import React, { Fragment, useContext, useEffect, useState } from 'react'
import {
	Form,
	Input,
	Button,
	Select,
	Skeleton,
	Alert,
	Space,
} from 'antd';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { NotificationMethod } from '../../../app/models/notification';
import { IUser } from '../../../app/models/user';
import { ICompany } from '../../../app/models/company';

interface IProps {
	closeModal: () => void;
	id: string;
}

const UpdateForm: React.FC<IProps> = ({ closeModal, id }) => {
	const rootStore = useContext(RootStoreContext);
	const { getCompanies } = rootStore.companyStore;
	const { update, getUserById, resendVerification } = rootStore.userStore;

	const [companies, setCompanies] = useState<ICompany[]>();
	const [loadingCompanies, setLoadingCompanies] = useState(false);
	const [loading, setLoading] = useState(false);
	const [initialValues, setInitialValues] = useState<IUser>();
	const [showEmailVerifiedWarning, setShowEmailVerifiedWarning] = useState(false);
	const [form] = Form.useForm();

	const onFinish = (values: any, successCallback?: () => Promise<void>) => {
		setLoading(true);
		update(values)
			.then(() => {
				if (successCallback) {
					successCallback();
				}

				setLoading(false);
				closeModal();
			})
			.finally(() => {
				setLoading(false);
			});
	}

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	useEffect(() => {
		setLoadingCompanies(true);
		getCompanies({ limit: -1, orderByColumn: 'CompanyName' }).then(companies => setCompanies(companies.data)).finally(() => setLoadingCompanies(false));
	}, [getCompanies])

	useEffect(() => {
		getUserById(id)
			.then(user => {
				setInitialValues(user);
				setShowEmailVerifiedWarning(!user.emailConfirmed)
			});
	}, [id, getUserById, setInitialValues])

	return (
		<Fragment>
			<div id='CustomUpdateFormContainer'>
				<Skeleton loading={!initialValues}>
					{showEmailVerifiedWarning && <Alert
						style={{ marginBottom: '16px' }}
						message="Please note this user has not yet verified their email, as a result they won't be able to log in"
						type="warning"
						action={
							<Space>
								<Button type='ghost' onClick={() => {
									resendVerification(form.getFieldValue('email'));
									setShowEmailVerifiedWarning(false);
								}}
								>
									Resend Verification
								</Button>
							</Space>
						}
						closable
					/>}

					<Form
						labelCol={{ span: 6 }}
						wrapperCol={{ span: 18 }}
						layout='horizontal'
						initialValues={initialValues}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						form={form}
					>
						<Form.Item
							label='Email'
							name='email'
							rules={[{ required: true, message: 'Please enter an email!' }]}
						>
							<Input autoComplete='off' type='email' />
						</Form.Item>
						<Form.Item
							label='Name'
							name='name'
							rules={[{ required: true, message: 'Please enter a name!' }]}
						>
							<Input autoComplete='off' />
						</Form.Item>
						<Form.Item
							label='Surname'
							name='surname'
							rules={[{ required: true, message: 'Please enter a surname!' }]}
						>
							<Input autoComplete='off' />
						</Form.Item>
						<Form.Item
							label='Display Name'
							name='displayName'
							rules={[{ required: true, message: 'Please enter a display name!' }]}
						>
							<Input autoComplete='off' />
						</Form.Item>
						<Form.Item
							label='Phone'
							name='phoneNumber'
							rules={[{ required: true, message: 'Please enter a phone number!' }]}
						>
							<Input autoComplete='off' type='tel' />
						</Form.Item>
						<Form.Item
							label='Company'
							name='companyId'
							rules={[{ required: true, message: 'Please select a company in the dropdown!' }]}
						>
							<Select
								virtual={false}
								loading={loadingCompanies}
								showSearch
								filterOption={(input, option) =>
									option?.props.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								getPopupContainer={() => document.getElementById('CustomUpdateFormContainer')!}
							>
								{companies?.map(company => {
									return (<Select.Option title={company.companyName} key={company.id} value={company.id}>{company.companyName}</Select.Option>)
								})}
							</Select>
						</Form.Item>
						<Form.Item
							label='Notification Method'
							name='notificationMethod'
							rules={[{ required: true, message: 'Please select a Notification Method!' }]}
						>
							<Select
								virtual={false}
								getPopupContainer={() => document.getElementById('CustomUpdateFormContainer')!}
							>
								<Select.Option key={NotificationMethod.default} value={NotificationMethod.default}>{NotificationMethod[NotificationMethod.default].charAt(0).toUpperCase() + NotificationMethod[NotificationMethod.default].slice(1)}</Select.Option>
								<Select.Option key={NotificationMethod.email} value={NotificationMethod.email}>{NotificationMethod[NotificationMethod.email].charAt(0).toUpperCase() + NotificationMethod[NotificationMethod.email].slice(1)}</Select.Option>
								<Select.Option key={NotificationMethod.whatsApp} value={NotificationMethod.whatsApp}>{NotificationMethod[NotificationMethod.whatsApp].charAt(0).toUpperCase() + NotificationMethod[NotificationMethod.whatsApp].slice(1)}</Select.Option>
							</Select>
						</Form.Item>
						<Form.Item style={{ float: 'right' }}>
							<Button loading={loading} htmlType='submit' type='primary'>Update</Button>
						</Form.Item>
						<Form.Item
							hidden={true}
							name='id'
						/>
					</Form>
				</Skeleton>
			</div>
		</Fragment>
	)
}

export default UpdateForm
