export const convertTimeZone = (date: Date | string, timeZone: string) => {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: timeZone}));   
}

export const fromUTC = (date: Date | string | undefined): Date | undefined => {
	if (!date) {
		return undefined;
	}
	if (typeof date === "string") {
		const stringDate = new Date(date);
		return new Date(Date.UTC(stringDate.getFullYear(), stringDate.getMonth(), stringDate.getDate(), stringDate.getHours(), stringDate.getMinutes(), 0, 0));
	}
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0, 0));   
}