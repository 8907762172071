import { makeObservable, action } from 'mobx';
import agent from '../api/agent';
import { IQueryableResponse } from '../models/queryableResponse';
import {
	IFuelRateMassUpdateRequest,
	IRate,
	IRateEstimate,
	IRateGetEstimateRequest,
	IRateGetRequest,
	IRatePostRequest,
	IRateRoute,
	IRateValueRateMassUpdateRequest
} from '../models/Rate';
import { IRequestData } from '../models/requestData';
import { RootStore } from './rootStore';

export default class RateStore {
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		makeObservable(this);
		this.rootStore = rootStore;
	}

	@action register = async (values: IRatePostRequest): Promise<void> => {
		try {
			return await agent.Rate.create(values);
		} catch (error) {
			throw error;
		}
	};

	@action edit = async (values: IRatePostRequest): Promise<void> => {
		try {
			return await agent.Rate.edit(values);
		} catch (error) {
			throw error;
		}
	};

	@action editFuel = async (
		values: IFuelRateMassUpdateRequest
	): Promise<void> => {
		try {
			return await agent.Rate.editFuel(values);
		} catch (error) {
			throw error;
		}
	};

	@action editRateValue = async (
		values: IRateValueRateMassUpdateRequest
	): Promise<void> => {
		try {
			return await agent.Rate.editRateValue(values);
		} catch (error) {
			throw error;
		}
	};

	@action getRates = async (
		companyId: string,
		request: IRequestData = {}
	): Promise<IQueryableResponse<IRate>> => {
		try {
			return await agent.Rate.list(companyId, request);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};

	@action getRoutes = async (companyId: string): Promise<IRateRoute[]> => {
		try {
			return await agent.Rate.listRoutes(companyId);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};

	@action get = async (request: IRateGetRequest): Promise<IRate> => {
		try {
			return await agent.Rate.get(request);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};

	@action getEstimate = async (
		request: IRateGetEstimateRequest
	): Promise<IRateEstimate> => {
		try {
			return await agent.Rate.getEstimate(request);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};

	@action getById = async (id: string): Promise<IRate> => {
		try {
			return await agent.Rate.getById(id);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};

	@action deleteRate = async (id: string): Promise<void> => {
		try {
			await agent.Rate.delete(id);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};
}
