import { Row, Col } from 'antd';
import React from 'react'
import { useBarcode } from 'react-barcodes';

interface IProps {
	waybill?: string;
}

const CollectionWaybillBarcodeComponent: React.FC<IProps> = ({waybill}) => {
	const { inputRef } = useBarcode({
		value: waybill ?? 'loading',
		options: {
			format: 'CODE128',
			background: '#FFFFFF',
			displayValue: true,
		}
	});

	return (
		<Row>
			<Col sm={24} md={8} lg={6}>
				<svg ref={inputRef} style={{width: '100%'}} />
			</Col>
		</Row>
	);
}

export default CollectionWaybillBarcodeComponent
