import React from 'react';
import {
	PieChartOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import Logo from './Logo';

interface IProps { }

const HomeNavBar: React.FC<IProps> = (props) => {
	const { Sider } = Layout;

	return (
		<Sider width="300px" breakpoint="lg" collapsedWidth="0">
			<Logo />
			<Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
				<Menu.Item key="1" icon={<PieChartOutlined />}>
					<a href='#home-home'>Home</a>
				</Menu.Item>
			
				<Menu.Item key="6"><a href="#home-services">Our Services</a></Menu.Item>
				<Menu.Item key="7"><a href="#home-aboutus">About us</a></Menu.Item>
				<Menu.Item key="8"><a href="#home-testimonials">Testimonials</a></Menu.Item>
				<Menu.Item key="9"><a href="#home-contactus">Contact us</a></Menu.Item>
			</Menu>
		</Sider>
	)
}

export default HomeNavBar
