import React from "react";
import { CollectionStatus, ICollectionListRequest } from "../models/Collection";

export const ConvertCollectionAdminRequestIntoOptions = (request: ICollectionListRequest): string => {
	if (!request) {
		return '';
	}

	let result = '?';

	if (request.from) {
		result = result + `from=${request.from.toLocaleString('en-US')}&`;
	}

	if (request.to) {
		result = result + `to=${request.to.toLocaleString('en-US')}&`;
	}

	if (request.keyword) {
		result = result + `keyword=${request.keyword}&`;
	}

	if (request.requesterId) {
		result = result + `requesterId=${request.requesterId}&`;
	}

	if (request.status) {
		result = result + `status=${request.status}&`;
	}

	if (request.supplierId) {
		result = result + `supplierId=${request.supplierId}&`;
	}

	if (request.limit) {
		result = result + `limit=${request.limit}&`;
	}

	if (request.offset) {
		result = result + `offset=${request.offset}&`;
	}

	if (request.invoiced) {
		result = result + `invoiced=${request.invoiced === 1 ? true : false}&`;
	}

	if (result[result.length - 1] === '?') {
		return ''
	}

	if (result[result.length - 1] === '&') {
		result = result.slice(0, result.length - 1);
	}

	return result;
}

export const getCollectionShortCardStyling = (collectionStatus: CollectionStatus): React.CSSProperties => {
	let result: React.CSSProperties = { marginTop: '16px' }

	switch (collectionStatus) {
		case CollectionStatus.Requested:
			result = {
				...result,
				border: '1px solid red',
				boxShadow: '2px 2px 5px red'
			}
			break;
		default:
			break;
	}

	return result;
}
