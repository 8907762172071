import React, { useContext, useEffect, useState } from 'react';
import {
	Table,
	Input,
	Row,
	TablePaginationConfig,
	Card,
	Button,
	Col,
	Select
} from 'antd';
import {
	DeleteOutlined,
	EditOutlined,
	PlusOutlined,
	RedoOutlined
} from '@ant-design/icons';
import { RootStoreContext } from '../../app/stores/rootStore';
import FeatureHeading from '../../app/components/headings/FeatureHeading';
import ConfirmationModal from '../../app/components/modals/ConfirmationModal';
import CustomFormModal from '../../app/components/modals/CustomFormModal';
import { IRequestData, OrderByType } from '../../app/models/requestData';
import RateFrom from './RateForm';
import { ICompany } from '../../app/models/company';
import { IRate } from '../../app/models/Rate';
import { IServiceType } from '../../app/models/ServiceType';

const ListRates = () => {
	const rootStore = useContext(RootStoreContext);
	const { user } = rootStore.userStore;
	const { getCompanies } = rootStore.companyStore;
	const { listServiceTypes } = rootStore.collectionStore;
	const { getRates, deleteRate } = rootStore.rateStore;
	const { Search } = Input;

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<IRate[]>([]);
	const [request, setRequest] = useState<IRequestData>({});
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [showRegisterModal, setShowRegisterModal] = useState<boolean>(false);
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [tableDataUpdated, setTableDataUpdated] = useState<boolean>(false);
	const [rateToModify, setRateToModify] = useState<Partial<IRate>>();
	const [companies, setCompanies] = useState<ICompany[]>();
	const [serviceTypes, setServiceTypes] = useState<IServiceType[]>([]);
	const [selectedCompany, setSelectedCompany] = useState<ICompany>();
	const [loadingCompanies, setLoadingCompanies] = useState(false);
	const [pagination, setPagination] = useState<TablePaginationConfig>({
		current: 1,
		pageSize: 10,
		showSizeChanger: true,
		total: 10
	});

	const fetchData = () => {
		setLoading(true);
		getRates(selectedCompany?.id!, request)
			.then((response) => {
				setPagination({ ...pagination, total: response.count });
				if (response.data) {
					setData(response.data);
					setLoading(false);
				}
			})
			.finally(() => setLoading(false));
	};

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: any,
		sorter: any
	) => {
		setPagination(pagination);
		let updatedRequest = { ...request };
		updatedRequest.limit = pagination?.pageSize;
		updatedRequest.offset =
			(pagination.pageSize || 10) * (pagination.current || 1) -
			(pagination.pageSize || 10);
		updatedRequest.orderByColumn = sorter?.column?.dataIndex;
		updatedRequest.orderByType =
			sorter?.order === 'ascend' ? OrderByType.asc : OrderByType.desc;

		setRequest(updatedRequest);
	};

	const onSearch = (value: string) => {
		setRequest({ ...request, keyword: value, offset: undefined });
		setPagination({ ...pagination, current: 1 });
	};

	const onDelete = async (record: IRate) => {
		setShowConfirmation(true);
		setRateToModify(record);
	};

	const onEdit = async (record: IRate) => {
		setShowEditModal(true);
		setRateToModify(record);
	};

	const onCompanyChange = async (value: string) => {
		setSelectedCompany(companies?.find((x) => x.id === value)!);
	};

	useEffect(() => {
		if (selectedCompany) {
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [request]);

	useEffect(() => {
		if (!tableDataUpdated) {
			return;
		}

		if (!showRegisterModal || !showEditModal) {
			fetchData();
		}

		setTableDataUpdated(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showRegisterModal, showEditModal]);

	useEffect(() => {
		setLoadingCompanies(true);
		getCompanies({ limit: -1, orderByColumn: 'CompanyName' })
			.then((companies) => {
				setCompanies(companies.data);
				setSelectedCompany(user!.company);
			})
			.finally(() => setLoadingCompanies(false));
		listServiceTypes().then((result) => setServiceTypes(result));
	}, [getCompanies, listServiceTypes, user]);

	const columns = [
		{
			title: 'Edit',
			dataIndex: 'edit',
			key: 'edit',
			width: '5%',
			render: (text: any, record: any) => (
				<Button
					title={'Edit'}
					style={{ border: 0 }}
					icon={<EditOutlined />}
					onClick={() => onEdit(record)}
				/>
			)
		},
		{
			title: 'Delete',
			dataIndex: 'delete',
			key: 'delete',
			width: '7%',
			render: (text: any, record: any) => (
				<Button
					title={'Delete'}
					style={{ border: 0 }}
					icon={<DeleteOutlined style={{ color: 'red' }} />}
					onClick={() => onDelete(record)}
				/>
			)
		},
		{
			title: 'From',
			dataIndex: 'from',
			key: 'from',
			width: '10%',
			sorter: true
		},
		{
			title: 'To',
			dataIndex: 'to',
			key: 'to',
			width: '10%',
			sorter: true
		},
		{
			title: 'Service',
			dataIndex: 'serviceTypes',
			key: 'serviceType',
			width: '10%',
			sorter: false,
			render: (serviceTypes: IServiceType[]) => {
				return <>{serviceTypes.map((it) => it.code).join(', ')}</>;
			}
		},
		{
			title: 'Minimum',
			dataIndex: 'minimum',
			key: 'minimum',
			width: '10%',
			sorter: true
		},
		{
			title: 'Rate',
			dataIndex: 'value',
			key: 'value',
			width: '10%',
			sorter: true
		},
		{
			title: 'Fuel',
			dataIndex: 'fuel',
			key: 'fuel',
			width: '10%',
			sorter: true
		},
		{
			title: 'Surcharge',
			dataIndex: 'surcharge',
			key: 'surcharge',
			width: '10%',
			sorter: true
		}
	];

	return (
		<>
			<FeatureHeading value="Rates" />
			<Card>
				<Row style={{ paddingBottom: '3px' }}>
					<Col>
						<Select
							virtual={false}
							placeholder="select a company"
							loading={loadingCompanies}
							showSearch
							disabled={companies?.length === 1}
							filterOption={(input, option) =>
								option?.props.title
									?.toLowerCase()
									.indexOf(input.toLowerCase()) >= 0
							}
							value={selectedCompany?.id}
							onChange={(value) => onCompanyChange(value)}
							style={{ width: '200px' }}
						>
							{companies?.map((company) => {
								return (
									<Select.Option
										key={company.id}
										value={company.id}
										title={company.companyName}
									>
										{company.companyName}
									</Select.Option>
								);
							})}
						</Select>
						<Search
							placeholder="input search text"
							onSearch={onSearch}
							style={{ width: 200 }}
						/>
					</Col>
					<Col style={{ marginLeft: 'auto' }}>
						<Button
							type="link"
							onClick={fetchData}
							icon={<RedoOutlined />}
						></Button>
						<Button
							disabled={!selectedCompany}
							type="link"
							onClick={() => setShowRegisterModal(true)}
							icon={<PlusOutlined />}
						></Button>
					</Col>
				</Row>
				<Table
					pagination={pagination}
					scroll={{ x: true }}
					loading={loading}
					columns={columns}
					dataSource={data}
					onChange={handleTableChange}
				/>
			</Card>
			<ConfirmationModal
				callback={async () => {
					setLoading(true);
					deleteRate(rateToModify?.id || '')
						.then(() => fetchData())
						.finally(() => setLoading(false));
				}}
				message={`Please confirm you want to delete ${rateToModify?.company?.companyName} (${rateToModify?.from} - ${rateToModify?.to})`}
				visible={showConfirmation}
				setVisible={setShowConfirmation}
			/>
			<CustomFormModal
				setVisible={setShowRegisterModal}
				title={'Register New Rate'}
				visible={showRegisterModal}
			>
				<RateFrom
					company={selectedCompany!}
					closeModal={() => {
						setTableDataUpdated(true);
						setShowRegisterModal(false);
					}}
					serviceTypes={serviceTypes}
				/>
			</CustomFormModal>
			<CustomFormModal
				setVisible={setShowEditModal}
				title={`Update Rate`}
				visible={showEditModal}
			>
				<RateFrom
					company={selectedCompany!}
					id={rateToModify?.id || ''}
					closeModal={() => {
						setTableDataUpdated(true);
						setShowEditModal(false);
					}}
					serviceTypes={serviceTypes}
				/>
			</CustomFormModal>
		</>
	);
};

export default ListRates;
