import { Card, Col } from 'antd'
import React from 'react'

interface IProps {
	loading?: boolean;
}

const CustomForm: React.FC<IProps> = ({ children, loading = false }) => {
	return (
		<Col xl={14} lg={18} xs={24}>
			<Card loading={loading}>
				{children}
			</Card>
		</Col>
	)
}

export default CustomForm
