import { InboxOutlined } from '@ant-design/icons'
import Dragger from 'antd/lib/upload/Dragger';
import { DraggerProps } from 'antd/lib/upload';
import React, { useContext, useEffect, useState } from 'react'
import { UploadFile } from 'antd/lib/upload/interface';
import { RootStoreContext } from '../../stores/rootStore';
import { toast } from 'react-toastify';
import { IMediaItem } from '../../models/MediaItem';

interface IProps {
	uploadUrl: string;
	uploadedFiles?: IMediaItem[];
	setUploadedFiles: (files: IMediaItem[]) => void;
	collectionId: string;
}

const DragUploader: React.FC<IProps> = (props) => {
	const rootContext = useContext(RootStoreContext);
	const {uploadUrl, uploadedFiles = [], setUploadedFiles, collectionId} = props

	const [files, setFiles] = useState(uploadedFiles?.map(x => {
		return {
			uid: x.publicId, 
			name: x.title,
			id: x.id, 
			link: x.link
		}
	}) ?? []);

	const onRemove = async (file: UploadFile<any>): Promise<void> => {
		await rootContext.mediaItemStore.deleteMediaItem(collectionId, file.uid);
		setFiles([...files.filter(x => x.uid !== file.uid)])
	}

	const token = window.localStorage.getItem('jwt');

	const uploadProps: DraggerProps = {
		name: 'file',
		multiple: true,
		action: uploadUrl,
		headers: {Authorization: `Bearer ${token}`},
		onRemove: onRemove,
		defaultFileList: [...files],
		onChange(info: any) {
			const { status } = info.file;
			if (status === 'done') {
				info.file.uid = info.file.response.publicId;
				setFiles([...files, {
					id: info.file.response.id, 
					uid: info.file.response.publicId,
					name: info.file.response.title,
					link: info.file.response.link
				}])
			} else if (status === 'error') {
				toast.error('Error uploading file!');
			}
		},
		onDrop(e: any) {
			console.log('Dropped files', e.dataTransfer.files);
		},
	};

	useEffect(() => {
		setUploadedFiles(files.map(x => {
			return {
				publicId: x.uid, 
				title: x.name,
				id: x.id,
				link: x.link
			}
		}))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [files])

	return (
		<>
			<Dragger {...uploadProps}>
				<p className="ant-upload-drag-icon">
					<InboxOutlined />
				</p>
				<p className="ant-upload-text">Click or drag file to this area to upload</p>
				<p className="ant-upload-hint">
					Support for a single or bulk upload. Strictly prohibited from uploading large, video or media files.
				</p>
			</Dragger>
		</>
	)
}

export default DragUploader
