import { Col, Row, Tooltip } from 'antd'
import React, { useContext, useState } from 'react'
import { WhatsAppOutlined, MailOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons/'
import Title from 'antd/lib/typography/Title'
import { ICollection } from '../../models/Collection'
import { download, open, getBlob } from '../../helpers/pdf/ViewWaybillReport'
import { RootStoreContext } from '../../stores/rootStore'
import CustomFormModal from '../modals/CustomFormModal'
import ShareInputForm from '../forms/ShareInputForm'

interface IProps {
	text?: string;
	collection: ICollection;
}

const ShareContentBar: React.FC<IProps> = ({ text, collection }) => {
	const rootContext = useContext(RootStoreContext);
	const { share, email: emailCollection } = rootContext.collectionStore;

	const [modalVisible, setModalVisible] = useState(false);
	const [title, setTitle] = useState<'email' | 'whatsapp'>();


	const whatsapp = (number: string) => {
		share(collection.id)
			.then(result => {
				const message = `Good day, \nKindly find collection #${collection.waybill} waybill at ${window.location.origin}/collections/shared/view?token=${result.token} \nKind regards\nCum Luade Freight Management Team`;
				window.open(`https://wa.me/${number}?text=${encodeURIComponent(message)}`);
				window.open(`whatsapp://send/?phone=${number}&text=${encodeURIComponent(message)}&source&data`);
			});
	}

	const email = (emailAddress: string) => {
		getBlob(collection)
			.then(blob => {
				emailCollection({
					attachment: blob,
					collectionId: collection.id,
					email: emailAddress
				})
			});
	}

	return (
		<>
			<Row>
				<Title style={{ fontWeight: 'bold' }} level={5}>Share or download this content?</Title>
			</Row>
			<Row>
				<Col style={{ paddingRight: '10px' }}>
					<Tooltip placement='bottom' title={`WhatsApp ${text ?? ''}`}>
						<WhatsAppOutlined style={{ fontSize: 20 }}
							onClick={() => {
								setTitle('whatsapp');
								setModalVisible(true)
							}}
						/>
					</Tooltip>
				</Col>
				<Col style={{ paddingRight: '10px' }}>
					<Tooltip placement='bottom' title={`Email ${text ?? ''}`}>
						<MailOutlined style={{ fontSize: 20 }}
							onClick={() => {
								setTitle('email');
								setModalVisible(true)
							}}
						/>
					</Tooltip>
				</Col>
				<Col style={{ paddingRight: '10px' }}>
					<Tooltip placement='bottom' title={`Download ${text ?? ''}`}>
						<DownloadOutlined style={{ fontSize: 20 }} onClick={() => download(collection)} />
					</Tooltip>
				</Col>
				<Col style={{ paddingRight: '10px' }}>
					<Tooltip placement='bottom' title={`View ${text ?? ''}`}>
						<EyeOutlined style={{ fontSize: 20 }} onClick={() => open(collection)} />
					</Tooltip>
				</Col>
			</Row>
			<br />

			<CustomFormModal setVisible={setModalVisible} title={'Send to ' + title} visible={modalVisible}>
				<ShareInputForm
					title={title}
					closeModal={value => {
						if (title === 'email') {
							email(value);
						} else {
							whatsapp(value);
						}
						setModalVisible(false);
					}}
					defaultValue={title === 'email'? collection.requester.email : collection.requester.phoneNumber}
				/>
			</CustomFormModal>
		</>
	)
}

export default ShareContentBar
