export interface IAuditEntry {
	userEmail: string;
	auditType: AuditType;
	dateTime: Date;
	changes: IAuditRecord[];
}

export interface IAuditRecord {
	affectedColumn: string;
	oldValue: string;
	newValue: string;
}

export enum  AuditType {
	None,
	Create,
	Update,
	Delete
}