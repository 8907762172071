import { Spin } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { RootStoreContext } from '../../../app/stores/rootStore';
import DownloadViewSelection from './DownloadViewSelection';
import { IMediaItemFile } from '../../../app/models/MediaItem';


const Download = () => {
	const [file, setFile] = useState<IMediaItemFile>();

	const rootContext = useContext(RootStoreContext);
	const { getMediaItem } = rootContext.mediaItemStore;

	const params = new URLSearchParams(window.location.search);
	const publicId: string = params.get('publicId') || '';
	const { waybillNumber }: any = useParams();

	useEffect(() => {
		getMediaItem(waybillNumber, publicId)
			.then(file => setFile(file));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	return (
		<>
			{file
				? <DownloadViewSelection file={file} />
				: 
				(<div style={{ marginLeft: 'auto', marginRight: 'auto', width: 'fit-content', height: 'fit-content', paddingTop: '35%' }}>
					<Spin size='large' tip='Downloading attachment' />
				</div>)
			}
		</>
	)
}

export default Download
