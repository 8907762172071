import { ToastContainer } from 'react-toastify';
import { Layout, Spin } from 'antd'
import './App.css';
import Footer from '../../features/footer/Footer';
import Header from '../../features/header/Header';
import { Route, Switch, withRouter } from 'react-router-dom';
import NotFound from './NotFound';
import PrivateRoute from '../../features/route/PrivateRoute';
import LoginForm from '../../features/account/user/LoginForm';
import DefaultNavBar from '../../features/nav/DefaultNavBar';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import { RootStoreContext } from '../stores/rootStore';
import Role from '../models/role';
import CreatePasswordForm from '../../features/account/user/CreatePasswordForm';
import ListUsers from '../../features/account/user/ListUsers';
import ListCompanies from '../../features/account/company/ListCompanies';
import ProfilePage from '../../features/account/user/ProfilePage';
import ListAddresses from '../../features/address/ListAddresses';
import CollectionForm from '../../features/collection/CollectionForm';
import OwnerDashboard from '../../features/dashboard/OwnerDashboard';
import CollectionSharedViewDownload from '../../features/collection/collectionComponents/CollectionSharedViewDownload';
import ListUserCollections from '../../features/collection/ListUserCollections';
import ListCollections from '../../features/collection/ListCollections';
import ListSuppliers from '../../features/supplier/ListSuppliers';
import Home from '../../features/default/home/Home';
import TermsConditions from '../../features/default/TermsConditions';
import Acknowledge from '../../features/supplier/Acknowledge';
import AdHock from '../../features/AdHock/AdHock';
import Download from '../../features/default/download/Download';
import ListRates from '../../features/rate/ListRates';
import Forbidden from './Forbidden';
import UpdateFuel from '../../features/rate/UpdateFuel';
import CreateQuote from '../../features/quote/CreateQuote';
import UpdateRate from '../../features/rate/UpdateRate';


function App() {
	const { Content } = Layout;

	const rootStore = useContext(RootStoreContext);
	const { token, appLoading, setAppLoading, lightMode } = rootStore.commonStore;
	const { getUser } = rootStore.userStore;

	useEffect(() => {
		if (token) {
			getUser().finally(() => setAppLoading(false));
		} else {
			setAppLoading(false);
		}
	}, [token, getUser, setAppLoading])

	if (appLoading) {
		return (
			<>
				<div>
					<Spin className={'app-loader-center'} tip='loading...' size="large" />
				</div>
			</>
		)
	}

	return (
		<>
			<ToastContainer />
			<Layout style={{ minHeight: '100vh', maxHeight: '100vh' }}>
				<DefaultNavBar />
				<Layout className="site-layout">
					<Header />
					<Content style={{ padding: '16px', backgroundColor: lightMode ? '#e8e8e8' : 'black', overflowY: 'scroll' }}>
						<Switch>
							<Route
								path={'/'}
								exact
								component={Home}
							/>
							<Route
								path={'/account/login'}
								exact
								component={LoginForm}
							/>
							<Route
								exact={true}
								path={'/account/forgot'}
								component={CreatePasswordForm}
							/>
							<Route
								exact={true}
								path={'/collections/shared/view'}
								component={CollectionSharedViewDownload}
							/>
							<Route
								exact={true}
								path={'/tc'}
								component={TermsConditions}
							/>
							<Route
								exact={true}
								path={'/supplier/acknowledge'}
								component={Acknowledge}
							/>
							<Route
								exact={true}
								path={'/media/:waybillNumber/get'}
								component={Download}
							/>
							{/* user routes */}
							<PrivateRoute
								role={Role.user}
								component={ProfilePage}
								path='/account/profile'
								exact={true}
							/>
							<PrivateRoute
								path={'/addresses'}
								component={ListAddresses}
								exact={true}
								role={Role.user}
							/>
							<PrivateRoute
								path={'/collections'}
								component={ListUserCollections}
								exact={true}
								role={Role.user}
								query={window.location.search}
							/>
							{/* <PrivateRoute
								path={'/collections/:collectionId'}
								component={View}
								exact={true}
								role={Role.user}
							/> */}
							<PrivateRoute
								path={'/collections/new'}
								component={CollectionForm}
								exact={true}
								role={Role.user}
							/>
							<PrivateRoute
								path={'/collections/update/:collectionId'}
								component={CollectionForm}
								exact={true}
								role={Role.user}
							/>
							{/* admin routes */}
							<PrivateRoute
								component={OwnerDashboard}
								path='/admin/dashboard'
								exact={true}
								role={Role.owner}
							/>
							<PrivateRoute
								path={'/admin/companies'}
								component={ListCompanies}
								exact={true}
								role={Role.admin}
							/>
							<PrivateRoute
								path={'/admin/users'}
								component={ListUsers}
								exact={true}
								role={Role.admin}
							/>
							<PrivateRoute
								path={'/admin/suppliers'}
								component={ListSuppliers}
								exact={true}
								role={Role.admin}
							/>
							<PrivateRoute
								path={'/admin/collections'}
								component={ListCollections}
								exact={true}
								role={Role.admin}
								query={window.location.search}
							/>
							<PrivateRoute
								path={'/collections/new/:userId'}
								component={CollectionForm}
								exact={true}
								role={Role.admin}
							/>
							<PrivateRoute
								path={'/developer/adhock'}
								component={AdHock}
								exact={true}
								role={Role.owner}
							/>
							<PrivateRoute
								path={'/admin/rates'}
								component={ListRates}
								exact={true}
								role={Role.admin}
							/>
							<PrivateRoute
								path={'/admin/rates/update-fuel'}
								component={UpdateFuel}
								exact={true}
								role={Role.admin}
							/>
							<PrivateRoute
								path={'/admin/rates/update-rates'}
								component={UpdateRate}
								exact={true}
								role={Role.admin}
							/>
							<PrivateRoute
								path={'/admin/quote'}
								component={CreateQuote}
								exact={true}
								role={Role.user}
							/>
							<Route
								path={'/forbidden'}
								component={Forbidden}
							/>
							<Route component={NotFound} />
						</Switch>
					</Content>
					<Footer />
				</Layout>
			</Layout>
		</>
	);
}

export default withRouter(observer(App));
