import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-toastify/dist/ReactToastify.min.css';
import App from './app/layout/App';
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import ThemeSelector from './features/default/web-theme/ThemeSelector';

export const history = createBrowserHistory();

ReactDOM.render(
	<React.StrictMode>
		<Router history={history}>
			<ThemeSelector>
				<App />
			</ThemeSelector>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
