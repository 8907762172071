import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { history } from '../..';
import agent from '../api/agent';
import { IQueryableResponse } from '../models/queryableResponse';
import { IRequestData } from '../models/requestData';
import { ILoginFormValues, IRegisterUserFormValues, IUpdateCurrentUserPasswordFormValues, IUpdateUserPasswordFormValues, IUser } from '../models/user'
import { RootStore } from './rootStore';

export default class UserStore {

	rootStore: RootStore;
	
	constructor(rootStore: RootStore) {
		makeObservable(this);
		this.rootStore = rootStore;
	}
	
	@observable user: IUser | null = null;

	@computed get isLoggedIn() { return !!this.user }

	@action login = async (values: ILoginFormValues): Promise<IUser> => {
		try {
			const user = await agent.User.login(values);
			runInAction(() => {
				this.user = user;
				this.rootStore.commonStore.setToken(user.token);
			})
			return user;
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}

	@action logout = async () => {
		this.user = null;
		this.rootStore.commonStore.setToken(null);
		history.push('/')
	}

	@action getUser = async () => {
		try {
			const user = await agent.User.current();
			runInAction(() => this.user = user);
		} catch (error) {
			await this.logout();
			console.debug(error);
			throw error;
		}
	}

	@action getUserById = async (id: string) => {
		try {
			return await agent.User.get(id);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}

	@action getUsers = async (request: IRequestData = {}): Promise<IQueryableResponse<IUser>> => {
		try {
			return await agent.User.list(request);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}

	@action register = async (values: IRegisterUserFormValues) => {
		try {
			await agent.User.register(values);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}

	@action deleteUser = async (id: string) => {
		try {
			await agent.User.delete(id);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}

	@action update = async (values: IRegisterUserFormValues) => {
		try {
			await agent.User.update(values);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}

	@action updatePassword = async (values: IUpdateUserPasswordFormValues) => {
		try {
			await agent.User.updatePassword(values);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}

	@action updateCurrentPassword = async (values: IUpdateCurrentUserPasswordFormValues) => {
		try {
			await agent.User.updateCurrentPassword(values);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}

	@action updateCurrentProfile = async (values: IRegisterUserFormValues) => {
		try {
			await agent.User.updateCurrentProfile(values);
			this.getUser();
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}

	@action resendVerification = async (email: string) => {
		try {
			await agent.User.sendVerification(email);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}

}