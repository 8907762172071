import { Tabs, Button, Skeleton, Row, Col } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import AuditHistoryCard from '../../app/components/cards/AuditHistoryCard';
import FeatureHeading from '../../app/components/headings/FeatureHeading';
import DragUploader from '../../app/components/uploader/DragUploader';
import { IAddress } from '../../app/models/Address';
import { IAuditEntry } from '../../app/models/audit';
import { IAdminCollection, ICollection } from '../../app/models/Collection';
import { IMediaItem } from '../../app/models/MediaItem';
import { IServiceType } from '../../app/models/ServiceType';
import { ISupplier } from '../../app/models/Supplier';
import { RootStoreContext } from '../../app/stores/rootStore';
import CollectionAddressComponent from './collectionComponents/CollectionAddressComponent';
import CollectionContentsComponent from './collectionComponents/CollectionContentsComponent';
import CollectionDetailsComponent from './collectionComponents/CollectionDetailsComponent';
import CollectionFinanceFormComponent from './collectionComponents/CollectionFinanceFormComponent';
import CollectionSupplierComponent from './collectionComponents/CollectionSupplierComponent';
import CollectionWaybillFormComponent from './collectionComponents/CollectionWaybillFormComponent';

interface IProps {
	collectionId: string;
	closeModal?: () => void;
}

const CollectionAdminUpdateForm: React.FC<IProps> = ({ collectionId, closeModal }) => {
	const rootContext = useContext(RootStoreContext);

	const { adminGet, adminUpdate, listServiceTypes } = rootContext.collectionStore;
	const { getAddresses } = rootContext.addressStore;
	const { getSuppliers } = rootContext.supplierStore;
	const { getCollectionHistory } = rootContext.auditStore;
	const { TabPane } = Tabs;

	const [collection, setCollection] = useState<Partial<IAdminCollection>>({});
	const [serviceTypes, setServiceTypes] = useState<IServiceType[]>([]);
	const [suppliers, setSuppliers] = useState<ISupplier[]>([]);
	const [addresses, setAddresses] = useState<IAddress[]>([]);
	const [collectionHistory, setCollectionHistory] = useState<IAuditEntry[]>([]);
	const [buttonLoading, setButtonLoading] = useState(false);

	const setDefaults = async (result: ICollection) => {
		setCollection(result);
		setAddresses((await getAddresses(result.requester.company.id, { limit: -1 })).data);
		setServiceTypes(await listServiceTypes());
		setSuppliers((await getSuppliers({limit: -1})).data);
		setCollectionHistory(await getCollectionHistory(collectionId))
	}

	useEffect(() => {
		adminGet(collectionId)
			.then((result) => {
				setCollection(result);
				setDefaults(result);
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const save = () => {
		setButtonLoading(true);
		adminUpdate(collection as IAdminCollection)
			.then(() => {
				if (closeModal) {
					closeModal();
				}
			})
			.finally(() => setButtonLoading(false));;
	}

	const cancel = () => {
		if (closeModal) {
			closeModal();
		}
	}

	return (
		<>
			<Row>
				<Col span={12}>
					<Button style={{ width: '100%' }} disabled={buttonLoading} type='ghost' onClick={() => cancel()}>Cancel</Button>
				</Col>
				<Col span={12}>
					<Button style={{ width: '100%' }} loading={buttonLoading} type='primary' onClick={() => save()}>Save</Button>
				</Col>
			</Row>
			<Skeleton loading={!collection || serviceTypes.length === 0}>
				<Tabs defaultActiveKey="1">
					<TabPane tab="Service" key="1">
						<FeatureHeading value='Service & Waybill' />
						<CollectionWaybillFormComponent
							collection={collection}
							setCollection={setCollection}
						/>
						<CollectionDetailsComponent
							collection={collection}
							setCollection={setCollection}
							serviceTypes={serviceTypes}
							showAttachments={false}
						/>
					</TabPane>
					<TabPane tab="Finance" key="2">
						<FeatureHeading value='Finance' />
						<CollectionFinanceFormComponent
							collection={collection}
							setCollection={setCollection}
						/>
					</TabPane>
					<TabPane tab="Sender & Receiver" key="3">
						<FeatureHeading value='Sender & Receiver' />
						<CollectionAddressComponent
							collection={collection}
							setCollection={setCollection}
							addresses={addresses}
							setAddresses={setAddresses}
						/>
					</TabPane>
					<TabPane tab="Contents" key="4">
						<FeatureHeading value='Contents' />
						<CollectionContentsComponent
							collection={collection}
							setCollection={setCollection}
						/>
					</TabPane>
					<TabPane tab="Attachments" key="5" style={{ paddingBottom: '50%' }}>
						<>
							<FeatureHeading value='Attachments' />
							<DragUploader
								uploadUrl={`${process.env.REACT_APP_API_HOST}/api/media/upload/${collection.id}`}
								setUploadedFiles={(result: IMediaItem[]) => setCollection({ ...collection, attachments: result })}
								uploadedFiles={collection.attachments}
								collectionId={collection.id!}
							/>
						</>
					</TabPane>
					<TabPane tab="Supplier" key="6">
						<FeatureHeading value='Supplier' />
						<CollectionSupplierComponent
							collection={collection}
							setCollection={setCollection}
							suppliers={suppliers}
						/>
					</TabPane>
					<TabPane tab="History" key="7">
						<FeatureHeading value='Collection history' />
						<AuditHistoryCard auditHistory={collectionHistory} /> 
					</TabPane>
				</Tabs>
			</Skeleton>
		</>
	)
}

export default CollectionAdminUpdateForm
