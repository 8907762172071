import { Checkbox, Col, Form, InputNumber } from 'antd';
import React, { useContext } from 'react';
import { IAdminCollection } from '../../../app/models/Collection';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { calculateRate } from '../../rate/RateCalculator';

interface IProps {
	collection: Partial<IAdminCollection>;
	setCollection: React.Dispatch<
		React.SetStateAction<Partial<IAdminCollection>>
	>;
}

const CollectionFinanceFormComponent: React.FC<IProps> = ({
	collection,
	setCollection
}) => {
	const rootStoreContext = useContext(RootStoreContext);
	const { get } = rootStoreContext.rateStore;

	const setShipmentMass = (value: number) => {
		setCollection((col) => {
			return { ...col, shipmentMass: value };
		});
	};

	const setSupplierRate = (value: number) => {
		if (shouldUpdateVat()) {
			const vat = Math.round(value * 0.15 * 100) / 100;
			setCollection({
				...collection,
				supplierRate: value,
				supplierVat: vat
			});
		} else {
			setCollection({ ...collection, supplierRate: value });
		}
	};

	const setSupplierVat = (value: number) => {
		setCollection({ ...collection, supplierVat: value });
	};

	const setMyRate = (value: number) => {
		if (shouldUpdateVat()) {
			const vat = Math.round(value * 0.15 * 100) / 100;
			setCollection((col) => {
				return { ...col, myRate: value, myVat: vat };
			});
		} else {
			setCollection((col) => {
				return { ...col, myRate: value };
			});
		}
	};

	const setMyVat = (value: number) => {
		setCollection({ ...collection, myVat: value });
	};

	const setInvoiced = (value: boolean) => {
		setCollection((col) => {
			return { ...col, invoiced: value };
		});
	};

	const shouldUpdateVat = (): boolean => {
		return (
			collection.serviceType?.code !== 'PRIORITY' &&
			collection.serviceType?.code !== 'CFR' &&
			collection.serviceType?.code !== 'INT'
		);
	};

	const onMassBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		const mass = e.target.value;
		if (
			collection.requester?.company.id &&
			collection.sender?.city &&
			collection.receiver?.city &&
			collection.serviceType?.id &&
			mass
		) {
			get({
				companyId: collection.requester.company.id,
				from: collection.sender.city,
				to: collection.receiver.city,
				serviceTypeId: collection.serviceType.id
			}).then((data) => {
				if (data) {
					const result = calculateRate(data, +mass);
					setMyRate(result);
				}
			});
		}
	};

	return (
		<>
			<Col sm={24} md={16} xl={8}>
				<Form layout={'vertical'}>
					<Form.Item label={'Mass:'}>
						<InputNumber
							autoFocus
							autoComplete="off"
							defaultValue={collection.shipmentMass}
							onChange={(value) => {
								setShipmentMass(value);
							}}
							onBlur={onMassBlur}
						/>
					</Form.Item>
					<Form.Item label={'Sub-total:'}>
						<InputNumber
							autoComplete="off"
							type="number"
							defaultValue={collection.supplierRate}
							onChange={(value) => {
								setSupplierRate(value);
							}}
						/>
					</Form.Item>
					<Form.Item label={'VAT:'}>
						<InputNumber
							autoComplete="off"
							type="number"
							defaultValue={collection.supplierVat}
							value={collection.supplierVat}
							onChange={(value) => {
								setSupplierVat(value);
							}}
						/>
					</Form.Item>
					<Form.Item label={'My Rate:'}>
						<InputNumber
							autoComplete="off"
							type="number"
							defaultValue={collection.myRate}
							value={collection.myRate}
							onChange={(value) => {
								setMyRate(value);
							}}
						/>
					</Form.Item>
					<Form.Item label={'My VAT:'}>
						<InputNumber
							autoComplete="off"
							type="number"
							defaultValue={collection.myVat}
							value={collection.myVat}
							onChange={(value) => {
								setMyVat(value);
							}}
						/>
					</Form.Item>
					<Form.Item label={'Invoiced:'}>
						<Checkbox
							defaultChecked={collection.invoiced}
							onChange={(value) => {
								setInvoiced(value.target.checked);
							}}
						>
							Invoiced?
						</Checkbox>
					</Form.Item>
				</Form>
			</Col>
		</>
	);
};

export default CollectionFinanceFormComponent;
