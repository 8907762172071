import { Button, Row } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const GuessPopOver = () => {

	return (
		<>
			<Row>
				<Link to='/account/login'>
					<Button type='link'>Login</Button>
				</Link>
			</Row>
		</>
	)
}

export default GuessPopOver
