import { Button, Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import ConfirmationModal from '../../../../app/components/modals/ConfirmationModal'
import CustomFormModal from '../../../../app/components/modals/CustomFormModal'
import { PlusOutlined } from '@ant-design/icons';
import { ISupplier } from '../../../../app/models/Supplier'
import SupplierServiceTableComponent from './SupplierServiceTableComponent';
import SupplierServiceForm from './SupplierServiceForm';
import { IServiceType } from '../../../../app/models/ServiceType';

interface IProps {
	supplier: Partial<ISupplier>;
	setSupplier: (collection: Partial<ISupplier>) => void;
	validateCallback?: (value: boolean) => void;
}

const SupplierServiceComponent: React.FC<IProps> = ({ supplier, setSupplier, validateCallback }) => {

	const [loading, setLoading] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [contentToModify, setContentToModify] = useState<IServiceType>();

	const onDelete = async (record: IServiceType) => {
		setContentToModify(record);
		setShowDeleteModal(true);
	}

	const onEdit = async (record: IServiceType) => {
		setContentToModify(record);
		setShowEditModal(true);
	}

	const setServices = (services: IServiceType[]) => {
		setSupplier({...supplier, services: services})
	}

	const validate = () => {
		if (validateCallback) {
			validateCallback(true);
		}
	}

	useEffect(() => {
		validate();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [supplier.emails])

	return (
		<>
			<Row style={{ paddingBottom: '3px' }}>
				<Col style={{ marginLeft: 'auto' }}>
					<Button type='link' onClick={() => setShowCreateModal(true)} icon={<PlusOutlined />}></Button>
				</Col>
			</Row>

			<SupplierServiceTableComponent 
				data={supplier.services} 
				loading={loading} 
				onDelete={onDelete} 
				onEdit={onEdit} 
			/>

			<ConfirmationModal
				callback={async () => {
					setLoading(true);
					setServices([...supplier.services!.filter(x => x !== contentToModify)])
					setLoading(false);
				}}
				message={`Please confirm deletion`}
				visible={showDeleteModal}
				setVisible={setShowDeleteModal}
			/>
			<CustomFormModal
				setVisible={setShowCreateModal}
				title={'Add a Service'}
				visible={showCreateModal}
			>
				<SupplierServiceForm 
					editMode={false} 
					closeModal={(result) => {
						setServices([...supplier.services ?? [], result]);
						setShowCreateModal(false);
					}} 
				/>
			</CustomFormModal>
			<CustomFormModal
				setVisible={setShowEditModal}
				title={'Update Services'}
				visible={showEditModal}
			>
				<SupplierServiceForm 
					initialValue={contentToModify}
					editMode={true} 
					closeModal={(result) => {
						setServices([...supplier.services!.filter(x => x !== contentToModify), result]);
						setShowEditModal(false);
					}} 
				/>
			</CustomFormModal>
		</>
	)
}

export default SupplierServiceComponent
