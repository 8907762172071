import { Button, Select } from 'antd'
import { SelectValue } from 'antd/lib/select'
import React, { useState } from 'react'
import CustomFormModal from '../../../app/components/modals/CustomFormModal'
import { IAddress } from '../../../app/models/Address'
import { ICompany } from '../../../app/models/company'
import AddressForm from '../../address/AddressForm'

interface IProps {
	selectedAddress?: IAddress;
	addresses: IAddress[];
	setAddresses: (value: IAddress[]) => void;
	setSelectedAddress: (value: IAddress) => void;
	company: ICompany;
}

const CollectionAddressSelectorComponent: React.FC<IProps> = ({ addresses, company, setAddresses, selectedAddress, setSelectedAddress }) => {
	const [showRegisterModal, setShowRegisterModal] = useState<boolean>(false);
	const [showEditModal, setShowEditModal] = useState<boolean>(false);

	const selectorChanged = (selectedOption: SelectValue) => {
		if (selectedOption?.toString() === 'new') {
			setShowRegisterModal(true);
		} else {
			setSelectedAddress(addresses.find(address => address.id === selectedOption?.toString())!)
		}
	}

	return (
		<>
			<div id='CustomCollectionAddressSelectorComponentContainer'>
				<Select
					virtual={false}
					placeholder='select an address'
					showSearch
					filterOption={(input, option) =>
						option?.props.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}
					style={{ width: '80%', marginRight: '2px', marginBottom: '3px' }}
					onSelect={selectorChanged}
					value={selectedAddress?.id}
					getPopupContainer={() => document.getElementById('CustomCollectionAddressSelectorComponentContainer')!}
				>
					<Select.Option
						key={'new'}
						value={'new'}
						title={'New Address'}
					>
						Create a new address
					</Select.Option>
					{addresses?.map(address => {
						return (
							<Select.Option
								key={address.id}
								value={address.id}
								title={address.addressLine1 + address.city + address.name}
							>
								{`${address.name} - ${address.addressLine1}, ${address.city}`}
							</Select.Option>
						)
					})}
				</Select>

				<Button onClick={() => setShowEditModal(true)} disabled={!selectedAddress}>Edit this address</Button>

				<CustomFormModal
					setVisible={setShowRegisterModal}
					title={'Register New Address'}
					visible={showRegisterModal}
				>
					<AddressForm
						company={company}
						closeModal={result => {
							setShowRegisterModal(false);
							setAddresses([result, ...addresses])
							setSelectedAddress(result);
						}}
					/>
				</CustomFormModal>
				<CustomFormModal
					setVisible={setShowEditModal}
					title={'Update Address - ' + selectedAddress?.name}
					visible={showEditModal}
				>
					<AddressForm
						company={company}
						id={selectedAddress?.id || ''}
						closeModal={result => {
							setShowEditModal(false);
							setAddresses([result, ...addresses.filter(x => x.id !== result.id)]);
							setSelectedAddress(result);
						}}
					/>
				</CustomFormModal>
			</div>
		</>
	)
}

export default CollectionAddressSelectorComponent
