import { Card, Col, Row } from 'antd'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { history } from '../..'
import { ICollection, ICollectionListRequest } from '../../app/models/Collection'
import Role from '../../app/models/role'
import { RootStoreContext } from '../../app/stores/rootStore'
import CollectionListFilterComponent from './collectionComponents/CollectionListFilterComponent'
import CollectionShortCard from './collectionComponents/CollectionShortCard'

const ListUserCollections = () => {
	const rootContext = useContext(RootStoreContext);
	const { list } = rootContext.collectionStore;
	const { user } = rootContext.userStore;

	const params = new URLSearchParams(window.location.search);
	const waybill: string | null = params.get('waybill');
	const from: Date = params.get('from') ? new Date(params.get('from')!) : moment().add(-2, 'days').toDate();
	const to: Date = params.get('to') ? new Date(params.get('to')!) : moment().add(+2, 'days').toDate();

	const [collectionRequest, setCollectionRequest] = useState<ICollectionListRequest>({ from: from, to: to, keyword: waybill ?? undefined })
	const [loading, setLoading] = useState(false);
	const [collections, setCollections] = useState<ICollection[]>([])

	const fetchData = () => {
		setLoading(true);
		list(collectionRequest)
			.then(result => setCollections(result))
			.finally(() => {
				setLoading(false);
			});
	}

	useEffect(() => {
		if (user?.role !== Role.user) {
			history.push('/admin' + window.location.pathname + window.location.search);
		}

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collectionRequest])

	return (
		<>
			<div className="site-card-wrapper">
				<Row>
					<Col xxl={4} xl={6} md={6} sm={24} xs={24} style={{ marginRight: 'auto' }}>
						<Card>
							<CollectionListFilterComponent
								collectionRequest={collectionRequest}
								setCollectionRequest={setCollectionRequest}
								users={collections.map(x => x.requester)}
								loading={loading}
							/>
						</Card>
					</Col>
					<Col xxl={19} xl={17} md={17} sm={24} xs={24}>
						{collections.map(collection => {
							return (
								<CollectionShortCard collection={collection} reloadCallback={fetchData} />
							);
						})}
					</Col>
				</Row>
			</div>
		</>
	)
}

export default ListUserCollections
