import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import {
	Form,
	Input,
	Button,
	Radio,
	Select
} from 'antd';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { NotificationMethod } from '../../../app/models/notification';
import { ICompany } from '../../../app/models/company';

interface IProps {
	closeModal: () => void;
}

const RegisterForm: React.FC<IProps> = ({ closeModal }) => {
	const rootStore = useContext(RootStoreContext);
	const { getCompanies } = rootStore.companyStore;
	const { register } = rootStore.userStore;

	const [companies, setCompanies] = useState<ICompany[]>();
	const [loadingCompanies, setLoadingCompanies] = useState(false);
	const [loading, setLoading] = useState(false);

	const onFinish = (values: any) => {
		setLoading(true);
		register(values)
			.then(() => {
				setLoading(false);
				closeModal();
			})
			.finally(() => {
				setLoading(false);
			});
	}

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};


	useEffect(() => {
		setLoadingCompanies(true);
		getCompanies({ limit: -1, orderByColumn: 'CompanyName' }).then(companies => setCompanies(companies.data)).finally(() => setLoadingCompanies(false));
	}, [getCompanies])

	return (
		<Fragment>
			<div id='CustomRegisterFormContainer'>
				<Form
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 18 }}
					layout='horizontal'
					initialValues={{ switch: true, input: 'hello' }}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
				>
					<Form.Item
						label='User Type'
						name='userType'
						rules={[{ required: true, message: 'Please select the type of user!' }]}
						initialValue={0}
					>
						<Radio.Group>
							<Radio.Button value={0}>Client</Radio.Button>
							<Radio.Button value={1}>Admin</Radio.Button>
							<Radio.Button value={2}>Owner</Radio.Button>
						</Radio.Group>
					</Form.Item>
					<Form.Item
						label='Email'
						name='email'
						rules={[{ required: true, message: 'Please enter an email!' }]}
					>
						<Input autoComplete='off' type='email' />
					</Form.Item>
					<Form.Item
						label='Name'
						name='name'
						rules={[{ required: true, message: 'Please enter a name!' }]}
					>
						<Input autoComplete='off' />
					</Form.Item>
					<Form.Item
						label='Surname'
						name='surname'
						rules={[{ required: true, message: 'Please enter a surname!' }]}
					>
						<Input autoComplete='off' />
					</Form.Item>
					<Form.Item
						label='Display Name'
						name='displayName'
						rules={[{ required: true, message: 'Please enter a display name!' }]}
					>
						<Input autoComplete='off' />
					</Form.Item>
					<Form.Item
						label='Phone'
						name='phoneNumber'
						rules={[{ required: true, message: 'Please enter a phone number!' }]}
					>
						<Input autoComplete='off' type='tel' />
					</Form.Item>
					<Form.Item
						label='Company'
						name='companyId'
						rules={[{ required: true, message: 'Please select a company in the dropdown!' }]}
					>
						<Select
							placeholder='select a company'
							loading={loadingCompanies}
							showSearch
							filterOption={(input, option) =>
								option?.props.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							getPopupContainer={() => document.getElementById('CustomRegisterFormContainer')!}
						>
							{companies?.map(company => {
								return (<Select.Option key={company.id} value={company.id} title={company.companyName}>{company.companyName}</Select.Option>)
							})}
						</Select>
					</Form.Item>
					<Form.Item
						label='Notification Method'
						name='notificationMethod'
						rules={[{ required: true, message: 'Please select a Notification Method!' }]}
					>
						<Select
							virtual={false}
							getPopupContainer={() => document.getElementById('CustomRegisterFormContainer')!}
						>
							<Select.Option key={NotificationMethod.default} value={NotificationMethod.default}>{NotificationMethod[NotificationMethod.default].charAt(0).toUpperCase() + NotificationMethod[NotificationMethod.default].slice(1)}</Select.Option>
							<Select.Option key={NotificationMethod.email} value={NotificationMethod.email}>{NotificationMethod[NotificationMethod.email].charAt(0).toUpperCase() + NotificationMethod[NotificationMethod.email].slice(1)}</Select.Option>
							<Select.Option key={NotificationMethod.whatsApp} value={NotificationMethod.whatsApp}>{NotificationMethod[NotificationMethod.whatsApp].charAt(0).toUpperCase() + NotificationMethod[NotificationMethod.whatsApp].slice(1)}</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item style={{ float: 'right' }}>
						<Button loading={loading} htmlType='submit' type='primary'>Register</Button>
					</Form.Item>
				</Form>
			</div>
		</Fragment>
	)
}

export default observer(RegisterForm)
