import { Card, Col, Row, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AreaChart from '../../app/components/charts/area/AreaChart';
import ColumnChart from '../../app/components/charts/column/ColumnChart';
import LineChart from '../../app/components/charts/line/LineChart';
import FeatureHeading from '../../app/components/headings/FeatureHeading';
import { IDashboard } from '../../app/models/Dashboard';
import { RootStoreContext } from '../../app/stores/rootStore';

const OwnerDashboard = () => {
	const rootStoreContext = useContext(RootStoreContext);
	const { getDashboard } = rootStoreContext.adhockStore;

	const [dashboardData, setDashboardData] = useState<IDashboard>();

	const colStyle: React.CSSProperties = {
		paddingLeft: '8px',
		paddingRight: '8px',
		paddingBottom: '16px'
	};

	const columns = [
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			width: '25%',
			render: (text: any, record: any) => (
				<Link
					to={`/collections?waybill=${record.waybillNumber}&from=${record.fromDate}&to=${record.toDate}`}
					title={'View Collection ' + record.waybillNumber}
					style={{ border: 0 }}
				>
					{text}
				</Link>
			)
		},
		{
			title: 'Client',
			dataIndex: 'client',
			key: 'client',
			width: '25%',
			sorter: true
		},
		{
			title: 'From',
			dataIndex: 'from',
			key: 'from',
			width: '25%',
			sorter: true
		},
		{
			title: 'To',
			dataIndex: 'to',
			key: 'to',
			width: '25%',
			sorter: true
		}
	];

	useEffect(() => {
		getDashboard().then((result) => setDashboardData(result));
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<FeatureHeading value="Dashboard" />
			<Row justify="center">
				<Col lg={6} md={12} sm={24} xs={24} style={colStyle}>
					<Card title={'Collections'}>
						<LineChart
							data={dashboardData?.collectionCounts ?? {}}
						/>
					</Card>
				</Col>
				<Col lg={6} md={12} sm={24} xs={24} style={colStyle}>
					<Card title={'Profit'}>
						<AreaChart
							data={dashboardData?.collectionProfits ?? {}}
						/>
					</Card>
				</Col>
				<Col lg={12} md={24} sm={24} xs={24} style={colStyle}>
					<Card
						style={{ height: '100%' }}
						title={'Actionable Collections'}
					>
						<Table
							scroll={{ x: true }}
							columns={columns}
							dataSource={dashboardData?.collections}
							loading={!dashboardData}
						/>
					</Card>
				</Col>
			</Row>
			<Row justify="center">
				<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
					<Card style={{ height: '100%' }} title={'Profit by Client'}>
						<ColumnChart
							data={dashboardData?.profitByClient ?? {}}
						/>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default OwnerDashboard;
