import React, { useContext } from 'react'
import { Route } from 'react-router-dom'
import { history } from '../..'
import Role from '../../app/models/role'
import { RootStoreContext } from '../../app/stores/rootStore'

interface IProps {
	component: React.FC;
	path: string;
	role?: Role;
	exact?: boolean;
	key?: string;
	query?: string;
}

const PrivateRoute: React.FC<IProps> = (props) => {
	const context = useContext(RootStoreContext);
	const {user} = context.userStore;
	const authenticated = !!context.commonStore.token;
	const query = props.query ?? '';

	if (!authenticated) {
		history.push('/account/login?redirect=' + encodeURIComponent(props.path + query));
		return null;
	}

	if (props.role === Role.admin && user?.role === Role.user) {
		history.push('/forbidden');
		return null;
	}

	if (props.role === Role.owner && user?.role !== Role.owner) {
		history.push('/forbidden');
		// history.push('/account/login?redirect=' + encodeURIComponent(props.path + query));
		return null;
	}

	return (
		<Route component={props.component} path={props.path} exact={props.exact ? true: false} key={props.key} />
	)
}

export default PrivateRoute


