import { action, makeObservable } from "mobx";
import { IAuditEntry } from "../models/audit";
import { RootStore } from "./rootStore";
import agent from '../api/agent';

export default class AuditStore {
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		makeObservable(this);
		this.rootStore = rootStore;
	}

	@action getUserHistory = async (id: string): Promise<IAuditEntry[]> => {
		try {
			return await agent.Audit.user(id);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}

	@action getCollectionHistory = async (id: string): Promise<IAuditEntry[]> => {
		try {
			return await agent.Audit.collection(id);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}
}