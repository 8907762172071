import { Alert, Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import CustomForm from '../../app/components/forms/CustomForm';
import FeatureHeading from '../../app/components/headings/FeatureHeading';
import { getBase64 } from '../../app/helpers/pdf/ViewQuoteReport'
import { ICompany } from '../../app/models/company';
import { IMediaItemFile } from '../../app/models/MediaItem';
import { IQuote } from '../../app/models/quote';
import { IRateRoute } from '../../app/models/Rate';
import Role from '../../app/models/role';
import { IServiceType } from '../../app/models/ServiceType';
import { RootStoreContext } from '../../app/stores/rootStore';
import CollectionContentsComponent from '../collection/collectionComponents/CollectionContentsComponent';
import DownloadViewSelection from '../default/download/DownloadViewSelection';

const CreateQuote = () => {
	const rootStore = useContext(RootStoreContext);
	const { user } = rootStore.userStore;
	const { getRoutes, getEstimate } = rootStore.rateStore;
	const { getCompanies } = rootStore.companyStore;

	const [routes, setRoutes] = useState<IRateRoute[]>();
	const [companies, setCompanies] = useState<ICompany[]>();
	const [routesStart, setRoutesStart] = useState<string[]>();
	const [routesEnd, setRoutesEnd] = useState<string[]>();
	const [from, setFrom] = useState<string>();
	const [to, setTo] = useState<string>();
	const [quote, setQuote] = useState<Partial<IQuote>>({ contents: [] });
	const [serviceTypes, setServiceTypes] = useState<IServiceType[]>([]);
	const [report, setReport] = useState<IMediaItemFile>();
	const [selectedCompany, setSelectedCompany] = useState<ICompany>();
	const [loadingCompanies, setLoadingCompanies] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(false);

	const [form] = Form.useForm();

	useEffect(() => {
		if (selectedCompany) {
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCompany]);

	useEffect(() => {
		form.setFieldsValue({
			companyId: user!.company.id,
		});
		if (user?.role === Role.user) {
			setCompanies([user.company]);
			setSelectedCompany(user.company);
			return;
		}
		setLoadingCompanies(true);
		getCompanies({ limit: -1, orderByColumn: 'CompanyName' })
			.then(companies => {
				setCompanies(companies.data);
				setSelectedCompany(user!.company);
			})
			.finally(() => setLoadingCompanies(false));
		// eslint-disable-next-line
	}, [getCompanies, user])

	useEffect(() => {
		const temp: string[] = [];
		routes?.forEach(route => {
			if (temp.indexOf(route.sendCity) === -1) {
				temp.push(route.sendCity);
			}

			if (temp.indexOf(route.receiveCity) === -1) {
				temp.push(route.receiveCity);
			}
		})

		setRoutesStart(temp.sort((a, b) => a.localeCompare(b)));
		setRoutesEnd([]);
	}, [routes]);

	useEffect(() => {
		if (from && to) {
			updateServices();
		}
		else {
			setServiceTypes([]);
		}
		// eslint-disable-next-line
	}, [from, to])

	const fetchData = () => {
		setLoading(true);

		getRoutes(selectedCompany?.id!)
			.then(result => {
				const temp: IRateRoute[] = [...result];
				result.forEach(route => {
					temp.push({ sendCity: route.receiveCity, receiveCity: route.sendCity, service: route.service });
				})
				setRoutes(temp);
			})
			.finally(() => setLoading(false));
	}

	const updateEndRoutes = (start: string) => {
		const temp: string[] = [];
		routes?.forEach(route => {
			if (start === route.sendCity && temp.indexOf(route.receiveCity) === -1) {
				temp.push(route.receiveCity);
			}
		});
		setRoutesEnd(temp.sort((a, b) => a.localeCompare(b)));
	};

	const updateServices = () => {
		const temp: IServiceType[] = [];
		routes?.forEach(route => {
			if (from === route.sendCity && to === route.receiveCity && temp.findIndex(r => r.id === route.service.id) === -1) {
				temp.push(route.service);
			}
		});
		setServiceTypes(temp.sort((a, b) => a.displayName.localeCompare(b.displayName)));
	};

	const onFinish = (values: any) => {
		if (!quote.contents || quote.contents.length === 0) {
			toast.error('Please add some contents!');
			return;
		}
		setLoadingSubmit(true);
		getEstimate({ companyId: values.companyId, from: values.from, to: values.to, serviceId: values.service, contents: quote.contents! })
			.then(result => {
				getBase64({
					...values,
					contents: quote?.contents!,
					serviceType: serviceTypes.find(x => x.id === values.service)!,
					value: result.value,
					chargeableWeight: result.chargeableWeight,
				})
					.then(stringFile => {
						setReport({ extension: '.pdf', file: stringFile, fileName: `price-estimate.pdf` });
					});
			})
			.finally(() => setLoadingSubmit(false));
	};

	const onFinishFailed = () => {
		toast.error('Could not generate an estimate')
	};

	const resetFields = (fields: string[]) => {
		if (fields.indexOf('contents') !== -1) {
			setQuote({ contents: [] });
		}
		form.resetFields(fields);
	}

	const onCompanyChanged = async (value: string) => {
		setSelectedCompany(companies?.find(x => x.id === value)!)
		resetFields(['from', 'to', 'sender', 'receiver', 'service', 'reference', 'contents']);
	}

	const onOriginChanged = (value: string) => {
		updateEndRoutes(value);
		setFrom(value);
		resetFields(['to', 'service', 'sender', 'receiver']);
	}

	const onDestinationChanged = (value: string) => {
		setTo(value);
		resetFields(['service', 'receiver']);
	}

	return (
		<>
			<div id='CreateQuoteComponentContainer'>
				<FeatureHeading value='Generate a Price Estimate' />
				<CustomForm loading={loading || loadingCompanies}>
					<Form
						form={form}
						labelCol={{ span: 6 }}
						wrapperCol={{ span: 18 }}
						layout='horizontal'
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
					>
						<Form.Item
							name='companyId'
							label='Company'
							rules={[{ required: true, message: 'Please select a Company!' }]}
						>
							<Select
								virtual={false}
								placeholder='select a company'
								loading={loadingCompanies}
								showSearch
								disabled={companies?.length === 1}
								filterOption={(input, option) =>
									option?.props.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								onChange={value => onCompanyChanged(value?.toString()!)}
								style={{ width: '200px' }}
							>
								{companies?.map(company => {
									return (<Select.Option key={company.id} value={company.id} title={company.companyName}>{company.companyName}</Select.Option>)
								})}
							</Select>
						</Form.Item>
						<Form.Item
							name='from'
							label='Origin'
							rules={[{ required: true, message: 'Please select a city!' }]}
						>
							<Select
								virtual={false}
								getPopupContainer={() => document.getElementById('CreateQuoteComponentContainer')!}
								allowClear
								style={{ width: '100%' }}
								placeholder="Please select"
								onChange={(value) => { onOriginChanged(value?.toString() || '') }}
							>
								{routesStart?.map(route => {
									return (<Select.Option key={`start-${route}`} value={route}>{route}</Select.Option>)
								})}
							</Select>
						</Form.Item>
						<Form.Item
							label='Sender'
							name='sender'
						>
							<Input autoComplete='off' maxLength={50} />
						</Form.Item>
						<Form.Item
							name='to'
							label='Destination'
							rules={[{ required: true, message: 'Please select a city!' }]}
						>
							<Select
								virtual={false}
								getPopupContainer={() => document.getElementById('CreateQuoteComponentContainer')!}
								allowClear
								style={{ width: '100%' }}
								placeholder="Please select"
								onChange={(value) => { onDestinationChanged(value?.toString() || '') }}
							>
								{routesEnd?.map(route => {
									return (<Select.Option key={`end-${route}`} value={route}>{route}</Select.Option>)
								})}
							</Select>
						</Form.Item>
						<Form.Item
							label='Receiver'
							name='receiver'
						>
							<Input autoComplete='off' maxLength={50} />
						</Form.Item>
						<Row>
							<Col span={6} style={{ alignItems: 'right' }}>
							</Col>
							<Col span={18} style={{ alignItems: 'right' }}>
								<Alert message={'Should a destination not be available, please contact Cum Laude Freight Management to add rates'} type="warning" />
							</Col>
						</Row>
						<Form.Item
							name='contents'
							label='Contents'
							required={true}
						>
							<CollectionContentsComponent collection={{ contents: quote?.contents }} setCollection={(value) => setQuote({ ...quote, contents: value.contents })} />
						</Form.Item>
						<Form.Item
							label={'Service:'}
							name='service'
							rules={[{ required: true, message: 'Please select a service!' }]}
						>
							<Select
								virtual={false}
								placeholder='select a service'
								showSearch
								filterOption={(input, option) =>
									option?.props.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								getPopupContainer={() => document.getElementById('CreateQuoteComponentContainer')!}
							>
								{serviceTypes?.map(serviceType => {
									return (
										<Select.Option
											key={serviceType.id}
											value={serviceType.id}
											title={serviceType.code + serviceType.displayName}
										>
											{`${serviceType.displayName} (${serviceType.code})`}
										</Select.Option>
									)
								})}
							</Select>
						</Form.Item>
						<Form.Item
							label='Reference'
							name='reference'
						>
							<Input autoComplete='off' maxLength={50} />
						</Form.Item>
						<Form.Item style={{ float: 'right' }}>
							<Button loading={loadingSubmit} type="primary" htmlType="submit">
								Generate estimate
							</Button>
						</Form.Item>
						<Form.Item style={{ float: 'right', marginRight: '3px' }}>
							<Button danger loading={loadingSubmit} type="ghost" htmlType="reset" onClick={() => {
								setReport(undefined);
								setQuote({});
							}}>
								Reset
							</Button>
						</Form.Item>
					</Form>
					<br />
					{report && <DownloadViewSelection file={report} />}
				</CustomForm>
			</div>
		</>
	)
}

export default CreateQuote