import React, { useContext } from 'react'
import { RootStoreContext } from '../../app/stores/rootStore'

const AdHock = () => {
	const rootContext = useContext(RootStoreContext);
	const {extendTokens} = rootContext.adhockStore;

	return (
		<div>
			<button onClick={() => extendTokens()}>Extend Tokens</button>
		</div>
	)
}

export default AdHock
