import { Button, Form, Input, Skeleton } from 'antd'
import React, { Fragment } from 'react'
import { IServiceType } from '../../../../app/models/ServiceType';


interface IProps {
	closeModal: (result: IServiceType) => void;
	initialValue?: IServiceType;
	editMode: boolean;
}

const SupplierServiceForm: React.FC<IProps> = ({ closeModal, initialValue, editMode }) => {

	const onFinish = async (values: IServiceType) => {
		closeModal(values);
	}

	return (
		<Fragment>
			<Skeleton loading={!initialValue && editMode}>
				<Form
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 18 }}
					layout='horizontal'
					initialValues={initialValue}
					onFinish={onFinish}
				>
					<Form.Item label='Code' name='code'
						rules={[{ required: true, message: 'Please input a code!' }]}
					>
						<Input autoComplete='off' maxLength={3} />
					</Form.Item>
					<Form.Item label='Display Name' name='displayName'
						rules={[{ required: true, message: 'Please input a display name!' }]}
					>
						<Input autoComplete='off' />
					</Form.Item>
					<Form.Item style={{ float: 'right' }}>
						<Button type="primary" htmlType="submit">
							{editMode ? 'Save Changes' : 'Save Content'}
						</Button>
					</Form.Item>
				</Form>
			</Skeleton>
		</Fragment>
	)
}

export default SupplierServiceForm
