import { ICollection } from '../../../app/models/Collection'

interface IProps {
	collection?: ICollection
}

const CollectionPodComponent: React.FC<IProps> = ({ collection }) => {

	// this method to request a pod from parcel perfect.. to be implemented
	// const requestPod = () => {
	// 	getPod(collection?.id!)
	// 		.then(result => {
	// 			setPod({title: 'POD', link: result.url, id: result.url, publicId: result.url})
	// 		});
	// }

	const content = collection?.attachments.map(pod => pod.title === 'POD' &&
		<a target='_blank' rel='noreferrer' href={pod.link}>Download {pod.title}</a>
	)

	return (
		<>
			{content && content.length > 0 &&
				<div style={{ marginBottom: '16px' }}>
					{content}
				</div>
			}
		</>
	)
}

export default CollectionPodComponent
