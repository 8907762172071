import React, { useContext, useEffect, useState } from 'react'
import { RootStoreContext } from '../../../app/stores/rootStore'
import { Table, Input, Row, TablePaginationConfig, Card, Button, Col } from 'antd';
import { DeleteOutlined, EditOutlined, PlusSquareOutlined } from '@ant-design/icons';
import FeatureHeading from '../../../app/components/headings/FeatureHeading';
import { observer } from 'mobx-react-lite';
import { IRequestData, OrderByType } from '../../../app/models/requestData';
import { IUser } from '../../../app/models/user';
import ConfirmationModal from '../../../app/components/modals/ConfirmationModal';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import CustomFormModal from '../../../app/components/modals/CustomFormModal';
import RegisterForm from './RegisterForm';
import UpdateForm from './UpdateForm';
import { history } from '../../..';

const ListUsers = () => {
	const rootStore = useContext(RootStoreContext);
	const { getUsers, deleteUser } = rootStore.userStore;
	const { Search } = Input;

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<{}[]>([]);
	const [request, setRequest] = useState<IRequestData>({})
	const [total, setTotal] = useState<number>()
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [showRegisterModal, setShowRegisterModal] = useState<boolean>(false);
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [tableDataUpdated, setTableDataUpdated] = useState<boolean>(false);
	const [userToModify, setUserToModify] = useState<Partial<IUser>>()

	const fetchData = () => {
		setLoading(true);
		getUsers(request)
			.then((response) => {
				setTotal(response.count)
				const users = response.data;
				if (users) {
					setData(users.map((x, index) => {
						return {
							id: x.id,
							name: x.name,
							surname: x.surname,
							company: x.company?.companyName,
							displayName: x.displayName,
							key: index,
						};
					}));
					setLoading(false);
				}
			})
			.finally(() => setLoading(false));
	}

	const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
		let updatedRequest = request;
		request.limit = pagination?.pageSize;
		request.offset = ((pagination.pageSize || 10) * (pagination.current || 1)) - (pagination.pageSize || 10);
		request.orderByColumn = sorter?.column?.dataIndex;
		request.orderByType = sorter?.order === 'ascend' ? OrderByType.asc : OrderByType.desc;

		setRequest(updatedRequest);
		fetchData();
	};

	const onSearch = (value: string) => {
		let updatedRequest = request;
		request.keyword = value;
		setRequest(updatedRequest);
		fetchData();
	}

	const onDelete = (record: IUser) => {
		setShowConfirmation(true);
		setUserToModify(record);
	}

	const onEdit = (record: IUser) => {
		setShowEditModal(true);
		setUserToModify(record);
	}

	const onCreateCollection = (record: IUser) => {
		history.push('/collections/new/' + record.id)
	}

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!tableDataUpdated) {
			return;
		}

		if (!showRegisterModal || !showEditModal) {
			fetchData();
		}

		setTableDataUpdated(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showRegisterModal, showEditModal]);

	const columns = [
		{
			title: 'Edit',
			dataIndex: 'edit',
			key: 'edit',
			width: '5%',
			render: (text: any, record: any) =>
				<Button
					htmlType='button'
					title={'Edit ' + record.displayName}
					style={{ border: 0 }}
					icon={<EditOutlined />} 
					onClick={() => onEdit(record)}
				/>,
		},
		{
			title: 'Delete',
			dataIndex: 'delete',
			key: 'delete',
			width: '5%',
			render: (text: any, record: any) => <Button title={'Delete ' + record.displayName} style={{ border: 0 }} icon={<DeleteOutlined style={{ color: 'red' }} />} onClick={() => onDelete(record)} />,
		},
		{
			title: 'Collection',
			dataIndex: 'collection',
			key: 'collection',
			width: '5%',
			render: (text: any, record: any) => <Button title={'Create new collection for ' + record.displayName} style={{ border: 0 }} icon={<PlusSquareOutlined />} onClick={() => onCreateCollection(record)} />,
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			width: '20%',
			sorter: true
		},
		{
			title: 'Surname',
			dataIndex: 'surname',
			key: 'surname',
			width: '20%',
			sorter: true
		},
		{
			title: 'Company',
			dataIndex: 'company',
			key: 'company',
			sorter: true
		},
	];

	return (
		<>
			<FeatureHeading value='Users' />
			<Card>
				<Row style={{ paddingBottom: '3px' }}>
					<Col>
						<Search
							placeholder="input search text"
							onSearch={onSearch}
							style={{ width: 200 }}
						/>
					</Col>
					<Col style={{ marginLeft: 'auto' }}>
						<Button type='link' onClick={fetchData} icon={<RedoOutlined />}></Button>
						<Button type='link' onClick={() => setShowRegisterModal(true)} icon={<PlusOutlined />}></Button>
					</Col>
				</Row>
				<Table
					pagination={{ pageSize: request?.limit, showSizeChanger: true, total: total }}
					scroll={{ x: true }}
					loading={loading}
					columns={columns}
					dataSource={data}
					onChange={handleTableChange}
				/>
			</Card>
			<ConfirmationModal
				callback={async () => {
					setLoading(true);
					deleteUser(userToModify?.id || '')
						.then(() => fetchData())
						.finally(() => setLoading(false));
				}}
				message={`Please confirm you want to delete ${userToModify?.displayName || ''}`}
				visible={showConfirmation}
				setVisible={setShowConfirmation}
			/>
			<CustomFormModal
				setVisible={setShowRegisterModal}
				title={'Register New User'}
				visible={showRegisterModal}
			>
				<RegisterForm
					closeModal={() => {
						setTableDataUpdated(true);
						setShowRegisterModal(false);
					}}
				/>
			</CustomFormModal>
			<CustomFormModal
				setVisible={setShowEditModal}
				title={'Update User - ' + userToModify?.displayName || 'default'}
				visible={showEditModal}
			>
				<UpdateForm
					id={userToModify?.id || ''}
					closeModal={() => {
						setTableDataUpdated(true);
						setShowEditModal(false);
					}}
				/>
			</CustomFormModal>
		</>
	)
}

export default observer(ListUsers)
