import { IAddress } from './Address';
import { IMediaItem } from './MediaItem';
import { IServiceType } from './ServiceType';
import { ISupplier } from './Supplier';
import { IUser } from './user';

export interface ICollection {
	id: string;
	requester: IUser;
	waybillNumber: number;
	waybill: string;
	supplierWaybill: string;
	status: CollectionStatus;
	serviceType: IServiceType;
	sender: IAddress;
	receiver: IAddress;
	contents: ICollectionContent[];
	attachments: IMediaItem[];
	shipmentMass: number;
	myRate: number;
	myVat: number;
	invoiced: boolean;
	readyDate?: Date;
	readyTime: string;
	closingTime: string;
	insurance: boolean;
	comment: string;
	supplier?: ISupplier;
}

export interface IAdminCollection extends ICollection {
	supplierRate: number;
	supplierVat: number;
}

export interface ICollectionContent {
	id: string;
	width: number;
	height: number;
	length: number;
	weight: number;
	pieces: number;
	description: string;
}

export enum CollectionStatus {
	Draft,
	Requested,
	Accepted,
	BeingProcessed,
	Cancelled,
	Declined,
	Completed,
	Pending,
	Collected,
	Delivered
}

export interface INewCollectionFormValues {
	userId: string;
}

export interface ISetCollectionAddressesValues {
	collectionId: string;
	senderAddressId: string;
	receiverAddressId: string;
}

export interface IReplaceCollectionContentValues {
	collectionId: string;
	contents: ICollectionContent[];
}

export interface ICollectionDetailsValues {
	collectionId: string;
	serviceTypeId: string;
	readyDate: Date;
	readyTime: string;
	closingTime: string;
	insurance: boolean;
	comment?: string;
}

export interface ICollectionListRequest {
	keyword?: string;
	status?: CollectionStatus;
	requesterId?: string;
	supplierId?: string;
	from: Date;
	to: Date;
	invoiced?: number;
	offset?: number;
	limit?: number;
}

export interface ICreateCollectionEmailNotification {
	attachment: Blob;
	email: string;
	collectionId: string;
}

export interface IPod {
	url: string;
}

export interface IUpdateComment {
	collectionId: string;
	comment: string;
}
