import { Button, Table } from 'antd';
import React from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { IServiceType } from '../../../../app/models/ServiceType';

interface IProps {
	onEdit?: (content: IServiceType) => void;
	onDelete?: (content: IServiceType) => void;
	loading: boolean;
	data?: IServiceType[];
}

const SupplierServiceTableComponent: React.FC<IProps> = ({onEdit, onDelete, loading, data}) => {

	let columns: any[] = [
		{
			title: 'Code',
			dataIndex: 'code',
			key: 'code',
			width: '25%',
		},
		{
			title: 'Display Name',
			dataIndex: 'displayName',
			key: 'displayName',
			width: '75%',
		}
	];

	if (onEdit && onDelete) {
		columns = [{
			title: 'Edit',
			dataIndex: 'edit',
			key: 'edit',
			width: '5%',
			render: (text: any, record: IServiceType) =>
				<Button
					title={'Edit'}
					style={{ border: 0 }}
					icon={<EditOutlined />}
					onClick={() => onEdit(record)}
				/>,
		},
		{
			title: 'Delete',
			dataIndex: 'delete',
			key: 'delete',
			width: '5%',
			render: (text: any, record: IServiceType) =>
				<Button
					title={'Delete '}
					style={{ border: 0 }}
					icon={<DeleteOutlined style={{ color: 'red' }} />}
					onClick={() => onDelete(record)}
				/>,
		}, ...columns]
	}

	return (
		<Table
			scroll={{ x: true }}
			loading={loading}
			columns={columns}
			dataSource={data}
		/>
	)
}

export default SupplierServiceTableComponent
