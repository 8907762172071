import { Descriptions } from 'antd'
import React, { useEffect, useState } from 'react'
import { IAddress } from '../../../app/models/Address'

interface IProps {
	title: 'sender' | 'receiver';
	address?: IAddress;
}

const CollectionAddressDescriptionsComponent: React.FC<IProps> = ({ address, title }) => {
	const [displayedAddress, setDisplayedAddress] = useState<IAddress>();

	useEffect(() => {
		setDisplayedAddress(address);
	}, [address, title])

	return (
		<>
			<Descriptions title={`${title === 'sender' ? 'Sender' : 'Receiver'} Info`} column={1}>
				<Descriptions.Item label="Address Name">{displayedAddress?.name}</Descriptions.Item>
				<Descriptions.Item label="Address line 1">{displayedAddress?.addressLine1}</Descriptions.Item>
				<Descriptions.Item label="Address line 2">{displayedAddress?.addressLine2}</Descriptions.Item>
				<Descriptions.Item label="Address line 3">{displayedAddress?.addressLine3}</Descriptions.Item>
				<Descriptions.Item label="Address line 4">{displayedAddress?.addressLine4}</Descriptions.Item>
				<Descriptions.Item label="City">{displayedAddress?.city}</Descriptions.Item>
				<Descriptions.Item label="Postal Code">{displayedAddress?.postalCode}</Descriptions.Item>
				<Descriptions.Item label="Country">{displayedAddress?.country}</Descriptions.Item>
				<Descriptions.Item label="Contact Person">{displayedAddress?.contactPerson}</Descriptions.Item>
				<Descriptions.Item label="Contact Cell">{displayedAddress?.contactCell}</Descriptions.Item>
				<Descriptions.Item label="Contact Phone">{displayedAddress?.contactPhone}</Descriptions.Item>
				<Descriptions.Item label="Contact Email">{displayedAddress?.contactEmail}</Descriptions.Item>
			</Descriptions>
		</>
	)
}

export default CollectionAddressDescriptionsComponent
