import { Button, Result } from 'antd'
import React from 'react'
import { history } from '../..'

const Forbidden = () => {
	return (
		<Result
			status="403"
			title="403"
			subTitle="Sorry, you do not have permissions to view this page."
			extra={<Button onClick={() => history.push('/collections')} type="primary">Back to Collections</Button>}
		/>
	)
}

export default Forbidden
