import { Col, Form, Select, Input } from 'antd';
import React from 'react';
import {
	CollectionStatus,
	IAdminCollection
} from '../../../app/models/Collection';

interface IProps {
	collection: Partial<IAdminCollection>;
	setCollection: (collection: Partial<IAdminCollection>) => void;
}

const CollectionWaybillFormComponent: React.FC<IProps> = ({
	collection,
	setCollection
}) => {
	const setStatus = (value: CollectionStatus) => {
		setCollection({ ...collection, status: value });
	};

	const setWaybill = (value: string) => {
		setCollection({ ...collection, waybill: value });
	};

	const setSupplierWaybill = (value: string) => {
		setCollection({ ...collection, supplierWaybill: value });
	};

	return (
		<>
			<div id="CustomCollectionWaybillFormComponentContainer">
				<Col sm={24} md={16} xl={8}>
					<Form layout={'vertical'}>
						<Form.Item label={'Requested By:'}>
							<Input
								autoComplete="off"
								defaultValue={`${collection?.requester?.name} ${collection?.requester?.surname} (${collection?.requester?.company?.companyName})`}
								disabled
							/>
						</Form.Item>
						<Form.Item label={'Status:'}>
							<Select
								virtual={false}
								placeholder="select a service"
								onChange={(value) => setStatus(value)}
								defaultValue={collection.status}
								getPopupContainer={() =>
									document.getElementById(
										'CustomCollectionWaybillFormComponentContainer'
									)!
								}
							>
								<Select.Option
									key={CollectionStatus.Accepted}
									value={CollectionStatus.Accepted}
								>
									{CollectionStatus[CollectionStatus.Accepted]
										.charAt(0)
										.toUpperCase() +
										CollectionStatus[
											CollectionStatus.Accepted
										].slice(1)}
								</Select.Option>
								<Select.Option
									key={CollectionStatus.BeingProcessed}
									value={CollectionStatus.BeingProcessed}
								>
									{CollectionStatus[
										CollectionStatus.BeingProcessed
									]
										.charAt(0)
										.toUpperCase() +
										CollectionStatus[
											CollectionStatus.BeingProcessed
										].slice(1)}
								</Select.Option>
								<Select.Option
									key={CollectionStatus.Cancelled}
									value={CollectionStatus.Cancelled}
								>
									{CollectionStatus[
										CollectionStatus.Cancelled
									]
										.charAt(0)
										.toUpperCase() +
										CollectionStatus[
											CollectionStatus.Cancelled
										].slice(1)}
								</Select.Option>
								<Select.Option
									key={CollectionStatus.Collected}
									value={CollectionStatus.Collected}
								>
									{CollectionStatus[
										CollectionStatus.Collected
									]
										.charAt(0)
										.toUpperCase() +
										CollectionStatus[
											CollectionStatus.Collected
										].slice(1)}
								</Select.Option>
								<Select.Option
									key={CollectionStatus.Completed}
									value={CollectionStatus.Completed}
								>
									{CollectionStatus[
										CollectionStatus.Completed
									]
										.charAt(0)
										.toUpperCase() +
										CollectionStatus[
											CollectionStatus.Completed
										].slice(1)}
								</Select.Option>
								<Select.Option
									key={CollectionStatus.Declined}
									value={CollectionStatus.Declined}
								>
									{CollectionStatus[CollectionStatus.Declined]
										.charAt(0)
										.toUpperCase() +
										CollectionStatus[
											CollectionStatus.Declined
										].slice(1)}
								</Select.Option>
								<Select.Option
									key={CollectionStatus.Delivered}
									value={CollectionStatus.Delivered}
								>
									{CollectionStatus[
										CollectionStatus.Delivered
									]
										.charAt(0)
										.toUpperCase() +
										CollectionStatus[
											CollectionStatus.Delivered
										].slice(1)}
								</Select.Option>
								<Select.Option
									key={CollectionStatus.Pending}
									value={CollectionStatus.Pending}
								>
									{CollectionStatus[CollectionStatus.Pending]
										.charAt(0)
										.toUpperCase() +
										CollectionStatus[
											CollectionStatus.Pending
										].slice(1)}
								</Select.Option>
								<Select.Option
									key={CollectionStatus.Requested}
									value={CollectionStatus.Requested}
								>
									{CollectionStatus[
										CollectionStatus.Requested
									]
										.charAt(0)
										.toUpperCase() +
										CollectionStatus[
											CollectionStatus.Requested
										].slice(1)}
								</Select.Option>
							</Select>
						</Form.Item>
						<Form.Item label={'Waybill:'}>
							<Input
								autoComplete="off"
								defaultValue={collection.waybill}
								maxLength={60}
								onChange={(value) => {
									setWaybill(value.target.value);
								}}
							/>
						</Form.Item>
						<Form.Item label={'Waybill number:'}>
							<Input
								autoComplete="off"
								defaultValue={collection.waybillNumber}
								maxLength={60}
								disabled
							/>
						</Form.Item>
						<Form.Item label={'Supplier Waybill:'}>
							<Input
								autoComplete="off"
								defaultValue={collection.supplierWaybill}
								maxLength={60}
								onChange={(value) => {
									setSupplierWaybill(value.target.value);
								}}
							/>
						</Form.Item>
					</Form>
				</Col>
			</div>
		</>
	);
};

export default CollectionWaybillFormComponent;
