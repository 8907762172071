import { action, makeObservable } from 'mobx'
import agent from '../api/agent';
import { ICompany } from '../models/company';
import { IRequestData } from '../models/requestData';
import { IQueryableResponse } from '../models/queryableResponse';
import { RootStore } from './rootStore';

export default class CompanyStore {

	rootStore: RootStore;
	
	constructor(rootStore: RootStore) {
		makeObservable(this);
		this.rootStore = rootStore;
	}

	@action register = async (values: ICompany) => {
		try {
			await agent.Company.create(values);
		} catch (error) {
			throw error;
		}
	}

	@action edit = async (values: ICompany) => {
		try {
			await agent.Company.edit(values);
		} catch (error) {
			throw error;
		}
	}

	@action getCompanies = async (request: IRequestData = {}): Promise<IQueryableResponse<ICompany>> => {
		try {
			return await agent.Company.list(request);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}

	@action get = async (id: string): Promise<ICompany> => {
		try {
			return await agent.Company.get(id);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}

	@action deleteCompany = async (id: string): Promise<void> => {
		try {
			await agent.Company.delete(id);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}
}