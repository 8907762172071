import { makeObservable, action } from "mobx";
import agent from "../api/agent";
import { IAddress } from "../models/Address";
import { IQueryableResponse } from "../models/queryableResponse";
import { IRequestData } from "../models/requestData";
import { IServerSuccessResponse } from "../models/ServerSuccessResponse";
import { RootStore } from "./rootStore";

export default class AddressStore {
	rootStore: RootStore;
	
	constructor(rootStore: RootStore) {
		makeObservable(this);
		this.rootStore = rootStore;
	}

	@action register = async (values: IAddress): Promise<IServerSuccessResponse> => {
		try {
			return await agent.Address.create(values);
		} catch (error) {
			throw error;
		}
	}

	@action edit = async (values: IAddress): Promise<IServerSuccessResponse> => {
		try {
			return await agent.Address.edit(values);
		} catch (error) {
			throw error;
		}
	}

	@action getAddresses = async (companyId: string, request: IRequestData = {}): Promise<IQueryableResponse<IAddress>> => {
		try {
			return await agent.Address.list(companyId, request);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}

	@action get = async (id: string): Promise<IAddress> => {
		try {
			let result = await agent.Address.get(id);
			result.companyId = result.company.id;
			return result;
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}

	@action deleteAddress = async (id: string): Promise<void> => {
		try {
			await agent.Address.delete(id);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}
}