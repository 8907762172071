import { Form, Input, Button, Skeleton, Select } from 'antd';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { ICompany } from '../../app/models/company';
import { RootStoreContext } from '../../app/stores/rootStore';
import { IRate } from '../../app/models/Rate';
import { IServiceType } from '../../app/models/ServiceType';

interface IProps {
	closeModal: (result: IRate) => void;
	id?: string;
	company: ICompany;
	serviceTypes: IServiceType[];
}

const RateForm: React.FC<IProps> = ({
	closeModal,
	id,
	company,
	serviceTypes
}) => {
	const rootStore = useContext(RootStoreContext);
	const { edit, register, getById } = rootStore.rateStore;

	const [editMode, setEditMode] = useState(false);
	const [loading, setLoading] = useState(false);
	const [initialValues, setInitialValues] = useState<Partial<IRate>>();

	const onFinish = async (values: any) => {
		setLoading(true);
		values.companyId = company.id;
		if (editMode) {
			edit(values)
				.then(() => {
					closeModal(values);
				})
				.finally(() => setLoading(false));
		} else {
			register(values)
				.then(() => {
					closeModal(values);
				})
				.finally(() => setLoading(false));
		}
	};

	const onFinishFailed = (errorInfo: any) => {
		setLoading(false);
	};

	useEffect(() => {
		if (id) {
			setEditMode(true);
			getById(id).then((result) => {
				setInitialValues(result);
			});
		} else {
			setInitialValues({ company: company });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return (
		<Fragment>
			<div id="CustomRateFormContainer">
				<Skeleton loading={!initialValues}>
					<Form
						labelCol={{ span: 6 }}
						wrapperCol={{ span: 18 }}
						layout="horizontal"
						initialValues={{
							...initialValues,
							serviceTypeIds: initialValues?.serviceTypes?.map(
								(it) => it.id
							)
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
					>
						<Form.Item name="companyId" hidden={true}>
							<Input autoComplete="off" value={company.id} />
						</Form.Item>
						<Form.Item name="serviceTypeIds" label="Service">
							<Select
								virtual={false}
								placeholder="select a service"
								showSearch
								mode="multiple"
								filterOption={(input, option) =>
									option?.props.title
										?.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}
								defaultValue={initialValues?.serviceTypes?.map(
									(it) => it.id
								)}
								getPopupContainer={() =>
									document.getElementById(
										'CustomRateFormContainer'
									)!
								}
							>
								{serviceTypes?.map((serviceType) => {
									return (
										<Select.Option
											key={serviceType.id}
											value={serviceType.id}
											title={
												serviceType.code +
												serviceType.displayName
											}
										>
											{`${serviceType.displayName} (${serviceType.code})`}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
						<Form.Item
							label="From"
							name="from"
							rules={[
								{ required: true, message: 'Field required!' }
							]}
						>
							<Input autoComplete="off" maxLength={50} />
						</Form.Item>
						<Form.Item
							label="To"
							name="to"
							rules={[
								{ required: true, message: 'Field required!' }
							]}
						>
							<Input autoComplete="off" maxLength={50} />
						</Form.Item>
						<Form.Item
							label="Minimum"
							name="minimum"
							rules={[
								{ required: true, message: 'Field required!' }
							]}
						>
							<Input
								autoComplete="off"
								type={'number'}
								maxLength={30}
							/>
						</Form.Item>
						<Form.Item
							label="Rate"
							name="value"
							rules={[
								{ required: true, message: 'Field required!' }
							]}
						>
							<Input
								autoComplete="off"
								type={'number'}
								maxLength={30}
							/>
						</Form.Item>
						<Form.Item
							label="Fuel"
							name="fuel"
							rules={[
								{ required: true, message: 'Field required!' }
							]}
						>
							<Input
								autoComplete="off"
								type={'number'}
								maxLength={30}
							/>
						</Form.Item>
						<Form.Item
							label="Surcharge"
							name="surcharge"
							rules={[
								{ required: true, message: 'Field required!' }
							]}
						>
							<Input
								autoComplete="off"
								type={'number'}
								maxLength={30}
							/>
						</Form.Item>
						<Form.Item
							label="Rate Apply After"
							name="rateApplyAfter"
							rules={[
								{ required: true, message: 'Field required!' }
							]}
						>
							<Input
								autoComplete="off"
								type={'number'}
								maxLength={30}
							/>
						</Form.Item>
						<Form.Item style={{ float: 'right' }}>
							<Button
								loading={loading}
								type="primary"
								htmlType="submit"
							>
								{editMode ? 'Save Changes' : 'Save Rate'}
							</Button>
						</Form.Item>
						<Form.Item hidden={true} name={'id'}>
							<Input autoComplete="off" />
						</Form.Item>
					</Form>
				</Skeleton>
			</div>
		</Fragment>
	);
};

export default RateForm;
