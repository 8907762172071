import React from 'react'

const TermsConditions = () => {
	return (
		<>
			<p><strong><u>TERMS &amp; CONDITIONS OF CARRIAGE</u></strong></p>
			<p><strong><u>&nbsp;</u></strong></p>
			<p>In these Terms and Conditions of Carriage of Goods the following words shall bear the meanings assigned to them below:</p>
			<p>“The Agreement” -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; means the agreement between the parties as contained in this document and quotations from time to time.</p>
			<p>“The Company” -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; means Cum Laude Freight Management (PTY) Ltd, Registration number 2014/091456/07 trading as Cum Laude Freight.</p>
			<p>“The Customer” -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; means the party instructing the Company to convey the Goods</p>
			<p>“The Goods” -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; means the Goods as described and handed to Carrier for conveyance</p>
			<p>&nbsp;</p>
			<ol>
				<li ><strong>CARRIAGE UNDERTAKEN SUBJECT TO CONDITONS OF CARRIAGE</strong>
					<p>The Company or its appointed subcontractors undertakes to transport the goods on behalf of the customer subject to these terms and conditions.</p>
					<p>1.1 Cum Laude Freight is not acting as a Carrier but contracts various freight carriers on behalf of the Customer for the purpose of obtaining discounted rates for transportation.</p>
				</li>
				<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
				<li><strong>LIMITATION OF LIABILITY</strong>
					<ul>
						<li>All transportation, loading or off-loading carried out is done at the exclusive risk of the Customer or owner of such goods. The company shall not be liable for any loss and/or damage whatsoever including, contractual, delictual and consequential damages, which the customer, owner of 3<sup>rd</sup> party may suffer as a result of the performance of the services, including negligent acts of omissions by the Company.</li>
						<li>The Company will not be responsible for shortages or damage to the goods whatsoever.</li>
					</ul>
				</li>
				<p>&nbsp;</p>
				<li><strong>TARIFFS AND QUOTATIONS.</strong>
					<p>3.1 The Customer shall pay the Company the amounts as proved for in a quotation OR</p>
					<p>3.2 &nbsp;the Company’s normal tariff structure at the time.</p>
					<p>3.3&nbsp; Quotations shall only be binding upon the Company if accepted by the Customer in writing within 7 days of the date thereof, failing which the Company shall be at liberty to withdraw, vary or revise any quotation.</p>
				</li>
				<p>&nbsp;</p>
				<li><strong> PAYMENT TERMS</strong>
					<p>4.1&nbsp; Accounts are payable within 30 (thirty) days of date of the Company’s statement.</p>
					<p>4.2&nbsp; Amounts payable to the Company in terms of this agreement shall be paid without deduction or set-off by the way of electronic transfer to the bank account of the Company, unless agreed otherwise in writing.</p>
					<p>4.3&nbsp; Interest on overdue amounts shall be charged to the Customer at 2.5 % above Standard banks’ prime overdraft rate from time to time.</p>
					<p>4.4&nbsp; The Customer shall not be entitled to withhold payment or any amount due to the Company by virtue of any claim the Customer may have for compensation for loss or damage to the goods, person or for any reason whatsoever.</p>
					<p>4.5&nbsp; In the event of the Customer being in arrears with any payment or in breach of any term of this agreement the supplier is entitled to cancel all contracts with immediate effect.</p>
				</li>
				<p>&nbsp;</p>
				<li><strong> DELIVERY</strong>
					<p>5.1&nbsp; The Company shall not be liable for any delay in delivery of the goods and/or any loss, damage or deterioration therein, unless the Company is given at least 24 hours written notice prior to the loading of the goods that the date and time of delivery of the goods is of the essence of the contract.</p>
					<p>5.2&nbsp; Delivery and performance times quoted are estimates and are not binding the Company.</p>
					<p>5.3&nbsp; Any delivery note, invoice or waybill signed by the Customer or a third party engaged to transport the goods, and held by the Company shall be conclusive proof that delivery was made to the customer.</p>
				</li>
				<p>&nbsp;</p>
				<li><strong> GENERAL INDEMNITY</strong>
					<p><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong>The Customer indemnifies the Company against any and all claims which may be made by third parties against the Company.</p>
				</li>
				<p>&nbsp;</p>
				<li><strong> OBLIGATIONS OF CUSTOMER</strong>
					<p>7.1&nbsp; The Customer undertakes to ensure that all applicable legislation, regulations and laws of South Africa in relations to the possession and transportations of the goods have been complied with, which shall include without limiting the generality of the aforementioned, the payment of all taxes (including customs and excise)</p>
					<p>7.2&nbsp; The Customer hereby indemnifies the Company against all damages, losses, charges, liabilities, fines, cost (including legal cost) which the Company may incur as a result of the transportation of their goods.</p>
				</li>
				<p>&nbsp;</p>
				<li><strong> INSURANCE</strong>
					<p>8.1&nbsp; Cum Laude Freight Management is not a registered financial institute and therefore cannot offer any insurance cover.</p>
					<p>8.2 The Customer will look solely to insurance provided by the carrier for damage to goods in transit. Each carrier’s governing tariff will determine the standard liability cargo insurance coverage offered on any shipment, subject to any exception value.</p>
				</li>
				<p>&nbsp;</p>
				<li><strong> FORCE MAJEURE</strong>
					<p>9.1 Performance of the obligations of the Company or any of its sub-contractors in terms this agreement shall be excused for as long, as and to the extent that, it is unable to perform because of any cause beyond its control, including but not limited to, an act of God, State of war, sabotage, fire, explosion, road conditions, riot and strike.</p>
				</li>
				<p>&nbsp;</p>
				<li><strong> THIRD PARTIES / SUPPLIERS (USE, LIABILITIES AND COSTS)</strong>
					<p>10.1 The Customer hereby irrevocably and in rem suam authorizes Cum Laude Freight to appoint third parties for the purpose of procuring the forwarding of the goods to their destinations on such conditions as may be stipulated by such third parties, and</p>
					<p>10.2 in the case of International Forwarding to disburse such amounts on behalf of the Customer as may be agreed with such third party with the Company within its sole discretion. The Company shall be entitled in addition to its charges, to recover such amounts disbursed from time to time, from the Customer.</p>
				</li>
				<p>&nbsp;</p>
			</ol>
		</>
	)
}

export default TermsConditions
