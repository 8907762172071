import { Page, Text, View, Document, StyleSheet, pdf, Image } from '@react-pdf/renderer';
import logo from '../../../assets/logo.png'
import { IAdminCollection } from '../../models/Collection';
import moment from 'moment';

interface IFinanceRecord {
	id: string;
	name: string;
	vat: number;
	value: number;
}

const styles = StyleSheet.create({
	body: {
		paddingTop: 35,
		paddingBottom: 65,
		paddingHorizontal: '5%',
	},
	headerText: {
		fontSize: 12,
		color: 'grey',
	},
	section: {
		marginTop: '10px'
	},
	footer: {
		position: 'absolute',
		bottom: 30,
		left: 30
	},
	footerHeading: {
		fontSize: 12,
		fontFamily: 'Helvetica-Bold',
		fontWeight: 'bold'
	},
	footerText: {
		fontSize: 12,
	},
	logo: {
		width: '240px'
	},
	logos: {
		flexDirection: 'row',
		maxHeight: '90px'
	},
	barcode: {
		marginLeft: '50px',
		width: '180px'
	},
	row: {
		flexDirection: 'row'
	},
	rowOdd: {
		flexDirection: 'row',
		backgroundColor: '#dedede',
	},
	column: {
		width: '50%'
	},
	column60: {
		width: '60%',
	},
	column80: {
		width: '80%',
		paddingRight: '10px',
	},
	column75: {
		width: '75%',
		paddingRight: '10px',
	},
	column20: {
		width: '20%',
	},
	column25: {
		width: '25%',
	},
	columnThree: {
		width: '33%'
	},
	columnFive: {
		width: '20%'
	},
	text: {
		fontSize: '15pt',
		maxHeight: '60px',
		lineHeight: '1.2'
	},
	textHeavy: {
		fontSize: '15pt',
		maxHeight: '18px',
		lineHeight: '1.2',
		fontWeight: 900,
		fontFamily: 'Helvetica-Bold'
	},
	textHeavyRight: {
		fontSize: '15pt',
		maxHeight: '18px',
		lineHeight: '1.2',
		fontWeight: 900,
		fontFamily: 'Helvetica-Bold',
		textAlign: 'right'
	},
	heading: {
		fontSize: '22pt',
		fontFamily: 'Helvetica-Bold',
		fontWeight: 'bold'
	},
	line: {
		padding: 0,
		margin: 0
	}
});

const line = (
	<View>
		<Text style={[styles.line, styles.text]}>-------------------------------------------------------------------------------------------------------------</Text>
	</View>
);

interface DocumentProps {
	supplierData: IFinanceRecord[]; clientData: IFinanceRecord[]; from: Date; to: Date;
}

const MyDocument = (props: DocumentProps) => {
	const supplierVat = props.supplierData.reduce((temp, x) => temp + x.vat, 0);
	const supplierRate = props.supplierData.reduce((temp, x) => temp + x.value, 0);
	const myVat = props.clientData.reduce((temp, x) => temp + x.vat, 0);
	const myRate = props.clientData.reduce((temp, x) => temp + x.value, 0);

	const isOdd = (num: number) => { return num % 2 === 1};

	return (
		<Document>
			<Page style={styles.body}>
				<View style={styles.logos}>
					<Image
						style={styles.logo}
						src={logo}
					/>
				</View>
				<View style={styles.section}>
					<Text style={styles.heading}>
						Revenue Report
					</Text>
					<Text style={styles.text}>
						{moment(props.from).format("D MMMM yyyy")} - {moment(props.to).format("D MMMM yyyy")}
					</Text>
				</View>
				{line}
				<View style={styles.section}>
					<Text style={styles.heading}>
						Suppliers
					</Text>
					{props.supplierData.map((x, i) => {
						return (
							<View style={isOdd(i) ? styles.rowOdd : styles.row}>
								<View style={styles.column60}>
									<Text style={styles.text}>
										{x.name}
									</Text>
								</View>
								<View style={styles.column20}>
									<Text style={styles.text}>
										{x.vat.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })};
									</Text>
								</View>
								<View style={styles.column20}>
									<Text style={styles.text}>
										{x.value.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })};
									</Text>
								</View>
							</View>
						)
					})}
					<View style={styles.row}>
						<Text style={styles.text}> </Text>
					</View>
					<View style={styles.row}>
						<View style={styles.column75}>
							<Text style={styles.textHeavyRight}>
								TOTAL VAT:
							</Text>
						</View>
						<View style={styles.column25}>
							<Text style={styles.textHeavy}>
								{supplierVat.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })};
							</Text>
						</View>
					</View>
					<View style={styles.row}>
						<View style={styles.column75}>
							<Text style={styles.textHeavyRight}>
								TOTAL SUPPLIERS:
							</Text>
						</View>
						<View style={styles.column25}>
							<Text style={styles.textHeavy}>
								{supplierRate.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })};
							</Text>
						</View>
					</View>
				</View>

				{line}

				<View style={styles.section}>
					<Text style={styles.heading}>
						Clients
					</Text>
					{props.clientData.map((x, i) => {
						return (
							<View style={isOdd(i) ? styles.rowOdd : styles.row}>
								<View style={styles.column60}>
									<Text style={styles.text}>
										{x.name}
									</Text>
								</View>
								<View style={styles.column20}>
									<Text style={styles.text}>
										{x.vat.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })};
									</Text>
								</View>
								<View style={styles.column20}>
									<Text style={styles.text}>
										{x.value.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })};
									</Text>
								</View>
							</View>
						)
					})}
					<View style={styles.row}>
						<Text style={styles.text}> </Text>
					</View>
					<View style={styles.row}>
						<View style={styles.column75}>
							<Text style={styles.textHeavyRight}>
								TOTAL VAT:
							</Text>
						</View>
						<View style={styles.column25}>
							<Text style={styles.textHeavy}>
								{myVat.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })};
							</Text>
						</View>
					</View>
					<View style={styles.row}>
						<View style={styles.column75}>
							<Text style={styles.textHeavyRight}>
								TOTAL CLIENTS:
							</Text>
						</View>
						<View style={styles.column25}>
							<Text style={styles.textHeavy}>
								{myRate.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })};
							</Text>
						</View>
					</View>
					{line}
					<View style={styles.row}>
						<View style={styles.column75}>
							<Text style={styles.textHeavyRight}>
								TOTAL PROFIT:
							</Text>
						</View>
						<View style={styles.column25}>
							<Text style={styles.textHeavy}>
								{(myRate - supplierRate ).toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })};
							</Text>
						</View>
					</View>
					{line}
				</View>

			</Page>
		</Document>
	);
}

const calculateSupplierData = (collections: IAdminCollection[]): IFinanceRecord[] => {
	const result: IFinanceRecord[] = [];
	collections.forEach(x => {
		if (!x.supplier || !x.supplierRate || !x.supplierVat) {
			return;
		}

		if (!result.some(r => r.id === x.supplier?.id)) {
			result.push({ id: x.supplier.id, name: x.supplier.name, vat: x.supplierVat, value: x.supplierRate });
		} else {
			let temp = result.filter(s => s.id === x.supplier?.id)[0];
			temp.vat = temp.vat + x.supplierVat;
			temp.value = temp.value + x.supplierRate;
		}
	});

	return result;
}

const calculateClientData = (collections: IAdminCollection[]): IFinanceRecord[] => {
	const result: IFinanceRecord[] = [];
	collections.forEach(x => {
		if (!x.requester.company || !x.myRate || !x.myVat) {
			return;
		}

		if (!result.some(r => r.id === x.requester?.company.id)) {
			result.push({ id: x.requester.company.id, name: x.requester.company.companyName, vat: x.myVat, value: x.myRate });
		} else {
			let temp = result.filter(s => s.id === x.requester.company.id)[0];
			temp.vat = temp.vat + x.myVat;
			temp.value = temp.value + x.myRate;
		}
	});

	return result;
}

export const download = async (collections: IAdminCollection[], from: Date, to: Date): Promise<void> => {
	var supplierData = calculateSupplierData(collections);
	var clientData = calculateClientData(collections);

	var FileSaver = require('file-saver');
	const blob = await pdf(
		<MyDocument supplierData={supplierData} clientData={clientData} from={from} to={to} />
	).toBlob();
	FileSaver.saveAs(blob, `report.pdf`);
};

// export const open = async (collection: ICollection): Promise<void> => {
// 	const blob = await pdf(
// 		<MyDocument {...collection} />
// 	).toBlob();
// 	const url = URL.createObjectURL(blob);
// 	window.open(url);
// }