export interface IRequestData {
	keyword?: string;
	offset?: number;
	limit?: number;
	orderByColumn?: string;
	orderByType?: OrderByType
}

export enum OrderByType {
	asc, 
	desc
}