import { Button, Table } from 'antd';
import React from 'react'
import { ICollectionContent } from '../../../app/models/Collection';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

interface IProps {
	onEdit?: (content: ICollectionContent) => void;
	onDelete?: (content: ICollectionContent) => void;
	loading: boolean;
	data?: ICollectionContent[];
}

const CollectionContentsTableComponent: React.FC<IProps> = ({onEdit, onDelete, loading, data}) => {

	let columns: any[] = [
		{
			title: 'Description',
			dataIndex: 'description',
			key: 'description',
			width: '17%',
		},
		{
			title: 'Length',
			dataIndex: 'length',
			key: 'length',
			width: '17%',
		},
		{
			title: 'Width',
			dataIndex: 'width',
			key: 'width',
			width: '17%',
		},
		{
			title: 'Height',
			dataIndex: 'height',
			key: 'height',
			width: '17%',
		},
		{
			title: 'Weight',
			dataIndex: 'weight',
			key: 'weight',
			width: '17%',
		},
		{
			title: 'Pieces',
			dataIndex: 'pieces',
			key: 'pieces',
			width: '17%',
		},
	];

	if (onEdit && onDelete) {
		columns = [{
			title: 'Edit',
			dataIndex: 'edit',
			key: 'edit',
			width: '5%',
			render: (text: any, record: ICollectionContent) =>
				<Button
					title={'Edit'}
					style={{ border: 0 }}
					icon={<EditOutlined />}
					onClick={() => onEdit(record)}
				/>,
		},
		{
			title: 'Delete',
			dataIndex: 'delete',
			key: 'delete',
			width: '5%',
			render: (text: any, record: ICollectionContent) =>
				<Button
					title={'Delete '}
					style={{ border: 0 }}
					icon={<DeleteOutlined style={{ color: 'red' }} />}
					onClick={() => onDelete(record)}
				/>,
		}, ...columns]
	}

	return (
		<Table
			scroll={{ x: true }}
			loading={loading}
			columns={columns}
			dataSource={data}
		/>
	)
}

export default CollectionContentsTableComponent
