import React, { useContext, useEffect, useState } from 'react'
import { Table, Input, Row, TablePaginationConfig, Card, Button, Col } from 'antd';
import { RootStoreContext } from '../../app/stores/rootStore';
import { ISupplier } from '../../app/models/Supplier';
import { IRequestData, OrderByType } from '../../app/models/requestData';
import { PlusOutlined, RedoOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import FeatureHeading from '../../app/components/headings/FeatureHeading';
import ConfirmationModal from '../../app/components/modals/ConfirmationModal';
import CustomFormModal from '../../app/components/modals/CustomFormModal';
import SupplierForm from './SupplierForm';

const ListSuppliers = () => {
	const rootStore = useContext(RootStoreContext);
	const { getSuppliers, deleteSupplier } = rootStore.supplierStore;
	const { Search } = Input;

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<{}[]>([]);
	const [request, setRequest] = useState<IRequestData>({})
	const [total, setTotal] = useState<number>()
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [showRegisterModal, setShowRegisterModal] = useState<boolean>(false);
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [tableDataUpdated, setTableDataUpdated] = useState<boolean>(false);
	const [recordToModify, setRecordToModify] = useState<Partial<ISupplier>>()

	const fetchData = () => {
		setLoading(true);
		getSuppliers(request)
			.then((response) => {
				setTotal(response.count)
				const suppliers = response.data;
				if (suppliers) {
					setData(suppliers);
					setLoading(false);
				}
			})
			.finally(() => setLoading(false));
	}

	const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
		let updatedRequest = request;
		request.limit = pagination?.pageSize;
		request.offset = ((pagination.pageSize || 10) * (pagination.current || 1)) - (pagination.pageSize || 10);
		request.orderByColumn = sorter?.column?.dataIndex;
		request.orderByType = sorter?.order === 'ascend' ? OrderByType.asc : OrderByType.desc;

		setRequest(updatedRequest);
		fetchData();
	};

	const onSearch = (value: string) => {
		let updatedRequest = request;
		request.keyword = value;
		setRequest(updatedRequest);
		fetchData();
	}

	const onDelete = async (record: ISupplier) => {
		setShowConfirmation(true);
		setRecordToModify(record);
	}

	const onEdit = async (record: ISupplier) => {
		setShowEditModal(true);
		setRecordToModify(record);
	}

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!tableDataUpdated) {
			return;
		}

		if (!showRegisterModal || !showEditModal) {
			fetchData();
		}

		setTableDataUpdated(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showRegisterModal, showEditModal]);

	const columns = [
		{
			title: 'Edit',
			dataIndex: 'edit',
			key: 'edit',
			width: '5%',
			render: (text: any, record: any) =>
				<Button
					title={'Edit ' + record.displayName}
					style={{ border: 0 }}
					icon={<EditOutlined />} 
					onClick={() => onEdit(record)}
				/>,
		},
		{
			title: 'Delete',
			dataIndex: 'delete',
			key: 'delete',
			width: '7%',
			render: (text: any, record: any) => <Button title={'Delete ' + record.companyName} style={{ border: 0 }} icon={<DeleteOutlined style={{ color: 'red' }} />} onClick={() => onDelete(record)} />,
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			width: '88%',
			sorter: true
		},
	];

	return (
		<>
			<FeatureHeading value='Suppliers' />
			<Card>
				<Row style={{ paddingBottom: '3px' }}>
					<Col>
						<Search
							placeholder="input search text"
							onSearch={onSearch}
							style={{ width: 200 }}
						/>
					</Col>
					<Col style={{marginLeft: 'auto'}}>
						<Button type='link' onClick={fetchData} icon={<RedoOutlined />}></Button>
						<Button type='link' onClick={() => setShowRegisterModal(true)} icon={<PlusOutlined />}></Button>
					</Col>
				</Row>
				<Table
					pagination={{ pageSize: request?.limit, showSizeChanger: true, total: total }}
					scroll={{ x: true }}
					loading={loading}
					columns={columns}
					dataSource={data}
					onChange={handleTableChange}
				/>
			</Card>
			<ConfirmationModal
				callback={async () => {
					setLoading(true);
					deleteSupplier(recordToModify?.id || '')
						.then(() => fetchData())
						.finally(() => setLoading(false));
				}}
				message={`Please confirm you want to delete ${recordToModify?.name}`}
				visible={showConfirmation}
				setVisible={setShowConfirmation}
			/>
			<CustomFormModal
				setVisible={setShowRegisterModal}
				title={'Register New Supplier'}
				visible={showRegisterModal}
			>
				<SupplierForm
					closeModal={() => {
						setTableDataUpdated(true);
						setShowRegisterModal(false);
					}}
				/>
			</CustomFormModal>
			<CustomFormModal
				setVisible={setShowEditModal}
				title={'Update Supplier'}
				visible={showEditModal}
			>
				<SupplierForm
					id={recordToModify?.id || ''}
					closeModal={() => {
						setTableDataUpdated(true);
						setShowEditModal(false);
					}}
				/>
			</CustomFormModal>
		</>
	)
}

export default ListSuppliers
