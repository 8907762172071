import { Button, Card, Col, Input, Row } from 'antd'

import React, { useState } from 'react'
import { history } from '../../..';
import FeatureHeading from '../../../app/components/headings/FeatureHeading'

const Home = () => {
	const { Meta } = Card;
	const [waybill, setWaybill] = useState<string>();

	return (
		<>
			<Row id='home-home' style={{ margin: '-16px' }}>
				<div
					style={{
						backgroundImage: 'url("https://res.cloudinary.com/cumlaudefreight/image/upload/v1640893882/Website%20Pictures/banner_jstzvm.jpg")',
						width: '100%',
						height: '100vh',
						backgroundRepeat: 'round'
					}}
				>
					<div style={{
						width: '500px',
						maxWidth: '80vw',
						marginTop: '12%',
						marginLeft: '15%'
					}}
					>
						<FeatureHeading
							value='Cum Laude '
							style={{
								fontSize: '38pt',
								color: 'white',
								marginBottom: '0'
							}}
						/>
						<FeatureHeading
							value='Freight Management '
							style={{
								padding: '0',
								margin: '0',
								fontSize: '38pt',
								color: 'white'
							}}
						/>
						<Row style={{ maxWidth: '80vw' }}>
							<Col>
								<Input autoComplete='off' placeholder='Enter Your Waybill' onChange={(value) => setWaybill(value.currentTarget.value)}></Input>
							</Col>
							<Col>
								<Button className='home-button-tracknow' type='primary' onClick={() => history.push(`/collections?waybill=${waybill}`)}>Track Now</Button>
							</Col>
						</Row>
					</div>
				</div>
			</Row>

			<div id='home-services' style={{ marginTop: '34px' }}>
				<FeatureHeading value='Our Services' />
				<div className="site-card-wrapper">
					<Row gutter={16}>
						<Col md={8}>
							<Card
								hoverable
								cover={<img alt="example" src="https://res.cloudinary.com/cumlaudefreight/image/upload/v1640893894/Website%20Pictures/g3_mvnpgi.jpg" />}
							>
								<Meta
									title="Door-To-Door"
									description="Our domestic courier services will provide the perfect delivery solution for you with various time frames."
								/>
							</Card>
						</Col>
						<Col md={8}>
							<Card
								hoverable
								cover={<img alt="example" src="https://res.cloudinary.com/cumlaudefreight/image/upload/v1640893894/Website%20Pictures/g4_ezcmwr.jpg" />}
							>
								<Meta
									title="Domestic Airfreight"
									description={(
										<>
											<p>Overnight Express: Delivery by 10:30 am the following day.</p>

											<p>Same day Express: Shipments will be collected and taken immediately to the airport and sent off on the first available flight.</p>

											<p>Dawn Delivery: Delivered before 08:00 the following day.</p>

											<p>Budget Air: Delivered within a 24-hour time frame.</p>

											<p>Saturdays and public holidays</p>
										</>
									)}
								/>
							</Card>
						</Col>
						<Col md={8}>
							<Card
								hoverable
								cover={<img alt="example" src="https://res.cloudinary.com/cumlaudefreight/image/upload/v1640893894/Website%20Pictures/g2_v7ynhg.jpg" />}
							>
								<Meta
									title="Road Freight"
									description={(
										<>
											<p>The delivery service is completed within 1 - 4 business days from date of collection.</p>

											<ul>
												<li>
													Economy
												</li>
												<li>
													Express
												</li>
												<li>
													Cross border
												</li>
											</ul>
										</>
									)}
								/>
							</Card>
						</Col>
					</Row>
					<Row gutter={16} style={{ marginTop: '16px' }}>
						<Col md={8}>
							<Card
								hoverable
								cover={<img alt="example" src="https://res.cloudinary.com/cumlaudefreight/image/upload/v1640894005/Website%20Pictures/g5_ynorqc.jpg" />}
							>
								<Meta
									title="Sea Freight"
									description="As the most common form of transportation in International Trade today, clients will benefit from the variety of services Cum Laude Freight has to offer."
								/>
							</Card>
						</Col>
						<Col md={8}>
							<Card
								hoverable
								cover={<img alt="example" src="https://res.cloudinary.com/cumlaudefreight/image/upload/v1640893894/Website%20Pictures/g4_ezcmwr.jpg" />}
							>
								<Meta
									title="International Airfreight"
									description="The consignment is collected from your door in South Africa and delivered to the receiver's door anywhere in the world. Each destination is linked to a zone and these zones are linked to transit times for deliveries.									"
								/>
							</Card>
						</Col>

					</Row>
				</div>
			</div>

			<Row id='home-aboutus' style={{ marginLeft: '-16px', marginRight: '-16px', marginTop: '34px', paddingTop: '32px', backgroundColor: 'black' }} justify='center'>
				<FeatureHeading style={{ color: 'white' }} value='About Us' />
				<div style={{ padding: '0 25%', fontSize: '16pt' }}>
					<p style={{ color: 'red' }}>Cum Laude Freight Management aims to provide a logistical courier service that will exceed all your expectations. We are not just another courier service, but understand that a business is made up of many parts that need to work together efficiently in order for the business to be successful.</p>
					<p style={{ color: 'white' }}>With more than 30 years experience in the freight and logistics environment, Cum Laude Freight Management realise that there is more to a business than just parcels. If an important document or order is not delivered on time it could cost the business thousands, if not millions of Rands. We want to provide you with peace of mind that, what is important to your business, is also important to us - and we will do whatever it takes to make it happen on time.</p>
				</div>
			</Row>

			<div id='home-testimonials' style={{ marginTop: '34px' }}>
				<FeatureHeading value='Testimonials' />
				<Row justify='center'>
					<Col md={6} sm={24}>
						<Card>
							<p style={{ color: 'gray' }}>"Dear Llewellyn The purpose of this letter is to formally commend you and your team for the excellent service provided to Base 4 Aerotech once again. The level of service that you extend to our company goes far beyond our expectations, especially in this case, since you were not involved in the initial planning of getting our blades delivered. In an era where exceptional one-to-one customer service excellence has virtually disappeared from the courier industry, the work that you and your team do , should be held up as an example for other companies in the industry to try to emulate. It is quite evident , in my experience with dealing with you for the past year , that it is simply your extraordinary commitment to excellence in customer service and support , that motivates you to always go the extra mile. In closing , on behalf of myself and Base 4 management ,I would like to thank you once again. I would not have been able to pull this off without your assistance."</p>
							<p>CINDY ROSSOUW, STORE CONTROLLER BASE 4 AVIATION & BASE 4 AEROTECH</p>
						</Card>
					</Col>
					<Col md={2} sm={24}>

					</Col>
					<Col md={6} sm={24}>
						<Card>
							<p style={{ color: 'gray' }}>Dear Llewelyn I would like to thank you and your team for the excellent service we received from your company recently. Without previous notice you were able to deliver some very important documents to us in Nairobi from Cape Town, in time, which over the Christmas holiday period could not have been an easy task.</p>
							<p>BARBARA LINDBLAD, SWEDEN</p>
						</Card>
					</Col>
				</Row>
			</div>

			<div id='home-contactus' style={{ marginTop: '34px' }}>
				<FeatureHeading value='Contact Us' />
				<a href="mailto:info@cumlaudefreight.co.za">info@cumlaudefreight.co.za</a>
				<p>+27 (0)21 493 2819</p>
			</div>
		</>
	)
}

export default Home
