import { Button, Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import ConfirmationModal from '../../../app/components/modals/ConfirmationModal'
import CustomFormModal from '../../../app/components/modals/CustomFormModal'
import { ICollection, ICollectionContent } from '../../../app/models/Collection'
import { PlusOutlined } from '@ant-design/icons';
import CollectionContentForm from '../CollectionContentForm'
import CollectionContentsTableComponent from './CollectionContentsTableComponent'

interface IProps {
	collection: Partial<ICollection>;
	setCollection: (collection: Partial<ICollection>) => void;
	validateCallback?: (value: boolean) => void;
}

const CollectionContentsComponent: React.FC<IProps> = ({ collection, setCollection, validateCallback }) => {

	const [loading, setLoading] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [contentToModify, setContentToModify] = useState<ICollectionContent>();

	const onDelete = async (record: ICollectionContent) => {
		setContentToModify(record);
		setShowDeleteModal(true);
	}

	const onEdit = async (record: ICollectionContent) => {
		setContentToModify(record);
		setShowEditModal(true);
	}

	const setContents = (contents: ICollectionContent[]) => {
		setCollection({
			...collection,
			contents: contents.map(content => {
				return {
					description: content.description,
					id: content.id,
					height: +content.height,
					width: +content.width,
					length: +content.length,
					weight: +content.weight,
					pieces: +content.pieces,
				}
			}),
		});
	}

	const validate = () => {
		if (validateCallback) {
			validateCallback(true);
		}
	}

	useEffect(() => {
		validate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collection.contents])

	return (
		<>
			<Row style={{ paddingBottom: '3px' }}>
				<Col style={{ marginLeft: 'auto' }}>
					<Button type='link' onClick={() => setShowCreateModal(true)} icon={<PlusOutlined />}></Button>
				</Col>
			</Row>

			<CollectionContentsTableComponent
				data={collection.contents}
				loading={loading}
				onDelete={onDelete}
				onEdit={onEdit}
			/>

			<ConfirmationModal
				callback={async () => {
					setLoading(true);
					setContents([...collection.contents!.filter(x => x.id !== contentToModify!.id)])
					setLoading(false);
				}}
				message={`Please confirm deletion`}
				visible={showDeleteModal}
				setVisible={setShowDeleteModal}
			/>
			<CustomFormModal
				setVisible={setShowCreateModal}
				title={'Add contents'}
				visible={showCreateModal}
			>
				<CollectionContentForm
					editMode={false}
					closeModal={(result) => {
						setContents([...collection.contents ?? [], result]);
						setShowCreateModal(false);
					}}
				/>
			</CustomFormModal>
			<CustomFormModal
				setVisible={setShowEditModal}
				title={'Update contents'}
				visible={showEditModal}
			>
				<CollectionContentForm
					initialValue={contentToModify}
					editMode={true}
					closeModal={(result) => {
						setContents([...collection.contents!.filter(x => x.id !== contentToModify!.id), result]);
						setShowEditModal(false);
					}}
				/>
			</CustomFormModal>
		</>
	)
}

export default CollectionContentsComponent
