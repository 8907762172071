import React, { Fragment, useContext, useEffect, useState } from 'react'
import {
	Form,
	Input,
	Button,
	Skeleton
} from 'antd';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { ICompany } from '../../../app/models/company';

interface IProps {
	closeModal: () => void;
	id?: string;
}

const CompanyForm: React.FC<IProps> = ({ closeModal, id }) => {
	const rootStore = useContext(RootStoreContext);
	const { register, edit, get } = rootStore.companyStore;
	const [form] = Form.useForm()

	const [loading, setLoading] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [initialValues, setInitialValues] = useState<ICompany>();

	const onFinish = async (values: any) => {
		setLoading(true);
		if (editMode) {
			edit(values)
				.then(() => closeModal())
				.finally(() => setLoading(false));
		} else {
			register(values)
				.then(() => closeModal())
				.finally(() => setLoading(false));
		}
	}

	const onFinishFailed = (errorInfo: any) => {
		setLoading(false);
	};

	useEffect(() => {
		if (id) {
			setEditMode(true);
			get(id)
				.then(company => {
					setInitialValues(company)
				});
		}
	}, [id, get, setInitialValues])

	return (
		<Fragment>
			<Skeleton loading={editMode && !initialValues}>
				<Form
					form={form}
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 18 }}
					layout='horizontal'
					initialValues={initialValues}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
				>
					<Form.Item label='Company Name' name='companyName'
						rules={[{ required: true, message: 'Please input a company name!' }]}
					>
						<Input autoComplete='off' />
					</Form.Item>
					<Form.Item style={{ float: 'right' }}>
						<Button loading={loading} type="primary" htmlType="submit">
							{editMode ? 'Save Changes' : 'Register Company'}
						</Button>
					</Form.Item>
					<Form.Item hidden={true} name={'id'}>
						<Input autoComplete='off' />
					</Form.Item>
				</Form>
			</Skeleton>
		</Fragment>
	)
}

export default CompanyForm
