import { makeObservable, action } from "mobx";
import agent from "../api/agent";
import { IServerSuccessResponse } from "../models/ServerSuccessResponse";
import { ISupplier } from "../models/Supplier";
import { RootStore } from "./rootStore";
import { IQueryableResponse } from '../models/queryableResponse'
import { IRequestData } from "../models/requestData";
import { CollectionStatus } from "../models/Collection";

export default class SupplierStore {
	rootStore: RootStore;
	
	constructor(rootStore: RootStore) {
		makeObservable(this);
		this.rootStore = rootStore;
	}

	@action register = async (values: ISupplier): Promise<IServerSuccessResponse> => {
		try {
			return await agent.Supplier.create(values);
		} catch (error) {
			throw error;
		}
	}

	@action edit = async (values: ISupplier): Promise<void> => {
		try {
			return await agent.Supplier.edit(values);
		} catch (error) {
			throw error;
		}
	}

	@action getSuppliers = async (request: IRequestData = {}): Promise<IQueryableResponse<ISupplier>> => {
		try {
			return await agent.Supplier.list(request);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}

	@action get = async (id: string): Promise<ISupplier> => {
		try {
			return await agent.Supplier.get(id);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}

	@action deleteSupplier = async (id: string): Promise<void> => {
		try {
			await agent.Supplier.delete(id);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}

	@action acknowledge = async (token: string, status: CollectionStatus): Promise<void> => {
		try {
			return await agent.Supplier.acknowledge(token, status);
		} catch (error) {
			throw error;
		}
	}
}