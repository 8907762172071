import React, { useContext } from 'react';
import {
	HighlightOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Switch } from 'antd';
import { Link } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import Logo from './Logo';

interface IProps { }

const UserNavBar: React.FC<IProps> = (props) => {
	const rootStore = useContext(RootStoreContext)

	const { setLightMode, lightMode } = rootStore.commonStore;
	const { Sider } = Layout;
	const changeTheme = (value: boolean) => {
		setLightMode(value);
	}

	return (
		<Sider width="300px" breakpoint="lg" collapsedWidth="0">
			<Logo />
			<Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
				<Menu.Item key="2" icon={<UserOutlined />}>
					<Link to='/addresses/'>Addresses</Link>
				</Menu.Item>
				<Menu.Item key="4">
					<Link to='/collections/new'>Create a new collection</Link>
				</Menu.Item>
				<Menu.Item key="3">
					<Link to='/collections'>My Collections</Link>
				</Menu.Item>
				<Menu.Item key="10">
					<Link to={'/admin/quote/'}>Get Quote</Link>
				</Menu.Item>
				<Menu.Item key="100" icon={<HighlightOutlined />}>
					Light Mode <Switch checked={lightMode} onChange={changeTheme} />
				</Menu.Item>
			</Menu>
		</Sider>
	)
}

export default observer(UserNavBar)
