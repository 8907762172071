import React, { Fragment, useContext, useEffect, useState } from 'react'
import {
	Form,
	Input,
	Button,
	Skeleton
} from 'antd';
import { RootStoreContext } from '../../app/stores/rootStore';
import { ISupplier } from '../../app/models/Supplier';
import SupplierConfigurationComponent from './supplierComponents/configuration/SupplierConfigurationComponent';
import SupplierEmailComponent from './supplierComponents/email/SupplierEmailComponent';
import SupplierServiceComponent from './supplierComponents/service/SupplierServiceComponent';


interface IProps {
	closeModal: () => void;
	id?: string;
}

const SupplierForm: React.FC<IProps> = ({ closeModal, id }) => {
	const rootStore = useContext(RootStoreContext);
	const { register, edit, get } = rootStore.supplierStore;
	const [form] = Form.useForm()

	const [loading, setLoading] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [supplier, setSupplier] = useState<Partial<ISupplier>>({});
	const [isValid, setIsValid] = useState<boolean>(false);

	const onFinish = async () => {
		setLoading(true);
		if (isValid)
			if (editMode) {
				edit(supplier as ISupplier)
					.then(() => closeModal())
					.finally(() => setLoading(false));
			} else {
				register(supplier as ISupplier)
					.then(() => closeModal())
					.finally(() => setLoading(false));
			}
	}

	const onFinishFailed = (errorInfo: any) => {
		setLoading(false);
	};

	useEffect(() => {
		if (id) {
			setEditMode(true);
			get(id)
				.then(supplier => {
					setSupplier(supplier)
				});
		}
	}, [get, id])

	return (
		<Fragment>
			<Skeleton loading={editMode && !supplier.id}>
				<Form
					form={form}
					layout='vertical'
					initialValues={supplier}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
				>
					<Form.Item label='Supplier Name' name='name'
						rules={[{ required: true, message: 'Please input a supplier name!' }]}
					>
						<Input autoComplete='off' onChange={(value) => setSupplier({ ...supplier, name: value.currentTarget.value })} />
					</Form.Item>
					<Form.Item label='Emails' required>
						<SupplierEmailComponent setSupplier={setSupplier} supplier={supplier} validateCallback={() => setIsValid(true)} />
					</Form.Item>
					<Form.Item label='Configurations'>
						<SupplierConfigurationComponent setSupplier={setSupplier} supplier={supplier} />
					</Form.Item>
					<Form.Item label='Services'>
						<SupplierServiceComponent setSupplier={setSupplier} supplier={supplier} />
					</Form.Item>
					<Form.Item style={{ float: 'right' }}>
						<Button loading={loading} type="primary" htmlType="submit">
							{editMode ? 'Save Changes' : 'Register Supplier'}
						</Button>
					</Form.Item>
					<Form.Item hidden={true} name={'id'}>
						<Input autoComplete='off' />
					</Form.Item>
				</Form>
			</Skeleton>
		</Fragment>
	)
}

export default SupplierForm
