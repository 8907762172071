import { Button, Col, Descriptions, Row, Typography } from 'antd'
import { contentType } from 'mime-types';
import { title } from 'process';
import React, { useContext, useState } from 'react'
import ShareInputForm from '../../../app/components/forms/ShareInputForm';
import CustomFormModal from '../../../app/components/modals/CustomFormModal';
import { IMediaItemFile } from '../../../app/models/MediaItem';
import { RootStoreContext } from '../../../app/stores/rootStore';

interface IProps {
	file: IMediaItemFile;
}

const DownloadViewSelection: React.FC<IProps> = ({ file }) => {
	const [modalVisible, setModalVisible] = useState(false);

	const rootStoreContext = useContext(RootStoreContext);
	const { emailAttachment } = rootStoreContext.mediaItemStore

	const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
		const byteCharacters = atob(b64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}

	const download = () => {
		var FileSaver = require('file-saver');
		const blob = b64toBlob(file.file, `${contentType(file.extension)}`)
		FileSaver.saveAs(blob, file.fileName);
	}

	const view = () => {
		const blob = b64toBlob(file.file, `${contentType(file.extension)}`)
		const url = URL.createObjectURL(blob);
		window.open(url, '_blank');
		URL.revokeObjectURL(url);
	}

	const email = (emailAddress: string) => {
		const blob = b64toBlob(file.file, `${contentType(file.extension)}`)
		const fileType = contentType(file.extension);
		console.log({
			attachment: blob,
			attachmentName: file.fileName,
			attachmentType: fileType || '',
			email: emailAddress
		})
		emailAttachment({
			attachment: blob,
			attachmentName: file.fileName,
			attachmentType: fileType || '',
			email: emailAddress
		});
	}

	return (
		<>
			<Descriptions title={'File Information'} column={1}>
				<Descriptions.Item label="File Name">{file.fileName}</Descriptions.Item>
				<Descriptions.Item label="File Type">{file.extension}</Descriptions.Item>
			</Descriptions>
			<Typography.Text>Would you like to download, view or email this file?</Typography.Text>
			<Row>
				<Col xs={24} md={24} lg={8}>
					<Button
						type='default'
						style={{ width: '95%', height: '200px' }}
						onClick={download}
					>
						Download
					</Button>
				</Col>
				<Col xs={24} md={24} lg={8}>
					<Button
						type='primary'
						style={{ width: '95%', height: '200px' }}
						onClick={view}
					>
						View Online
					</Button>
				</Col>
				<Col xs={24} md={24} lg={8}>
					<Button
						type='primary'
						style={{ width: '95%', height: '200px' }}
						onClick={() => setModalVisible(true)}
					>
						Email
					</Button>
				</Col>
			</Row>

			<CustomFormModal setVisible={setModalVisible} title={'Send to ' + title} visible={modalVisible}>
				<ShareInputForm
					title={'email'}
					closeModal={value => {
						email(value);
						setModalVisible(false);
					}}
				/>
			</CustomFormModal>
		</>
	)
}

export default DownloadViewSelection