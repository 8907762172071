import React, { Fragment, useContext, useState } from 'react'
import {
	Form,
	Input,
	Button,
} from 'antd';
import FeatureHeading from '../../../app/components/headings/FeatureHeading';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import CustomForm from '../../../app/components/forms/CustomForm';
import Role from '../../../app/models/role';
import { history } from '../../..';
import CustomFormModal from '../../../app/components/modals/CustomFormModal';
import ShareInputForm from '../../../app/components/forms/ShareInputForm';
import { toast } from 'react-toastify';

const LoginForm = () => {
	const [loading, setLoading] = useState(false);
	const [showForgotPassword, setShowForgotPassword] = useState(false);

	const context = useContext(RootStoreContext)
	let { login, resendVerification } = context.userStore

	const onFinish = async (values: any) => {
		setLoading(true);
		login(values)
			.then(user => {
				navigate(user.role);
			})
			.catch(() => {
				setLoading(false);
			});
	}

	const navigate = (role: Role) => {
		const params = new URLSearchParams(window.location.search);
		const returnUrl: string | null = params.get('redirect');
		if (returnUrl) {
			history.push(decodeURI(returnUrl));
		}
		else {
			if (role === Role.user) {
				history.push('/collections');
			} else if (role === Role.admin) {
				history.push('/admin/collections');
			} else if (role === Role.owner) {
				history.push('/admin/dashboard')
			}
		}
	}

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	const showForgotPasswordModal = () => {
		setShowForgotPassword(true);
	}

	const sendForgotPasswordEmail = (email: string) => {
		resendVerification(email)
			.then(() => {
				toast.success("An email has been sent to reset your password");
				setShowForgotPassword(false);
			});
	}

	return (
		<Fragment>
			<FeatureHeading value='Login' />
			<CustomForm>
				<Form
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 18 }}
					layout='horizontal'
					initialValues={{ switch: true, input: 'hello' }}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
				>
					<Form.Item label='Email' name='email' rules={[{ required: true, message: 'Please enter an email!' }]}>
						<Input autoComplete='off' />
					</Form.Item>
					<Form.Item label='Password' name='password' rules={[{ required: true, message: 'Please enter a password!' }]}>
						<Input.Password autoComplete='off' type='password' />
					</Form.Item>
					<Form.Item style={{ float: 'right' }}>
						<Button loading={loading} type="primary" htmlType="submit">
							Login
						</Button>
					</Form.Item>
					<Button style={{ float: 'right', marginRight: '8px' }} onClick={showForgotPasswordModal}>Forgot password</Button>
				</Form>
			</CustomForm>

			<CustomFormModal setVisible={setShowForgotPassword} visible={showForgotPassword} title='Email'>
				<ShareInputForm closeModal={sendForgotPasswordEmail} title='email' />
			</CustomFormModal>
		</Fragment>
	)
}

export default observer(LoginForm)
