import { IRate } from "../../app/models/Rate";

export const calculateRate = (rate: IRate, mass: number): number => {
	let result = 0;
	if (mass > rate.rateApplyAfter) {
		const massRemaining = mass - rate.rateApplyAfter;
		result = result + rate.minimum + rate.surcharge;
		result = result + (rate.value * massRemaining);
		result = result + (result * (rate.fuel / 100));
	} else {
		result = result + rate.minimum + rate.surcharge;
		result = result + (result * (rate.fuel / 100));
	}
	return result;
}