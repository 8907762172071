import React from 'react'
import { Column } from '@ant-design/charts';
import { IDashboard } from '../../../models/Dashboard';

interface IProps {
	data: IDashboard['profitByClient'];
}

const ColumnChart: React.FC<IProps> = ({ data: serverData }) => {
	const data: any[] = [];
	Object.keys(serverData).forEach(key => {
		data.push({ client: key, profit: serverData[key] });
	})

	const config = {
		data,
		loading: data.length === 0,
		xField: 'client',
		yField: 'profit',
		columnWidthRatio: 0.8,
		xAxis: {
			label: {
				autoHide: true,
				autoRotate: false,
			},
		},
		meta: {
			client: {
				alias: 'Client',
			},
			profit: {
				alias: 'Profit',
			},
		},
		tooltip: {
			formatter: (data: any) => {
				return {
					name: "Profit",
					value: data.profit.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' }),
				};
			},
		}
	};
	return <Column {...config} data={data} />;
}

export default ColumnChart