import { Button, Card, Col, Descriptions, Row, Skeleton, Tabs } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { CollectionStatus, ICollection } from '../../app/models/Collection'
import { RootStoreContext } from '../../app/stores/rootStore'
import CollectionAddressDescriptionsComponent from '../collection/collectionComponents/CollectionAddressDescriptionsComponent'
import CollectionAttachmentsListComponent from '../collection/collectionComponents/CollectionAttachmentsListComponent'
import CollectionContentsTableComponent from '../collection/collectionComponents/CollectionContentsTableComponent'
import CollectionWaybillBarcodeComponent from '../collection/collectionComponents/CollectionWaybillBarcodeComponent'

const Acknowledge = () => {
	const rootContext = useContext(RootStoreContext);
	const { getSharedAcknowledgement } = rootContext.collectionStore;
	const { acknowledge } = rootContext.supplierStore;
	const { TabPane } = Tabs;

	const [collection, setCollection] = useState<ICollection>();
	const [token, setToken] = useState<string>('');
	const [loading, setLoading] = useState<{button: 'accept' | 'decline', loading: boolean}>({button: 'accept', loading: false});
	const [actioned, setActioned] = useState(false);

	useEffect(() => {
		const token: string = new URLSearchParams(window.location.search).get('token') || '';

		if (token) {
			setToken(token);
			getSharedAcknowledgement(token)
				.then(collection => {
					setCollection(collection);
					setActioned(collection.status !== CollectionStatus.Pending);
				});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const accept = () => {
		setLoading({button: 'accept', loading: true});
		acknowledge(token, CollectionStatus.Accepted)
			.then(() => setActioned(true))
			.finally(() => setLoading({...loading, loading: false}));
	}

	const decline = () => {
		setLoading({button: 'decline', loading: true});
		acknowledge(token, CollectionStatus.Declined)
			.then(() => setActioned(true))
			.finally(() => setLoading({...loading, loading: false}));
	}

	return (
		<div style={{ height: '100%' }}>
			<Card style={{ maxHeight: '95%', height: '95%', overflowY: 'auto', marginBottom: '4px' }}>
				<Tabs defaultActiveKey='1'>
					<TabPane tab='Waybill' key='1'>
						<Skeleton loading={!collection}>
							{collection?.waybill && <CollectionWaybillBarcodeComponent waybill={collection?.waybill} />}
							<Descriptions title={'Waybill Details'} column={1}>
								<Descriptions.Item label="Service">{collection?.serviceType.displayName}</Descriptions.Item>
								<Descriptions.Item label="Ready Time">{collection?.readyTime.toString()}</Descriptions.Item>
								<Descriptions.Item label="Closing Time">{collection?.closingTime?.toString()}</Descriptions.Item>
								<Descriptions.Item label="Optional Insurance">{collection?.insurance ? 'Yes' : 'No'}</Descriptions.Item>
								<Descriptions.Item label="Comment">{collection?.comment}</Descriptions.Item>
							</Descriptions>
						</Skeleton>
					</TabPane>
					<TabPane tab='Sender & Receiver' key='2'>
						<Row>
							<Col lg={12} sm={24}>
								<CollectionAddressDescriptionsComponent title='sender' address={collection?.sender} />
							</Col>
							<Col lg={12} sm={24}>
								<CollectionAddressDescriptionsComponent title='receiver' address={collection?.receiver} />
							</Col>
						</Row>
					</TabPane>
					<TabPane tab='Contents' key='3'>
						<CollectionContentsTableComponent data={collection?.contents} loading={false} />
					</TabPane>
					<TabPane tab='Attachments' key='4'>
						<CollectionAttachmentsListComponent attachments={collection?.attachments} />
					</TabPane>
				</Tabs>
			</Card>

			<Button loading={loading.loading && loading.button === 'accept'} disabled={(loading.loading && loading.button === 'decline') || actioned} type='primary' style={{width: '50%'}} onClick={() => accept()}>Accept</Button>
			<Button loading={loading.loading && loading.button === 'decline'} disabled={(loading.loading && loading.button === 'accept') || actioned} danger style={{ width: '50%'}} onClick={() => decline()}>Decline</Button>
		</div>
	)
}

export default Acknowledge
