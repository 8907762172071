import { Form, Input, Button, Skeleton, Row, Col } from 'antd'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { IAddress } from '../../app/models/Address';
import { ICompany } from '../../app/models/company';
import { RootStoreContext } from '../../app/stores/rootStore';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { AimOutlined } from '@ant-design/icons/lib/icons/';

interface IProps {
	closeModal: (result: IAddress) => void;
	id?: string;
	company: ICompany;
}

const AddressForm: React.FC<IProps> = ({ closeModal, id, company }) => {
	const rootStore = useContext(RootStoreContext);
	const { edit, register, get } = rootStore.addressStore;

	const [editMode, setEditMode] = useState(false);
	const [loading, setLoading] = useState(false);
	const [initialValues, setInitialValues] = useState<Partial<IAddress>>();

	const onFinish = async (values: any) => {
		setLoading(true);
		if (editMode) {
			edit(values)
				.then((serverResponse) => {
					values.id = serverResponse.id;
					closeModal(values)
				})
				.finally(() => setLoading(false));
		} else {
			register(values)
				.then(serverResponse => {
					values.id = serverResponse.id;
					closeModal(values)
				})
				.finally(() => setLoading(false));
		}
	}

	const onFinishFailed = (errorInfo: any) => {
		setLoading(false);
	};

	const googleMapsAddressChanged = (value: any) => {
		geocodeByPlaceId(value.value.place_id)
			.then((resultArray: google.maps.GeocoderResult[]) => {
				const result = resultArray[0];

				// initial values
				let address: Partial<IAddress> = {};
				setInitialValues(undefined);
				address.name = initialValues?.name;
				address.addressLine2 = initialValues?.addressLine2;
				address.addressLine3 = initialValues?.addressLine3;
				address.addressLine4 = initialValues?.addressLine4;
				address.contactPerson = initialValues?.contactPerson;
				address.contactCell = initialValues?.contactCell;
				address.contactPhone = initialValues?.contactPhone;
				address.contactEmail = initialValues?.contactEmail;
				address.companyId = initialValues?.companyId;
				address.company = initialValues?.company;
				address.id = initialValues?.id;

				// google result
				let street_number: string = (result.address_components.find(x => x.types.includes('street_number'))?.long_name ?? '') + ' ';
				let street: string = result.address_components.find(x => x.types.includes('route'))?.long_name ?? '';
				let city: string = result.address_components.find(x => x.types.includes('locality'))?.long_name ?? '';
				let country: string = result.address_components.find(x => x.types.includes('country'))?.long_name ?? '';
				let postalCode: string = result.address_components.find(x => x.types.includes('postal_code'))?.long_name ?? '';
				let businessName: string = ''
				if (!result.types.includes('street_address')) {
					businessName = value.value?.structured_formatting?.main_text ?? '';
				};

				// setting new initial values
				address.addressLine1 = street_number + street
				address.addressLine2 = businessName;
				address.city = city;
				address.country = country;
				address.postalCode = postalCode;
				setInitialValues(address)
			});
	};

	useEffect(() => {
		if (id) {
			setEditMode(true);
			get(id)
				.then(result => {
					setInitialValues(result);
				});
		}
		else {
			setInitialValues({ companyId: company.id });
		}
	}, [id, get, setInitialValues, company.id])

	return (
		<Fragment>
			<Skeleton loading={!initialValues}>
				<Form
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 18 }}
					layout='horizontal'
					initialValues={initialValues}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
				>
					<Form.Item wrapperCol={{ span: 24 }}>
						<Row align={'middle'}>
							<Col span={2}>
								<AimOutlined style={{ fontSize: '20px' }} />
							</Col>
							<Col span={22}>
								<GooglePlacesAutocomplete
									apiKey={process.env.REACT_APP_GOOGLE_KEY ?? 'default'}
									selectProps={{
										onChange: googleMapsAddressChanged
									}}
									minLengthAutocomplete={12}
								/>
							</Col>
						</Row>
					</Form.Item>
					<Form.Item
						name='companyId'
						hidden={true}
					>
						<Input autoComplete='off' value={company.id} />
					</Form.Item>
					<Form.Item label='Address Name' name='name'
						rules={[{ required: true, message: 'Please input a name for the address!' }]}
					>
						<Input autoComplete='off' />
					</Form.Item>
					<Form.Item label='Address Line 1' name='addressLine1'
						rules={[{ required: true, message: 'Please input an address!' }]}
					>
						<Input autoComplete='off' maxLength={30} />
					</Form.Item>
					<Form.Item label='Address Line 2' name='addressLine2'>
						<Input autoComplete='off' maxLength={30} />
					</Form.Item>
					<Form.Item label='Address Line 3' name='addressLine3'>
						<Input autoComplete='off' maxLength={30} />
					</Form.Item>
					<Form.Item label='Address Line 4' name='addressLine4'>
						<Input autoComplete='off' maxLength={30} />
					</Form.Item>
					<Form.Item label='City' name='city'
						rules={[{ required: true, message: 'Please input a city!' }]}
					>
						<Input autoComplete='off' maxLength={50} />
					</Form.Item>
					<Form.Item label='Postal Code' name='postalCode'
						rules={[{ required: true, message: 'Please input a postal code!' }]}
					>
						<Input autoComplete='off' maxLength={8} />
					</Form.Item>
					<Form.Item label='Country' name='country'
						rules={[{ required: true, message: 'Please input a country!' }]}
					>
						<Input autoComplete='off' />
					</Form.Item>
					<Form.Item label='Contact Person' name='contactPerson'
						rules={[{ required: true, message: 'Please input a contact person!' }]}
					>
						<Input autoComplete='off' maxLength={30} />
					</Form.Item>
					<Form.Item label='Contact Cell' name='contactCell'
						rules={[{ required: true, message: 'Please input a contact cell!' }]}
					>
						<Input autoComplete='off' maxLength={20} />
					</Form.Item>
					<Form.Item label='Contact Phone' name='contactPhone'>
						<Input autoComplete='off' maxLength={20} />
					</Form.Item>
					<Form.Item label='Contact Email' name='contactEmail'>
						<Input autoComplete='off' maxLength={50} />
					</Form.Item>
					<Form.Item style={{ float: 'right' }}>
						<Button loading={loading} type="primary" htmlType="submit">
							{editMode ? 'Save Changes' : 'Save Address'}
						</Button>
					</Form.Item>
					<Form.Item hidden={true} name={'id'}>
						<Input autoComplete='off' />
					</Form.Item>
				</Form>
			</Skeleton>
		</Fragment>
	)
}

export default AddressForm
