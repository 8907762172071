import React, { Fragment, useContext, useState } from 'react'
import {
	Form,
	Input,
	Button
} from 'antd';
import FeatureHeading from '../../../app/components/headings/FeatureHeading';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { history } from '../../..';
import CustomForm from '../../../app/components/forms/CustomForm';

const CreatePasswordForm = () => {
	const context = useContext(RootStoreContext)

	const [loading, setLoading] = useState(false);
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const token: string = params.get('token') || '';

	const onFinish = async (values: any) => {
		setLoading(true);
		context.userStore.updatePassword({ password: values.password, token: token })
			.then(() => {
				setLoading(false);
				history.push('/account/login');
			})
			.finally(() => {
				setLoading(false);
			});
	}

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};



	return (
		<Fragment>
			<FeatureHeading value='Update Password' />
			<CustomForm>
				<Form
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 18 }}
					layout='horizontal'
					initialValues={{ switch: true, input: 'hello' }}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
				>
					<Form.Item
						name="password"
						label="Password"
						rules={[
							{
								required: true,
								message: 'Please input your password!',
							},
						]}
						hasFeedback
					>
						<Input.Password autoComplete='off' />
					</Form.Item>

					<Form.Item
						name="confirm"
						label="Confirm Password"
						dependencies={['password']}
						hasFeedback
						rules={[
							{
								required: true,
								message: 'Please confirm your password!',
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve();
									}
									return Promise.reject(new Error('The two passwords that you entered do not match!'));
								},
							}),
						]}
					>
						<Input.Password autoComplete='off' />
					</Form.Item>
					<Form.Item wrapperCol={{ offset: 4 }}>
						<Button loading={loading} type="primary" htmlType="submit">
							Update Password
						</Button>
					</Form.Item>
				</Form>
			</CustomForm>
		</Fragment>
	)
}

export default observer(CreatePasswordForm)
