import React, { useContext } from 'react'
import { RootStoreContext } from '../../../app/stores/rootStore';

const ThemeLight = React.lazy(() => import('./ThemeLight'));
const ThemeDark = React.lazy(() => import('./ThemeDark'));

const ThemeSelector: React.FC = ({ children }) => {
	const rootStore = useContext(RootStoreContext);
	const { lightMode } = rootStore.commonStore;

	return (
		<>
			{/* Conditionally render theme, based on the current client context */}
			<React.Suspense fallback={null}>
				{lightMode && <ThemeLight />}
				{(!lightMode) && <ThemeDark />}
			</React.Suspense>
			{/* Render children immediately! */}
			{children}
		</>
	)
};

export default ThemeSelector
