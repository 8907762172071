import { Modal } from 'antd'
import React from 'react'

interface IProps {
	message: string;
	callback: () => void;
	visible: boolean;
	setVisible: any;
}

const ConfirmationModal: React.FC<IProps> = ({ visible, callback, message, setVisible }) => {

	const onOk = async () => {
		callback();
		setVisible(false);
	}

	return (
		<>
			<Modal
				title="Confirmation"
				visible={visible}
				onOk={() => onOk()}
				onCancel={() => setVisible(false)}
				okText="Confirm"
				cancelText="Cancel"
				afterClose={() => setVisible(false)}
			>
				<p>{message}</p>
			</Modal>
		</>
	)
}

export default ConfirmationModal
