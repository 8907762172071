import { Row } from 'antd'
import React from 'react'

interface IProps {
	value: string;
	style?: React.CSSProperties;
}

const FeatureHeading: React.FC<IProps> = (props) => {
	return (
		<Row>
			<h1 className="feature-page-heading" style={props.style}>{props.value}</h1>
		</Row>
	)
}

export default FeatureHeading
