import React, { useContext } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import Role from '../../app/models/role';
import AdminNavBar from './AdminNavBar';
import HomeNavBar from './HomeNavBar';
import { observer } from 'mobx-react-lite';
import UserNavBar from './UserNavBar';

interface IProps {}

const DefaultNavBar: React.FC<IProps> = (props) => {
	const context = useContext(RootStoreContext);
	var { user } = context.userStore;

	if (user && user.role !== Role.user) {
		return <AdminNavBar />;
	} else if (user && user.role === Role.user) {
		return <UserNavBar />;
	}

	return <HomeNavBar />;
};

export default observer(DefaultNavBar);
