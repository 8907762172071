import { Button, Form, Input, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import CustomForm from '../../app/components/forms/CustomForm'
import FeatureHeading from '../../app/components/headings/FeatureHeading'
import { ICompany } from '../../app/models/company';
import { IFuelRateMassUpdateRequest } from '../../app/models/Rate';
import { RootStoreContext } from '../../app/stores/rootStore';

const UpdateFuel: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { getCompanies } = rootStore.companyStore;
	const { editFuel } = rootStore.rateStore;

	const [loading, setLoading] = useState(false);
	const [companies, setCompanies] = useState<ICompany[]>();

	useEffect(() => {
		setLoading(true);
		getCompanies({ limit: -1, orderByColumn: 'CompanyName' })
			.then(companies => {
				setCompanies(companies.data);
			})
			.finally(() => setLoading(false));
		// eslint-disable-next-line
	}, [])

	const onFinish = (values: IFuelRateMassUpdateRequest) => {
		setLoading(true);
		editFuel(values)
			.then(() => setLoading(false))
			.catch(() => setLoading(false));
	}

	const onFinishFailed = () => {

	}

	return (
		<>
			<FeatureHeading value='Mass Update Fuel Rates' />
			<CustomForm loading={loading}>
				<Form
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 18 }}
					layout='horizontal'
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
				>
					<Form.Item
						name='companies'
						label='Companies'
						rules={[{ required: true, message: 'Please select at least one company!' }]}
					>
						<Select
							mode="multiple"
							allowClear
							showSearch
							filterOption={(input, option) =>
								option?.props.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							style={{ width: '100%' }}
							placeholder="Please select"
						>
							{companies?.map(company => {
								return (<Select.Option key={company.id} value={company.id} title={company.companyName}>{company.companyName}</Select.Option>)
							})}
						</Select>
					</Form.Item>
					<Form.Item
						label='Fuel (%)'
						name='fuel'
						rules={[{ required: true, message: 'Please input a rate!' }]}
					>
						<Input autoComplete='off' type={'number'} maxLength={30} />
					</Form.Item>
					<Form.Item style={{ float: 'right' }}>
						<Button loading={loading} type="primary" htmlType="submit">
							Update Fuel Rates
						</Button>
					</Form.Item>
				</Form>
			</CustomForm>
		</>
	)
}

export default UpdateFuel