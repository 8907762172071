import { Skeleton, Card, Row, Col, Typography } from 'antd';
import { EditOutlined, EyeOutlined, DeleteOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import React, { useContext, useState } from 'react'
import { CollectionStatus, IAdminCollection, ICollection } from '../../../app/models/Collection';
import { RootStoreContext } from '../../../app/stores/rootStore';
import Role from '../../../app/models/role';
import { getCollectionShortCardStyling } from '../../../app/helpers/CollectionHelper';
import moment from 'moment';
import ConfirmationModal from '../../../app/components/modals/ConfirmationModal';
import CustomFormModal from '../../../app/components/modals/CustomFormModal';
import ViewCollectionComponent from './ViewCollectionComponent';
import CollectionAdminUpdateForm from '../CollectionAdminUpdateForm';
import { history } from '../../..';
import SupplierSelection from '../../supplier/SupplierSelection';

interface IProps {
	collection: ICollection;
	adminCollection?: IAdminCollection;
	reloadCallback: () => void;
}

const CollectionShortCard: React.FC<IProps> = ({ collection, adminCollection, reloadCallback }) => {
	const rootContext = useContext(RootStoreContext);
	const { user } = rootContext.userStore;
	const { deleteCollection } = rootContext.collectionStore;

	const [loading, setLoading] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showViewModal, setShowViewModal] = useState(false);
	const [showAcceptModal, setShowAcceptModal] = useState(false);

	const { Meta } = Card;

	const getActions = (): React.ReactNode[] | undefined => {
		if (user?.role === Role.user) {
			if (collection.status === CollectionStatus.Draft || collection.status === CollectionStatus.Requested) {
				return [
					<EyeOutlined key='view' onClick={() => setShowViewModal(true)} />,
					<EditOutlined key='edit' onClick={() => history.push(`/collections/update/${collection.id}`)} />,
					<DeleteOutlined key='delete' onClick={() => setShowDeleteModal(true)} />
				];
			} else {
				return [
					<EyeOutlined key='view' onClick={() => setShowViewModal(true)} />
				]
			}
		} else {
			if (collection.status === CollectionStatus.Requested) {
				return [
					<CheckOutlined style={{ color: 'limegreen' }} key='accept' onClick={() => setShowAcceptModal(true)} />,
					<CloseOutlined style={{ color: 'red' }} key='decline' />,
					<EyeOutlined key='view' onClick={() => setShowViewModal(true)} />,
					<EditOutlined key='edit' onClick={() => setShowEditModal(true)} />,
					<DeleteOutlined key='delete' onClick={() => setShowDeleteModal(true)} />
				];
			} else {
				return [
					<EyeOutlined key='view' onClick={() => setShowViewModal(true)} />,
					<EditOutlined key='edit' onClick={() => setShowEditModal(true)} />,
					<DeleteOutlined key='delete' onClick={() => setShowDeleteModal(true)} />
				]
			}
		}
	}

	return (
		<>
			<Card
				style={getCollectionShortCardStyling(collection.status)}
				actions={getActions()}
			>
				<Skeleton loading={loading} avatar active>
					<Row>
						<Col md={6} sm={24}>
							<Meta
								title={collection.waybill}
								description={(<p style={{ color: 'orange', padding: 0, margin: 0, fontSize: 'medium' }}>{CollectionStatus[collection.status]}</p>)}
								style={{ marginBottom: '4px' }}
							/>
							<Typography.Text>{moment(collection.readyDate).format('dddd, D MMM yyyy')}</Typography.Text> <br />
							<Typography.Text>{collection.readyTime}</Typography.Text> - <Typography.Text>{collection.closingTime}</Typography.Text> <br />
							<Typography.Text>{`${collection.serviceType.displayName} (${collection.serviceType.code})`}</Typography.Text>
						</Col>
						<Col md={6} sm={24}>
							<Meta
								title={(<div style={{ color: '#3483eb', fontSize: 'large' }} className="ant-card-meta-title">{collection.requester.company.companyName}</div>)}
								description={`Invoiced? ${collection.invoiced ? 'Yes' : 'No'}`}
								style={{ marginBottom: '4px' }}
							/>
							{adminCollection &&
								<>
									<Typography.Text>S Total: {adminCollection.supplierRate.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</Typography.Text> <br />
									<Typography.Text>C Total: {collection.myRate.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</Typography.Text> <br />
									{user?.role === Role.owner && <><Typography.Text>Profit: {(collection.myRate - adminCollection.supplierRate).toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</Typography.Text> <br /></>}
								</>
							}
							{!adminCollection &&
								<>
									<Typography.Text> Rate: {collection.myRate.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</Typography.Text> <br />
									<Typography.Text> VAT : {collection.myVat.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</Typography.Text> <br />
									<Typography.Text>Total: {(collection.myRate + collection.myVat).toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</Typography.Text> <br />
								</>
							}
						</Col>
						<Col md={12} sm={24}>
							<Meta
								title={`${collection.sender.city} - ${collection.receiver.city}`}
								description={(<p style={{ color: '#2ed146', padding: 0, margin: 0, fontSize: 'large' }}>{collection.supplier?.name ?? 'N/A'}</p>)}
								style={{ marginBottom: '4px' }}
							/>
							<Typography.Text>From: {collection.sender.name}</Typography.Text> <br />
							<Typography.Text>To: {collection.receiver.name}</Typography.Text> <br />
						</Col>
					</Row>
				</Skeleton>
			</Card>
			<ConfirmationModal
				callback={async () => {
					setLoading(true);
					deleteCollection(collection.id)
						.then(() => reloadCallback())
						.finally(() => setLoading(false));
				}}
				message={`Please confirm you want to delete ${collection.waybill}`}
				visible={showDeleteModal}
				setVisible={setShowDeleteModal}
			/>
			<CustomFormModal
				setVisible={setShowViewModal}
				title={`View Collection ${collection.waybill}`}
				visible={showViewModal}
				width={1000}
			>
				<ViewCollectionComponent collection={collection} />
			</CustomFormModal>
			<CustomFormModal
				setVisible={setShowEditModal}
				title={`Update Collection ${collection.waybill}`}
				visible={showEditModal}
				width={1000}
			>
				<CollectionAdminUpdateForm
					collectionId={collection.id}
					closeModal={() => {
						setShowEditModal(false);
						reloadCallback()
					}}
				/>
			</CustomFormModal>

			<CustomFormModal
				setVisible={setShowAcceptModal}
				title={`Accept Collection ${collection.waybill}`}
				visible={showAcceptModal}
			>
				<SupplierSelection
					collection={collection}
					closeModal={() => {
						setShowAcceptModal(false);
						reloadCallback()
					}}
				/>
			</CustomFormModal>
		</>
	)
}

export default CollectionShortCard
