import { AxiosRequestConfig, AxiosResponse } from "axios"
import { toast } from "react-toastify";
import { history } from "../..";
import { IRequestData } from "../models/requestData";

const requestInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
	const token = window.localStorage.getItem('jwt');
	if (token) config.headers.Authorization = `Bearer ${token}`;

	config.url = '/api' + config.url;
	return config;
}

const responseSuccessInterceptor = (info: AxiosResponse<any>): AxiosResponse<any> => {
	const { config, status } = info;
	if (config?.url !== '/api/user/me' && status === 200 && (config?.method !== 'GET' && config?.method !== 'get')) {
		toast.success('Action completed!');
	}
	return info;
}

const responseFailInterceptor = (error: any) => {
	if (error.message === 'Network Error' && !error.response) {
		toast.error('Could not establish a connection between the client and API');
		throw error.message;
	}
	const { status, data } = error.response;
	if (status === 500) {
		if (data?.errors?.ErrorKey === 'Request' && data?.errors?.ErrorMessage === 'No Changes Saved') {
			toast.warning(`No changes were made!`);
		} else {
			toast.error('Server error - check the terminal for more info!');
		}
	} else if (status === 401 && (!window.location.pathname.includes('/account/login'))) {
		toast.warning(`Please re-authenticate!`);
		history.push('/account/login?redirect=' + encodeURI(window.location.pathname));
	}
	else {
		if (data?.errors?.ErrorKey) {
			toast.error(`${data.errors.ErrorKey} - ${data.errors.ErrorMessage}`);
		} else {
			toast.error('An unexpected error has occured');
		}
	}
	throw error.response;
}

const requestDataToString = (data: IRequestData): string => {
	let result = '?';
	if (data.keyword) {
		result += `keyword=${data.keyword}&`
	}
	if (data.limit) {
		result += `limit=${data.limit}&`
	}
	if (data.offset) {
		result += `offset=${data.offset}&`
	}
	if (data.orderByColumn) {
		result += `orderByColumn=${data.orderByColumn}&`
	}
	if (data.orderByType) {
		result += `orderByType=${data.orderByType}&`
	}
	return result;
}

const buildQueryString = (data: any): string => {
	let result = '?';
	const keys = Object.keys(data)
	keys.forEach(key => {
		let value = data[key];
		if (typeof value === 'object') {
			value = JSON.stringify(value);
		}
		value = value.toString();
		value = encodeURI(value);
		result = result + `${key}=${value}&`;
	});
	return result;
}

export {
	requestInterceptor,
	responseSuccessInterceptor,
	responseFailInterceptor,
	requestDataToString,
	buildQueryString,
}