import { Button, Row } from 'antd'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { RootStoreContext } from '../../stores/rootStore'

const UserPopOver = () => {
	const context = useContext(RootStoreContext);
	const { logout } = context.userStore;

	return (
		<>
			<Row>
				<Link to='/account/profile'>
					<Button type='link'>Edit my profile</Button>
				</Link>
			</Row>
			<Button type='link' onClick={() => logout()}>Logout</Button>
		</>
	)
}

export default UserPopOver
