import { makeObservable, action } from "mobx";
import agent from "../api/agent";
import { IEmailNotification, IMediaItemFile } from "../models/MediaItem";
import { RootStore } from "./rootStore";

export default class MediaItemStore {
	rootStore: RootStore;
	
	constructor(rootStore: RootStore) {
		makeObservable(this);
		this.rootStore = rootStore;
	}

	@action deleteMediaItem = async (collectionId: string, publicId: string): Promise<void> => {
		try {
			await agent.MediaItem.delete(collectionId, publicId);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}

	@action getMediaItem = async (waybillNumber: number, publicId: string): Promise<IMediaItemFile> => {
		try {
			return await agent.MediaItem.get(waybillNumber, publicId);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}

	@action emailAttachment = async (attachment: IEmailNotification): Promise<void> => {
		try {
			return await agent.MediaItem.email(attachment);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}
}