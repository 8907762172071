import { Button, Card, Col, Row } from 'antd'
import moment from 'moment'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { download } from '../../app/helpers/pdf/FinanceReport'
import { CollectionStatus, IAdminCollection, ICollectionListRequest } from '../../app/models/Collection'
import Role from '../../app/models/role'
import { RootStoreContext } from '../../app/stores/rootStore'
import CollectionListFilterComponent from './collectionComponents/CollectionListFilterComponent'
import CollectionShortCard from './collectionComponents/CollectionShortCard'

const ListCollections = () => {
	const rootContext = useContext(RootStoreContext);
	const { adminList } = rootContext.collectionStore;
	const { user } = rootContext.userStore;
	const params = new URLSearchParams(window.location.search);
	const waybill: string | null = params.get('waybill');
	const from: Date = params.get('from') ? new Date(params.get('from')!) : moment().add(-2, 'days').toDate();
	const to: Date = params.get('to') ? new Date(params.get('to')!) : moment().add(2, 'days').toDate();

	const [collectionRequest, setCollectionRequest] = useState<ICollectionListRequest>({ from: from, to: to, keyword: waybill ?? undefined })
	const [loading, setLoading] = useState(false);
	const [collections, setCollections] = useState<IAdminCollection[]>([])

	const fetchData = async (): Promise<void> => {
		setLoading(true);
		adminList({ ...collectionRequest, limit: -1 })
			.then(result => {
				const newCollections = result.data.filter(x => x.status === CollectionStatus.Requested);
				const otherCollections = result.data.filter(x => x.status !== CollectionStatus.Requested);
				setCollections([...newCollections, ...otherCollections]);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	const exportReport = () => {
		download(collections, collectionRequest.from, collectionRequest.to);
	}

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collectionRequest])

	const rate: number = useMemo(() => {
		return collections.reduce((prev, current) => prev + current.myRate, 0);
	}, [collections]);

	const supplierRate: number = useMemo(() => {
		return collections.reduce((prev, current) => prev + current.supplierRate, 0);
	}, [collections]);

	const table = () => {
		const column: React.CSSProperties = {
			float: 'left',
			width: '33.33%',
		}
		return (
			<div>
				<div style={column}>Rate: {rate.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</div>
				<div style={column}>Supplier: {supplierRate.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</div>
				<div style={column}>Profit: {(rate - supplierRate).toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</div>
			</div>
		)
	}

	return (
		<div className="site-card-wrapper">
			<Row>
				<Col xxl={4} xl={6} md={6} sm={24} xs={24}>
					<Card className='collections-shortcard-admin-filter'>
						<CollectionListFilterComponent
							collectionRequest={collectionRequest}
							setCollectionRequest={setCollectionRequest}
							users={collections.map(x => x.requester)}
							suppliers={collections
								.filter(x => x.supplier)
								.map(x => x.supplier!)
								.filter((value, index, self) => self.map(x => x.name).indexOf(value.name) === index)
								.sort((a, b) => a.name.localeCompare(b.name))}
							loading={loading}
						/>
					</Card>
				</Col>
				<Col xxl={19} xl={17} md={17} sm={24} xs={24} className='collections-shortcard-admin-list'>
					{user?.role === Role.owner &&
						<div className='collections-shortcard-admin-filter' style={{ zIndex: 1, border: '1px solid black', padding: '5px', backgroundColor: 'white' }}>
							{table()}
							<Button hidden={true} style={{ width: '100%' }} type='default' onClick={() => exportReport()}>Download Report</Button>
						</div>
					}
					{collections.map(collection => {
						return (
							<CollectionShortCard collection={collection} adminCollection={collection} reloadCallback={fetchData} />
						);
					})}
				</Col>
			</Row>
		</div>
	)
}

export default ListCollections
