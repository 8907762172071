import { Button, Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import ConfirmationModal from '../../../../app/components/modals/ConfirmationModal'
import CustomFormModal from '../../../../app/components/modals/CustomFormModal'
import { PlusOutlined } from '@ant-design/icons';
import SupplierEmailForm from './SupplierEmailForm'
import SupplierEmailTableComponent from './SupplierEmailTableComponent'
import { ISupplier } from '../../../../app/models/Supplier'

interface IProps {
	supplier: Partial<ISupplier>;
	setSupplier: (collection: Partial<ISupplier>) => void;
	validateCallback?: (value: boolean) => void;
}

const SupplierEmailComponent: React.FC<IProps> = ({ supplier, setSupplier, validateCallback }) => {

	const [loading, setLoading] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [contentToModify, setContentToModify] = useState<string>();

	const onDelete = async (record: string) => {
		setContentToModify(record);
		setShowDeleteModal(true);
	}

	const onEdit = async (record: string) => {
		setContentToModify(record);
		setShowEditModal(true);
	}

	const setEmails = (emails: string[]) => {
		setSupplier({...supplier, emails: emails})
	}

	const validate = () => {
		if (validateCallback) {
			if (!supplier.emails || supplier.emails.length === 0) {
				return validateCallback(false);
			}
			validateCallback(true);
		}
	}

	useEffect(() => {
		validate();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [supplier.emails])

	return (
		<>
			<Row style={{ paddingBottom: '3px' }}>
				<Col style={{ marginLeft: 'auto' }}>
					<Button type='link' onClick={() => setShowCreateModal(true)} icon={<PlusOutlined />}></Button>
				</Col>
			</Row>

			<SupplierEmailTableComponent 
				data={supplier.emails} 
				loading={loading} 
				onDelete={onDelete} 
				onEdit={onEdit} 
			/>

			<ConfirmationModal
				callback={async () => {
					setLoading(true);
					setEmails([...supplier.emails!.filter(x => x !== contentToModify)])
					setLoading(false);
				}}
				message={`Please confirm deletion`}
				visible={showDeleteModal}
				setVisible={setShowDeleteModal}
			/>
			<CustomFormModal
				setVisible={setShowCreateModal}
				title={'Add email'}
				visible={showCreateModal}
			>
				<SupplierEmailForm 
					editMode={false} 
					closeModal={(result) => {
						setEmails([...supplier.emails ?? [], result]);
						setShowCreateModal(false);
					}} 
				/>
			</CustomFormModal>
			<CustomFormModal
				setVisible={setShowEditModal}
				title={'Update contents'}
				visible={showEditModal}
			>
				<SupplierEmailForm 
					initialValue={contentToModify}
					editMode={true} 
					closeModal={(result) => {
						setEmails([...supplier.emails!.filter(x => x !== contentToModify), result]);
						setShowEditModal(false);
					}} 
				/>
			</CustomFormModal>
		</>
	)
}

export default SupplierEmailComponent
