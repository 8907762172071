import React, { useContext, useEffect, useState } from 'react'
import { Table, Input, Row, TablePaginationConfig, Card, Button, Col, Select } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { RootStoreContext } from '../../app/stores/rootStore';
import FeatureHeading from '../../app/components/headings/FeatureHeading';
import ConfirmationModal from '../../app/components/modals/ConfirmationModal';
import CustomFormModal from '../../app/components/modals/CustomFormModal';
import { IRequestData, OrderByType } from '../../app/models/requestData';
import { IAddress } from '../../app/models/Address';
import AddressForm from './AddressForm';
import { ICompany } from '../../app/models/company';
import Role from '../../app/models/role';


const ListAddresses = () => {
	const rootStore = useContext(RootStoreContext);
	const { user } = rootStore.userStore;
	const { getCompanies } = rootStore.companyStore;
	const { getAddresses, deleteAddress } = rootStore.addressStore;
	const { Search } = Input;

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<IAddress[]>([]);
	const [request, setRequest] = useState<IRequestData>({});
	const [total, setTotal] = useState<number>();
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [showRegisterModal, setShowRegisterModal] = useState<boolean>(false);
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [tableDataUpdated, setTableDataUpdated] = useState<boolean>(false);
	const [addressToModify, setAddressToModify] = useState<Partial<IAddress>>()
	const [companies, setCompanies] = useState<ICompany[]>();
	const [selectedCompany, setSelectedCompany] = useState<ICompany>();
	const [loadingCompanies, setLoadingCompanies] = useState(false);

	const fetchData = () => {
		setLoading(true);
		getAddresses(selectedCompany?.id!, request)
			.then((response) => {
				setTotal(response.count)
				if (response.data) {
					setData(response.data);
					setLoading(false);
				}
			})
			.finally(() => setLoading(false));
	}

	const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
		let updatedRequest = request;
		request.limit = pagination?.pageSize;
		request.offset = ((pagination.pageSize || 10) * (pagination.current || 1)) - (pagination.pageSize || 10);
		request.orderByColumn = sorter?.column?.dataIndex;
		request.orderByType = sorter?.order === 'ascend' ? OrderByType.asc : OrderByType.desc;

		setRequest(updatedRequest);
		fetchData();
	};

	const onSearch = (value: string) => {
		let updatedRequest = request;
		request.keyword = value;
		setRequest(updatedRequest);
		fetchData();
	}

	const onDelete = async (record: IAddress) => {
		setShowConfirmation(true);
		setAddressToModify(record);
	}

	const onEdit = async (record: IAddress) => {
		setShowEditModal(true);
		setAddressToModify(record);
	}

	const onCompanyChange = async (value: string) => {
		setSelectedCompany(companies?.find(x => x.id === value)!)
	}

	useEffect(() => {
		if (selectedCompany) {
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCompany]);

	useEffect(() => {
		if (!tableDataUpdated) {
			return;
		}

		if (!showRegisterModal || !showEditModal) {
			fetchData();
		}

		setTableDataUpdated(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showRegisterModal, showEditModal]);

	useEffect(() => {
		if (user?.role === Role.user) {
			setCompanies([user.company]);
			setSelectedCompany(user.company);
			return;
		}
		setLoadingCompanies(true);
		getCompanies({ limit: -1, orderByColumn: 'CompanyName' })
			.then(companies => {
				setCompanies(companies.data);
				setSelectedCompany(user!.company);
			})
			.finally(() => setLoadingCompanies(false));
	}, [getCompanies, user])

	const columns = [
		{
			title: 'Edit',
			dataIndex: 'edit',
			key: 'edit',
			width: '5%',
			render: (text: any, record: any) =>
				<Button
					title={'Edit ' + record.displayName}
					style={{ border: 0 }}
					icon={<EditOutlined />}
					onClick={() => onEdit(record)}
				/>,
		},
		{
			title: 'Delete',
			dataIndex: 'delete',
			key: 'delete',
			width: '7%',
			render: (text: any, record: any) =>
				<Button
					title={'Delete ' + record.companyName}
					style={{ border: 0 }}
					icon={<DeleteOutlined style={{ color: 'red' }} />}
					onClick={() => onDelete(record)}
				/>,
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			width: '15%',
			sorter: true
		},
		{
			title: 'City',
			dataIndex: 'city',
			key: 'city',
			width: '15%',
			sorter: true
		},
		{
			title: 'Address',
			dataIndex: 'addressLine1',
			key: 'addressLine1',
			width: '58%',
			sorter: true
		},
	];

	return (
		<>
			<FeatureHeading value='Addresses' />
			<Card>
				<Row style={{ paddingBottom: '3px' }}>
					<Select
						virtual={false}
						placeholder='select a company'
						loading={loadingCompanies}
						showSearch
						disabled={companies?.length === 1}
						filterOption={(input, option) =>
							option?.props.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						value={selectedCompany?.id}
						onChange={value => onCompanyChange(value)}
						style={{ width: '200px' }}
					>
						{companies?.map(company => {
							return (<Select.Option key={company.id} value={company.id} title={company.companyName}>{company.companyName}</Select.Option>)
						})}
					</Select>
				</Row>
				<Row style={{ paddingBottom: '3px' }}>
					<Col>
						<Search
							placeholder="input search text"
							onSearch={onSearch}
							style={{ width: 200 }}
						/>
					</Col>
					<Col style={{ marginLeft: 'auto' }}>
						<Button type='link' onClick={fetchData} icon={<RedoOutlined />}></Button>
						<Button disabled={!selectedCompany} type='link' onClick={() => setShowRegisterModal(true)} icon={<PlusOutlined />}></Button>
					</Col>
				</Row>
				<Table
					pagination={{ pageSize: request?.limit, showSizeChanger: true, total: total }}
					scroll={{ x: true }}
					loading={loading}
					columns={columns}
					dataSource={data}
					onChange={handleTableChange}
				/>
			</Card>
			<ConfirmationModal
				callback={async () => {
					setLoading(true);
					deleteAddress(addressToModify?.id || '')
						.then(() => fetchData())
						.finally(() => setLoading(false));
				}}
				message={`Please confirm you want to delete ${addressToModify?.name}`}
				visible={showConfirmation}
				setVisible={setShowConfirmation}
			/>
			<CustomFormModal
				setVisible={setShowRegisterModal}
				title={'Register New Address'}
				visible={showRegisterModal}
			>
				<AddressForm
					company={selectedCompany!}
					closeModal={() => {
						setTableDataUpdated(true);
						setShowRegisterModal(false);
					}}
				/>
			</CustomFormModal>
			<CustomFormModal
				setVisible={setShowEditModal}
				title={'Update Address - ' + addressToModify?.name}
				visible={showEditModal}
			>
				<AddressForm
					company={selectedCompany!}
					id={addressToModify?.id || ''}
					closeModal={() => {
						setTableDataUpdated(true);
						setShowEditModal(false);
					}}
				/>
			</CustomFormModal>
		</>
	)
}

export default ListAddresses
