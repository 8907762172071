import { Button, Descriptions, Form, Input, Skeleton } from 'antd';
import React, { useContext, useState } from 'react';
import ShareContentBar from '../../../app/components/bars/ShareContentBar';
import { CollectionStatus, ICollection } from '../../../app/models/Collection';
import CollectionPodComponent from './CollectionPodComponent';
import CollectionWaybillBarcodeComponent from './CollectionWaybillBarcodeComponent';
import { RootStoreContext } from '../../../app/stores/rootStore';

interface IProps {
	collection?: ICollection;
	editableComment?: boolean;
}

const CollectionWaybillDescriptionsComponent: React.FC<IProps> = ({
	collection,
	editableComment
}) => {
	const rootContext = useContext(RootStoreContext);

	const { updateComment } = rootContext.collectionStore;
	const [comment, setComment] = useState(collection?.comment ?? '');

	const getCommentComponent = () => {
		const saveChanges = () => {
			updateComment({ collectionId: collection?.id!, comment: comment });
		};
		if (editableComment) {
			return (
				<div>
					<Form.Item label="Comment">
						<Input
							autoComplete="off"
							defaultValue={collection?.comment}
							onChange={(it) =>
								setComment(it.currentTarget.value)
							}
						/>
						<Button onClick={saveChanges}>Save Comment</Button>
					</Form.Item>
				</div>
			);
		} else {
			return (
				<Descriptions.Item label="Comment">
					{collection?.comment}
				</Descriptions.Item>
			);
		}
	};

	return (
		<>
			<Skeleton loading={!collection}>
				{collection?.waybill && (
					<CollectionWaybillBarcodeComponent
						waybill={collection?.waybill}
					/>
				)}
				{collection?.waybill && collection.supplier && (
					<ShareContentBar collection={collection} />
				)}
				{collection?.waybill && collection.supplier && (
					<CollectionPodComponent collection={collection} />
				)}
				<Descriptions title={'Waybill Details'} column={1}>
					<Descriptions.Item label="Requested By">{`${collection?.requester.name} ${collection?.requester.surname} (${collection?.requester.company?.companyName})`}</Descriptions.Item>
					<Descriptions.Item label="Service">
						{collection?.serviceType.displayName}
					</Descriptions.Item>
					<Descriptions.Item label="Ready Time">
						{collection?.readyTime.toString()}
					</Descriptions.Item>
					<Descriptions.Item label="Closing Time">
						{collection?.closingTime?.toString()}
					</Descriptions.Item>
					<Descriptions.Item label="Optional Insurance">
						{collection?.insurance ? 'Yes' : 'No'}
					</Descriptions.Item>

					{getCommentComponent()}
				</Descriptions>

				<Descriptions title={'Waybill Charge'} column={1}>
					<Descriptions.Item label="Service">
						{collection?.serviceType.displayName}
					</Descriptions.Item>
					<Descriptions.Item label="Total VAT">
						{((collection?.myVat ?? 0) === 0
							? 'To Be Calculated'
							: collection?.myVat!
						).toLocaleString('en-ZA', {
							style: 'currency',
							currency: 'ZAR'
						})}
					</Descriptions.Item>
					<Descriptions.Item label="Total payable (incl VAT)">
						{(collection?.myRate ?? 0) === 0
							? 'To Be Calculated'
							: (
									(collection?.myRate ?? 0) +
									(collection?.myVat ?? 0)
							  ).toLocaleString('en-ZA', {
									style: 'currency',
									currency: 'ZAR'
							  })}
					</Descriptions.Item>
					<Descriptions.Item label="Chargeable mass">
						{(collection?.shipmentMass ?? 0) === 0
							? 'To Be Calculated'
							: collection?.shipmentMass}
					</Descriptions.Item>
					<Descriptions.Item label="Status">
						{CollectionStatus[collection?.status ?? 0]}
					</Descriptions.Item>
				</Descriptions>
			</Skeleton>
		</>
	);
};

export default CollectionWaybillDescriptionsComponent;
