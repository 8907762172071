import { makeObservable, action } from "mobx";
import agent from "../api/agent";
import { IDashboard } from "../models/Dashboard";
import { RootStore } from "./rootStore";

export default class AdhockStore {
	rootStore: RootStore;
	
	constructor(rootStore: RootStore) {
		makeObservable(this);
		this.rootStore = rootStore;
	}

	@action extendTokens = async (): Promise<void> => {
		try {
			await agent.Adhock.extendTokens();
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}

	@action getDashboard = async (): Promise<IDashboard> => {
		try {
			return await agent.Dashboard.get();
		} catch (error) {
			console.debug(error);
			throw error;
		}
	}
}