import React, { useContext, useEffect, useState } from 'react';
import { Tabs, Row, Col } from 'antd';
import CollectionAddressDescriptionsComponent from './CollectionAddressDescriptionsComponent';
import { ICollection } from '../../../app/models/Collection';
import { RootStoreContext } from '../../../app/stores/rootStore';
import CollectionContentsTableComponent from './CollectionContentsTableComponent';
import CollectionWaybillDescriptionsComponent from './CollectionWaybillDescriptionsComponent';
import CollectionAttachmentsListComponent from './CollectionAttachmentsListComponent';

interface IProps {
	collectionId?: string;
	collection?: ICollection;
}

const ViewCollectionComponent: React.FC<IProps> = ({
	collectionId,
	collection: passedCollection
}) => {
	const rootContext = useContext(RootStoreContext);
	const { get } = rootContext.collectionStore;
	const { TabPane } = Tabs;

	const [collection, setCollection] = useState<ICollection>();

	useEffect(() => {
		if (collectionId) {
			get(collectionId).then(setCollection);
		} else {
			setCollection(passedCollection);
		}
	}, [collectionId, passedCollection, get]);

	return (
		<>
			<Tabs defaultActiveKey="1">
				<TabPane tab="Waybill" key="1">
					<CollectionWaybillDescriptionsComponent
						collection={collection}
						editableComment={true}
					/>
				</TabPane>
				<TabPane tab="Sender & Receiver" key="2">
					<Row>
						<Col lg={12} sm={24}>
							<CollectionAddressDescriptionsComponent
								title="sender"
								address={collection?.sender}
							/>
						</Col>
						<Col lg={12} sm={24}>
							<CollectionAddressDescriptionsComponent
								title="receiver"
								address={collection?.receiver}
							/>
						</Col>
					</Row>
				</TabPane>
				<TabPane tab="Contents" key="3">
					<CollectionContentsTableComponent
						data={collection?.contents}
						loading={false}
					/>
				</TabPane>
				<TabPane tab="Attachments" key="4">
					<CollectionAttachmentsListComponent
						attachments={collection?.attachments}
					/>
				</TabPane>
			</Tabs>
		</>
	);
};

export default ViewCollectionComponent;
