import { Input, Button, Form, Alert } from 'antd'
import React from 'react'
import { toast } from 'react-toastify';

interface IProps {
	closeModal: (result: string) => void;
	title?: 'email' | 'whatsapp'
	defaultValue?: string;
}

const ShareInputForm: React.FC<IProps> = ({ closeModal, title, defaultValue }) => {
	const onFinish = async (values: any) => {
		const value = values.value.replace(/\s/g, '');
		if (validate(value)) {
			console.log(value)
			closeModal(value);
		}
	}

	const validate = (value: string): boolean => {
		if (title === 'whatsapp') {
			if (value.indexOf('+') === -1) {
				toast.error('Invalid format, no international code');
				return false;
			}

			if (value.indexOf('+27') !== -1 && value.length < 12) {
				toast.error('Invalid number');
				return false;
			}

			return true;
		} else {
			const valid = (value.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			) ?? []).length > 0;
			if (!valid) {
				toast.error('Invalid email');
				return false;
			}

			return true;
		}
	}

	return (
		<>
			<Form
				labelCol={{ span: 6 }}
				wrapperCol={{ span: 24 }}
				layout='vertical'
				onFinish={onFinish}
				initialValues={{ value: defaultValue }}
			>
				<Form.Item label={title === 'email' ? 'Email' : 'Number'} name='value'
					rules={[{ required: true, message: `Please input ${title === 'email' ? 'an email' : 'a valid number with country code'}!` }]}
				>
					<Input autoComplete='off' />
				</Form.Item>
				{title === 'whatsapp' &&
					<Alert
						style={{ marginBottom: '16px' }}
						message="Please use international style numbers with country codes when using the whasapp feature, for example +27 82 123 1234"
						type="warning"
						closable
					/>
				}
				<Form.Item style={{ float: 'right' }}>
					<Button
						type='primary'
						htmlType='submit'
					>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</>
	)
}

export default ShareInputForm
