import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { Link } from "react-router-dom";

export interface INavItemPath {
	value: string;
	link?: string,
	icon?: JSX.Element,
	children?: INavItemPath[];
}

export const getActivePathItem = (paths: INavItemPath[]): { active: string, open?: string } => {
	let index = paths.findIndex(x => x.link === document.location.pathname);
	if (index !== -1) return { active: index.toString() };
	else {
		for (let i = 0; i < paths.length; i++) {
			const path = paths[i];
			if (path.children) {
				const tempIndex = path.children.findIndex(x => x.link === document.location.pathname);
				if (tempIndex !== -1) return { active: `${i}-${tempIndex}`, open: i.toString() }; // returning foreach not the function
			}
		}
	}

	return { active: '-1' }
}


export const generateNavBarItems = (paths: INavItemPath[]) => {
	const mapItem = (path: INavItemPath, index: string) => {
		return <Menu.Item key={index}><Link to={path.link!}>{path.value}</Link></Menu.Item>
	}

	return paths.map((path, index) => {
		if (path.children) {
			return (
				<SubMenu key={index} icon={path.icon} title={path.value}>
					{path.children.map((child, childIndex) => mapItem(child, index + '-' + childIndex))}
				</SubMenu>
			);
		}
		else {
			return mapItem(path, `${index}`)
		}
	})
}