import { createContext } from "react";
import UserStore from "./userStore";
import CommonStore from "./commonStore";
import CompanyStore from "./companyStore";
import AuditStore from "./auditStore";
import AddressStore from "./addressStore";
import CollectionStore from "./collectionStore";
import MediaItemStore from "./mediaItemStore";
import SupplierStore from "./supplierStore";
import AdhockStore from "./adhockStore";
import RateStore from "./rateStore";

export class RootStore {
	userStore: UserStore;
	commonStore: CommonStore;
	companyStore: CompanyStore;
	auditStore: AuditStore;
	addressStore: AddressStore;
	supplierStore: SupplierStore;
	collectionStore: CollectionStore;
	mediaItemStore: MediaItemStore;
	adhockStore: AdhockStore;
	rateStore: RateStore;

	constructor() {
		this.userStore = new UserStore(this);
		this.commonStore = new CommonStore(this);
		this.companyStore = new CompanyStore(this);
		this.auditStore = new AuditStore(this);
		this.addressStore = new AddressStore(this);
		this.collectionStore = new CollectionStore(this);
		this.mediaItemStore = new MediaItemStore(this);
		this.supplierStore = new SupplierStore(this);
		this.adhockStore = new AdhockStore(this);
		this.rateStore = new RateStore(this);
	}
}

export const RootStoreContext = createContext(new RootStore());