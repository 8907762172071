import axios, { AxiosResponse } from 'axios';
import { IAddress } from '../models/Address';
import {
	CollectionStatus,
	IAdminCollection,
	ICollection,
	ICollectionDetailsValues,
	ICreateCollectionEmailNotification,
	INewCollectionFormValues,
	IPod,
	IReplaceCollectionContentValues,
	ISetCollectionAddressesValues,
	IUpdateComment
} from '../models/Collection';
import { ICompany } from '../models/company';
import { IDashboard } from '../models/Dashboard';
import { IEmailNotification, IMediaItemFile } from '../models/MediaItem';
import { IQueryableResponse } from '../models/queryableResponse';
import {
	IFuelRateMassUpdateRequest,
	IRate,
	IRateEstimate,
	IRateGetEstimateRequest,
	IRateGetRequest,
	IRatePostRequest,
	IRateRoute,
	IRateValueRateMassUpdateRequest
} from '../models/Rate';
import { IRequestData } from '../models/requestData';
import { IServerSuccessResponse } from '../models/ServerSuccessResponse';
import { IServiceType } from '../models/ServiceType';
import { IShareableLink } from '../models/ShareableLink';
import {
	IAssignCollectionSupplierCommand,
	ISupplier
} from '../models/Supplier';
import {
	ILoginFormValues,
	IRegisterUserFormValues,
	IUpdateCurrentUserPasswordFormValues,
	IUpdateUserPasswordFormValues,
	IUser
} from '../models/user';
import {
	requestInterceptor,
	responseFailInterceptor,
	responseSuccessInterceptor,
	requestDataToString,
	buildQueryString
} from './apiInterceptors';

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;
console.log('Current API host is set to:', process.env.REACT_APP_API_HOST);

axios.interceptors.request.use(requestInterceptor, (error) => {
	return Promise.reject(error);
});

axios.interceptors.response.use(
	responseSuccessInterceptor,
	responseFailInterceptor
);

const responseBody = (response: AxiosResponse) => response.data;

const sleep = (ms: number) => (response: AxiosResponse) => {
	if (process.env.NODE_ENV !== 'production') {
		console.log('slowing api request down by ' + ms);
		return new Promise<AxiosResponse>((resolve) =>
			setTimeout(() => resolve(response), ms)
		);
	} else {
		return new Promise<AxiosResponse>((resolve) => resolve(response));
	}
};

const requests = {
	get: (url: string) => axios.get(url).then(sleep(1000)).then(responseBody),
	post: (url: string, body: {}) =>
		axios.post(url, body).then(sleep(1000)).then(responseBody),
	put: (url: string, body: {}) =>
		axios.put(url, body).then(sleep(1000)).then(responseBody),
	del: (url: string) =>
		axios.delete(url).then(sleep(1000)).then(responseBody),
	postForm: (url: string, body: any) => {
		let formData = new FormData();
		Object.keys(body).forEach((key) => {
			formData.append(key, body[key]);
		});
		return axios
			.post(url, formData, {
				headers: { 'Content-type': 'multipart/formdata' }
			})
			.then(responseBody);
	}
};

const User = {
	current: (): Promise<IUser> => requests.get('/user/me'),
	get: (id: string): Promise<IUser> => requests.get(`/user/${id}`),
	login: (user: ILoginFormValues): Promise<IUser> =>
		requests.post('/user/login', user),
	register: (user: IRegisterUserFormValues) =>
		requests.post('/user/register', user),
	update: (user: IRegisterUserFormValues) =>
		requests.put('/user/admin/update', user),
	updateCurrentProfile: (user: IRegisterUserFormValues) =>
		requests.put('/user/profile/', user),
	updateCurrentPassword: (password: IUpdateCurrentUserPasswordFormValues) =>
		requests.put('/user/profile/password', password),
	updatePassword: (values: IUpdateUserPasswordFormValues) =>
		requests.put('/user/reset', values),
	list: (request: IRequestData = {}): Promise<IQueryableResponse<IUser>> =>
		requests.get('/user/users' + requestDataToString(request)),
	delete: (id: string): Promise<void> => requests.del('/user/delete/' + id),
	sendVerification: (email: string): Promise<void> =>
		requests.get(`/user/profile/reverify/${encodeURI(email)}`)
};

const Company = {
	create: (company: ICompany) => requests.post('/company/register', company),
	edit: (company: ICompany) => requests.put('/company/update', company),
	list: (request: IRequestData = {}): Promise<IQueryableResponse<ICompany>> =>
		requests.get('/company/list' + requestDataToString(request)),
	get: (id: string): Promise<ICompany> => requests.get('/company/get/' + id),
	delete: (id: string): Promise<void> => requests.del('/company/delete/' + id)
};

const Address = {
	create: (address: IAddress): Promise<IServerSuccessResponse> =>
		requests.post('/address/create', address),
	edit: (address: IAddress): Promise<IServerSuccessResponse> =>
		requests.put('/address/update', address),
	list: (
		companyId: string,
		request: IRequestData = {}
	): Promise<IQueryableResponse<IAddress>> =>
		requests.get(
			`/address/list/${companyId}` + requestDataToString(request)
		),
	get: (id: string): Promise<IAddress> => requests.get('/address/get/' + id),
	delete: (id: string): Promise<void> => requests.del('/address/delete/' + id)
};

const Rate = {
	create: (rate: IRatePostRequest): Promise<void> =>
		requests.post('/rate/create', rate),
	edit: (rate: IRatePostRequest): Promise<void> =>
		requests.put('/rate/update', rate),
	editFuel: (rates: IFuelRateMassUpdateRequest): Promise<void> =>
		requests.put('/rate/update/fuel', rates),
	editRateValue: (rates: IRateValueRateMassUpdateRequest): Promise<void> =>
		requests.put('/rate/update/rate-value', rates),
	list: (
		companyId: string,
		request: IRequestData = {}
	): Promise<IQueryableResponse<IRate>> =>
		requests.get(`/rate/list/${companyId}` + requestDataToString(request)),
	listRoutes: (companyId: string): Promise<IRateRoute[]> =>
		requests.get(`/rate/list/${companyId}/routes`),
	getById: (id: string): Promise<IRate> =>
		requests.get('/rate/getById/' + id),
	get: (request: IRateGetRequest): Promise<IRate> =>
		requests.get('/rate/get' + buildQueryString(request)),
	getEstimate: (request: IRateGetEstimateRequest): Promise<IRateEstimate> =>
		requests.get('/rate/estimate' + buildQueryString(request)),
	delete: (id: string): Promise<void> => requests.del('/rate/delete/' + id)
};

const Supplier = {
	create: (supplier: ISupplier): Promise<IServerSuccessResponse> =>
		requests.post('/supplier/create', supplier),
	edit: (supplier: ISupplier): Promise<void> =>
		requests.put('/supplier/update', supplier),
	list: (
		request: IRequestData = {}
	): Promise<IQueryableResponse<ISupplier>> =>
		requests.get('/supplier/list' + requestDataToString(request)),
	get: (id: string): Promise<ISupplier> =>
		requests.get('/supplier/get/' + id),
	delete: (id: string): Promise<void> =>
		requests.del('/supplier/delete/' + id),
	acknowledge: (token: string, status: CollectionStatus): Promise<void> =>
		requests.put(`/supplier/acknowledge/`, { token, status })
};

const Audit = {
	user: (id: string) => requests.get(`/audit/user/${id}`),
	collection: (id: string) => requests.get(`/audit/collection/${id}`)
};

const Adhock = {
	extendTokens: () => requests.get(`/user/tokens/extend`)
};

const Collection = {
	get: (id: string): Promise<ICollection> =>
		requests.get(`/collection/get/${id}`),
	delete: (id: string) => requests.del(`/collection/${id}`),
	create: (collection: INewCollectionFormValues): Promise<ICollection> =>
		requests.post('/collection/new', collection),
	setAddresses: (values: ISetCollectionAddressesValues) =>
		requests.put('/collection/set-addresses', values),
	replaceContents: (values: IReplaceCollectionContentValues) =>
		requests.put('/collection/replace-contents', values),
	completeDraft: (values: ICollectionDetailsValues) =>
		requests.put('/collection/complete-draft', values),
	list: (options: string): Promise<ICollection[]> =>
		requests.get(`/collection/list${options}`),
	adminList: (
		options: string
	): Promise<IQueryableResponse<IAdminCollection>> =>
		requests.get(`/collection/admin-list${options}`),
	adminGet: (id: string): Promise<IAdminCollection> =>
		requests.get(`/collection/admin-get/${id}`),
	adminUpdate: (collection: IAdminCollection) =>
		requests.put('/collection/admin-update', collection),
	updateComment: (request: IUpdateComment) =>
		requests.put('/collection/update-comment', request),
	share: (collectionId: string): Promise<IShareableLink> =>
		requests.put('/collection/shared/share', {
			collectionId: collectionId
		}),
	getShared: (token: string): Promise<ICollection> =>
		requests.get(`/collection/shared/view/${token}`),
	getSharedAcknowledgement: (token: string): Promise<ICollection> =>
		requests.get(`/collection/shared/acknowledgement/${token}`),
	email: (data: ICreateCollectionEmailNotification) =>
		requests.postForm('/collection/shared/email', data),
	acceptSupplier: (data: IAssignCollectionSupplierCommand) =>
		requests.put('/collection/accept', data),
	getPod: (id: string): Promise<IPod> => requests.get(`/collection/pod/${id}`)
};

const MediaItem = {
	delete: (collectionId: string, publicId: string): Promise<void> =>
		requests.del(`/media/delete/${collectionId}/${publicId}`),
	get: (waybillNumber: number, publicId: string): Promise<IMediaItemFile> =>
		requests.get(`/media/${waybillNumber}/get?publicId=${publicId}`),
	email: (attachment: IEmailNotification): Promise<void> =>
		requests.postForm(`/media/share/email`, attachment)
};

const Dashboard = {
	get: (): Promise<IDashboard> => requests.get(`/dashboard`)
};

const ServiceType = {
	list: (): Promise<IServiceType[]> =>
		requests.get(`/collection/service-types`)
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	User: User,
	Company: Company,
	Audit: Audit,
	Address: Address,
	Collection: Collection,
	ServiceType: ServiceType,
	MediaItem: MediaItem,
	Supplier: Supplier,
	Adhock: Adhock,
	Rate: Rate,
	Dashboard: Dashboard
};
