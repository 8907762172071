import { Button, Form, Input, Skeleton } from 'antd'
import React, { Fragment } from 'react'
import { Guid } from '../../app/helpers/GuidHelper';
import { ICollectionContent } from '../../app/models/Collection';

interface IProps {
	closeModal: (result: ICollectionContent) => void;
	initialValue?: ICollectionContent;
	editMode: boolean;
}

const CollectionContentForm: React.FC<IProps> = ({ closeModal, initialValue, editMode }) => {

	const onFinish = async (values: any) => {
		if (!editMode) {
			values.id = Guid.newGuid();
		}
		closeModal(values);
	}

	return (
		<Fragment>
			<Skeleton loading={!initialValue && editMode}>
				<Form
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 18 }}
					layout='horizontal'
					initialValues={initialValue}
					onFinish={onFinish}
				>
					<Form.Item label='Description' name='description'
						rules={[{ required: true, message: 'Please input the description!' }]}
					>
						<Input autoComplete='off' maxLength={20} />
					</Form.Item>
					<Form.Item label='Length' name='length'
						rules={[{ required: true, message: 'Please input the length!' }]}
					>
						<Input autoComplete='off' type='number' />
					</Form.Item>
					<Form.Item label='Width' name='width'
						rules={[{ required: true, message: 'Please input the width!' }]}
					>
						<Input autoComplete='off' type='number' />
					</Form.Item>
					<Form.Item label='Height' name='height'
						rules={[{ required: true, message: 'Please input the height!' }]}
					>
						<Input autoComplete='off' type='number' />
					</Form.Item>
					<Form.Item label='Weight' name='weight'
						rules={[{ required: true, message: 'Please input the weight!' }]}
					>
						<Input autoComplete='off' type='number' />
					</Form.Item>
					<Form.Item label='Pieces' name='pieces'
						rules={[{ required: true, message: 'Please input the number of pieces!' }]}
					>
						<Input autoComplete='off' type='number' />
					</Form.Item>
					<Form.Item hidden={true} name={'id'}>
						<Input autoComplete='off' />
					</Form.Item>
					<Form.Item style={{ float: 'right' }}>
						<Button type="primary" htmlType="submit">
							{editMode ? 'Save Changes' : 'Save Content'}
						</Button>
					</Form.Item>
				</Form>
			</Skeleton>
		</Fragment>
	)
}

export default CollectionContentForm
