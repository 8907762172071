import { Col, Card } from 'antd'
import React from 'react'
import { AuditType, IAuditEntry } from '../../models/audit'

interface IProps {
	auditHistory: IAuditEntry[];
}

interface IKeyValuePair {
	key: string;
	value: string;
}

const extractData = (data: any): IKeyValuePair[] => {
	const result: IKeyValuePair[] = [];
	const keys = Object.keys(data);
	keys.forEach(key => {
		if (typeof data[key] === 'object') {
			splitData(data[key]).forEach(pair => result.push(pair));
		} else {
			result.push({
				key: key,
				value: data[key].toString(),
			});
		}
	})
	return result;
}

const splitData = (data: any): IKeyValuePair[] => {
	if (!data || data === 'null') {
		return []
	};
	let result: IKeyValuePair[] = [];
	if (JSON.stringify(data)[0] === '[' && JSON.stringify(data)[0] === '{') {
		data.forEach((dataItem: any) => {
			result = [...result, ...extractData(dataItem)];
		})
	} else {
		result = [...result, ...extractData(data)];
	}

	return result;
}

const getValue = (value: string): JSX.Element[] | string => {
	const result: JSX.Element[] = [];

	try {
		const parsed = JSON.parse(value);
		if (typeof parsed !== 'object') {
			throw new Error();
		}

		splitData(parsed).forEach(pair => {
			result.push(
				<div>
					{pair.key}: {pair.value}
				</div>
			)
		});
	} catch {
		result.push(<div>{value}</div>)
	}

	return result.length === 0 ? '-' : result;
}

const AuditHistoryCard: React.FC<IProps> = ({ auditHistory }) => {
	return (
		<>
			{auditHistory?.map(entry => {
				return (
					entry.changes.length > 0 &&
					<div style={{ marginBottom: '32px' }}>
						{new Date(entry.dateTime.toString()).toDateString()}
						<Col>
							<Card title={AuditType[entry.auditType]}>
								<p>Changed by: {entry.userEmail}</p>
								<p>Changes:</p>
								{entry?.changes?.map(change => {
									return (
										<Card type='inner' title={change.affectedColumn}>
											<Card.Grid style={{ width: '50%', paddingTop: '0', paddingBottom: '0' }}>{getValue(change.oldValue)}</Card.Grid>
											<Card.Grid style={{ width: '50%', paddingTop: '0', paddingBottom: '0' }}>{getValue(change.newValue)}</Card.Grid>
										</Card>
									)
								})}
							</Card>
						</Col>
					</div>
				)
			})}
		</>
	)
}

export default AuditHistoryCard
