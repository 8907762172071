import { Alert, Button, Form, Radio, Select, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { CollectionStatus, ICollection } from '../../app/models/Collection';
import { IAssignCollectionSupplierCommand, ISupplier, ParcelPerfectConfigurationType, SupplierAcknowledgementType } from '../../app/models/Supplier';
import { RootStoreContext } from '../../app/stores/rootStore';

interface IProps {
	collection: ICollection;
	closeModal: () => void;
}

const SupplierSelection: React.FC<IProps> = ({ collection, closeModal }) => {
	const rootContext = useContext(RootStoreContext);
	const { getSuppliers } = rootContext.supplierStore
	const { acceptSupplier } = rootContext.collectionStore
	const { Text } = Typography;

	const [request, setRequest] = useState<Partial<IAssignCollectionSupplierCommand>>({ collectionId: collection.id, status: CollectionStatus.Accepted });
	const [suppliers, setSuppliers] = useState<ISupplier[]>();
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		getSuppliers({ limit: -1 })
			.then(result => {
				setSuppliers(result.data);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onFinish = () => {
		setLoading(true);
		acceptSupplier(request as IAssignCollectionSupplierCommand)
			.then(() => {
				setLoading(false);
				closeModal();
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const supplierSelected = (value: any) => {
		setRequest({ ...request, supplierId: value })
	}

	const validateParcelPerfectConfig = (supplierId: string | undefined): boolean => {
		if (!supplierId) {
			return false;
		}

		const supplier = suppliers?.find(x => x.id === request.supplierId);
		if (!supplier) {
			return false;
		}

		if (supplier.parcelPerfectConfigurations && supplier.parcelPerfectConfigurations.some(x => x.type === ParcelPerfectConfigurationType.Integration)) {
			return true
		}

		return false;
	}

	const validate = (): boolean => {
		if (request.emails && request.emails.length > 0 && request.supplierAcknowledgementType === SupplierAcknowledgementType.Email) {
			return true;
		}

		if (validateParcelPerfectConfig(request.supplierId) && request.serviceId && request.supplierAcknowledgementType === SupplierAcknowledgementType.ParcelPerfect) {
			return true;
		}

		return false;
	}

	return (
		<>
			<div id='CustomSupplierSelectionContainer'>
				<Form
					layout='vertical'
					onFinish={() => { onFinish() }}
				>
					<Form.Item
						label='Supplier'
					>
						<Select
							virtual={false}
							placeholder='select a company'
							loading={!suppliers}
							showSearch
							filterOption={(input, option) =>
								option?.props.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={supplierSelected}
							getPopupContainer={() => document.getElementById('CustomSupplierSelectionContainer')!}
						>
							{suppliers?.map(supplier => {
								return (<Select.Option key={supplier.id} value={supplier.id} title={supplier.name}>{supplier.name}</Select.Option>)
							})}
						</Select>
					</Form.Item>
					<Form.Item label="How would you like to proceed" hidden={!request.supplierId}>
						<Radio.Group onChange={value => setRequest({ ...request, supplierAcknowledgementType: value.target.value })}>
							<Radio value={SupplierAcknowledgementType.Email}>{SupplierAcknowledgementType[SupplierAcknowledgementType.Email]}</Radio>
							<Radio value={SupplierAcknowledgementType.ParcelPerfect}>{SupplierAcknowledgementType[SupplierAcknowledgementType.ParcelPerfect]}</Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item
						label='Select an email'
						hidden={request.supplierAcknowledgementType !== SupplierAcknowledgementType.Email}
					>
						<Select
							virtual={false}
							placeholder='select an email'
							mode='multiple'
							showSearch
							filterOption={(input, option) =>
								option?.props.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(value) => setRequest({ ...request, emails: value as string[] })}
							getPopupContainer={() => document.getElementById('CustomSupplierSelectionContainer')!}
						>
							{suppliers?.find(x => x.id === request.supplierId)?.emails.map(email => {
								return (<Select.Option key={email} value={email} title={email}>{email}</Select.Option>)
							})}
						</Select>
					</Form.Item>
					<Form.Item hidden={request.supplierAcknowledgementType !== SupplierAcknowledgementType.ParcelPerfect}>
						<Alert message={`This action will create an entry into ${suppliers?.find(x => x.id === request.supplierId)?.name}'s system directly, please note this action cannot be undone`} type="warning" />
						{!validateParcelPerfectConfig(request.supplierId) &&
							(
								<div>
									<br />
									<Alert message={`${suppliers?.find(x => x.id === request.supplierId)?.name}'s system currently does not support direct waybill collections, please use an alternative mehtod`} type="error" />
								</div>
							)}
					</Form.Item>
					<Form.Item
						label='Select a Service'
						hidden={request.supplierAcknowledgementType !== SupplierAcknowledgementType.ParcelPerfect}
					>
						<Text type='secondary'>The user selected service is: {collection.serviceType.displayName} ({collection.serviceType.code})</Text>
						<Select
							virtual={false}
							placeholder='select a service'
							showSearch
							filterOption={(input, option) =>
								option?.props.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(value) => setRequest({ ...request, serviceId: value?.toString() })}
							getPopupContainer={() => document.getElementById('CustomSupplierSelectionContainer')!}
						>
							{suppliers?.find(x => x.id === request.supplierId)?.services.map(service => {
								return (<Select.Option key={service.id} value={service.id} title={service.code + service.displayName}>{service.displayName} ({service.code})</Select.Option>)
							})}
						</Select>
					</Form.Item>
					<Form.Item style={{ float: 'right' }}>
						<Button loading={loading} type="primary" htmlType="submit" disabled={!validate()}>
							Save and close
						</Button>
					</Form.Item>
				</Form>
			</div>
		</>
	)
}

export default SupplierSelection
