import {
	Typography,
	Form,
	Input,
	Select,
	DatePicker,
	Button,
	TreeSelect
} from 'antd';
import moment from 'moment';
import React from 'react';
import {
	CollectionStatus,
	ICollectionListRequest
} from '../../../app/models/Collection';
import { ISupplier } from '../../../app/models/Supplier';
import { IUser } from '../../../app/models/user';

interface IProps {
	collectionRequest: ICollectionListRequest;
	setCollectionRequest: (collectionRequest: ICollectionListRequest) => void;
	loading: boolean;
	users?: IUser[];
	suppliers?: ISupplier[];
}

interface ICompanyUsers {
	value: string;
	title: string;
	children?: ICompanyUsers[];
}

const CollectionListFilterComponent: React.FC<IProps> = ({
	collectionRequest,
	setCollectionRequest,
	loading,
	users = [],
	suppliers
}) => {
	const onFinish = (values: ICollectionListRequest) => {
		setCollectionRequest({
			...values,
			from: (values as any)?.from?.toDate(),
			to: (values as any)?.to?.toDate()
		});
	};

	const companyUsers = (): ICompanyUsers[] => {
		const companies: ICompanyUsers[] = [];
		users.forEach((user) => {
			var companyIndex = companies.findIndex(
				(x) => x.value === user.company.id
			);
			if (companyIndex === -1) {
				companies.push({
					value: user.company.id,
					title: user.company.companyName,
					children: users
						.filter((value) => value.company.id === user.company.id)
						.filter(
							(value, index, self) =>
								self.map((x) => x.id).indexOf(value.id) ===
								index
						)
						.map((result) => {
							return {
								value: `${user.company.id},${result.id}`,
								title: result.displayName
							};
						})
				});
			}
		});

		return [
			{ title: 'All', value: '' },
			...companies.sort((a, b) => a.title.localeCompare(b.title))
		];
	};

	return (
		<>
			<div id="CustomCollectionListFilterComponentContainer">
				<Typography.Title level={5}>Filters</Typography.Title>
				<Form
					layout="vertical"
					onFinish={onFinish}
					initialValues={{
						...collectionRequest,
						from: moment(collectionRequest.from),
						to: moment(collectionRequest.to)
					}}
				>
					<Form.Item label="Keyword" name="keyword">
						<Input autoComplete="off" />
					</Form.Item>
					<Form.Item label="Status" name={'status'}>
						<Select
							virtual={false}
							getPopupContainer={() =>
								document.getElementById(
									'CustomCollectionListFilterComponentContainer'
								)!
							}
							defaultValue={''}
						>
							<Select.Option key={'all'} value={''}>
								All
							</Select.Option>
							<Select.Option
								key={CollectionStatus.Accepted}
								value={CollectionStatus.Accepted}
							>
								{CollectionStatus[CollectionStatus.Accepted]
									.charAt(0)
									.toUpperCase() +
									CollectionStatus[
										CollectionStatus.Accepted
									].slice(1)}
							</Select.Option>
							<Select.Option
								key={CollectionStatus.BeingProcessed}
								value={CollectionStatus.BeingProcessed}
							>
								{CollectionStatus[
									CollectionStatus.BeingProcessed
								]
									.charAt(0)
									.toUpperCase() +
									CollectionStatus[
										CollectionStatus.BeingProcessed
									].slice(1)}
							</Select.Option>
							<Select.Option
								key={CollectionStatus.Cancelled}
								value={CollectionStatus.Cancelled}
							>
								{CollectionStatus[CollectionStatus.Cancelled]
									.charAt(0)
									.toUpperCase() +
									CollectionStatus[
										CollectionStatus.Cancelled
									].slice(1)}
							</Select.Option>
							<Select.Option
								key={CollectionStatus.Collected}
								value={CollectionStatus.Collected}
							>
								{CollectionStatus[CollectionStatus.Collected]
									.charAt(0)
									.toUpperCase() +
									CollectionStatus[
										CollectionStatus.Collected
									].slice(1)}
							</Select.Option>
							<Select.Option
								key={CollectionStatus.Completed}
								value={CollectionStatus.Completed}
							>
								{CollectionStatus[CollectionStatus.Completed]
									.charAt(0)
									.toUpperCase() +
									CollectionStatus[
										CollectionStatus.Completed
									].slice(1)}
							</Select.Option>
							<Select.Option
								key={CollectionStatus.Declined}
								value={CollectionStatus.Declined}
							>
								{CollectionStatus[CollectionStatus.Declined]
									.charAt(0)
									.toUpperCase() +
									CollectionStatus[
										CollectionStatus.Declined
									].slice(1)}
							</Select.Option>
							<Select.Option
								key={CollectionStatus.Delivered}
								value={CollectionStatus.Delivered}
							>
								{CollectionStatus[CollectionStatus.Delivered]
									.charAt(0)
									.toUpperCase() +
									CollectionStatus[
										CollectionStatus.Delivered
									].slice(1)}
							</Select.Option>
							<Select.Option
								key={CollectionStatus.Pending}
								value={CollectionStatus.Pending}
							>
								{CollectionStatus[CollectionStatus.Pending]
									.charAt(0)
									.toUpperCase() +
									CollectionStatus[
										CollectionStatus.Pending
									].slice(1)}
							</Select.Option>
							<Select.Option
								key={CollectionStatus.Requested}
								value={CollectionStatus.Requested}
							>
								{CollectionStatus[CollectionStatus.Requested]
									.charAt(0)
									.toUpperCase() +
									CollectionStatus[
										CollectionStatus.Requested
									].slice(1)}
							</Select.Option>
						</Select>
					</Form.Item>
					{suppliers && (
						<Form.Item label="Supplier" name={'supplierId'}>
							<Select
								virtual={false}
								placeholder="select a supplier"
								showSearch
								filterOption={(input, option) =>
									option?.props.title
										?.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}
								defaultValue={''}
								getPopupContainer={() =>
									document.getElementById(
										'CustomCollectionListFilterComponentContainer'
									)!
								}
							>
								<Select.Option
									key={'all'}
									value={''}
									title={'All'}
								>
									All
								</Select.Option>
								{suppliers?.map((supplier) => {
									return (
										<Select.Option
											key={supplier.id}
											value={supplier.id}
											title={supplier.name}
										>
											{supplier.name}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
					)}
					<Form.Item label="Company & User" name={'requesterId'}>
						<TreeSelect
							virtual={false}
							getPopupContainer={() =>
								document.getElementById(
									'CustomCollectionListFilterComponentContainer'
								)!
							}
							defaultValue={''}
							treeData={companyUsers()}
						/>
					</Form.Item>
					<Form.Item label="From" name={'from'}>
						<DatePicker
						// getPopupContainer={() => document.getElementById('CustomCollectionListFilterComponentContainer')!}
						/>
					</Form.Item>
					<Form.Item label="To" name={'to'}>
						<DatePicker
						// getPopupContainer={() => document.getElementById('CustomCollectionListFilterComponentContainer')!}
						/>
					</Form.Item>
					<Form.Item label="Invoiced?" name={'invoiced'}>
						<Select
							virtual={false}
							getPopupContainer={() =>
								document.getElementById(
									'CustomCollectionListFilterComponentContainer'
								)!
							}
							defaultValue={''}
						>
							<Select.Option key={'all'} value={''}>
								All
							</Select.Option>
							<Select.Option key={1} value={1}>
								Yes
							</Select.Option>
							<Select.Option key={2} value={2}>
								No
							</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item style={{ float: 'right' }}>
						<Button
							loading={loading}
							htmlType="submit"
							type="primary"
						>
							Update
						</Button>
					</Form.Item>
				</Form>
			</div>
		</>
	);
};

export default CollectionListFilterComponent;
