import { Col, Row } from 'antd'
import React, { useEffect } from 'react'
import { IAddress } from '../../../app/models/Address'
import { ICollection } from '../../../app/models/Collection'
import CollectionAddressDescriptionsComponent from './CollectionAddressDescriptionsComponent'
import CollectionAddressSelectorComponent from './CollectionAddressSelectorComponent'

interface IProps {
	collection: Partial<ICollection>;
	setCollection: (collection: Partial<ICollection>) => void;
	addresses: IAddress[];
	setAddresses: (addresses: IAddress[]) => void;
	validateCallback?: (value: boolean) => void;
}

const CollectionAddressComponent: React.FC<IProps> = ({ collection, setCollection, addresses, setAddresses, validateCallback }) => {
	const setSender = (address: IAddress) => {
		setCollection({...collection, sender: address});
	}

	const setReceiver = (address: IAddress) => {
		setCollection({...collection, receiver: address});
	}

	const validate = () => {
		if (validateCallback && collection.sender && collection.receiver) {
			collection.sender.id === collection.receiver.id ? validateCallback(false) : validateCallback(true);
		}
	}

	useEffect(() => {
		validate();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collection.sender, collection.receiver])
	
	useEffect(() => {
		// this needs to be done because the address in the addressbook with a matching Id of the sender/receiver will 
		// not always match and could have been updated. You want to display the sender/receiver address from the collection, 
		// not the address from the addressbook

		if (collection.sender) {
			setAddresses([...addresses.filter(x => x.id !== collection.sender?.id), collection.sender])
		}
		if (collection.receiver) {
			setAddresses([...addresses.filter(x => x.id !== collection.receiver?.id), collection.receiver])
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<Row>
				<Col lg={12} sm={24}>
					<h2>Sender</h2>
					<CollectionAddressSelectorComponent
						addresses={addresses!}
						setAddresses={setAddresses}
						company={collection.requester!.company}
						selectedAddress={collection.sender}
						setSelectedAddress={setSender} />
					<br /><br />
					<CollectionAddressDescriptionsComponent title='sender' address={collection.sender} />
				</Col>
				<Col lg={12} sm={24}>
					<h2>Receiver</h2>
					<CollectionAddressSelectorComponent
						addresses={addresses!}
						setAddresses={setAddresses}
						company={collection.requester!.company}
						selectedAddress={collection.receiver}
						setSelectedAddress={setReceiver} />
					<br /><br />
					<CollectionAddressDescriptionsComponent title='receiver' address={collection.receiver} />
				</Col>
			</Row>
		</>
	)
}

export default CollectionAddressComponent
