import { makeObservable, action } from 'mobx';
import agent from '../api/agent';
import { ConvertCollectionAdminRequestIntoOptions } from '../helpers/CollectionHelper';
import { fromUTC } from '../helpers/DateHelper';
import {
	IAdminCollection,
	ICollection,
	ICollectionDetailsValues,
	ICollectionListRequest,
	ICreateCollectionEmailNotification,
	INewCollectionFormValues,
	IPod,
	IReplaceCollectionContentValues,
	ISetCollectionAddressesValues,
	IUpdateComment
} from '../models/Collection';
import { IQueryableResponse } from '../models/queryableResponse';
import { IServiceType } from '../models/ServiceType';
import { IShareableLink } from '../models/ShareableLink';
import { IAssignCollectionSupplierCommand } from '../models/Supplier';
import { RootStore } from './rootStore';

export default class CollectionStore {
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		makeObservable(this);
		this.rootStore = rootStore;
	}

	@action newCollection = async (
		newCollectionFormValues: INewCollectionFormValues
	): Promise<ICollection> => {
		try {
			return await agent.Collection.create(newCollectionFormValues);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};

	@action get = async (id: string): Promise<ICollection> => {
		try {
			let result = await agent.Collection.get(id);
			result.readyDate = fromUTC(result.readyDate);
			return result;
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};

	@action listServiceTypes = async (): Promise<IServiceType[]> => {
		try {
			return await agent.ServiceType.list();
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};

	@action setAddresses = async (values: ISetCollectionAddressesValues) => {
		try {
			await agent.Collection.setAddresses(values);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};

	@action updateComment = async (request: IUpdateComment) => {
		try {
			await agent.Collection.updateComment(request);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};

	@action replaceContents = async (
		values: IReplaceCollectionContentValues
	) => {
		try {
			await agent.Collection.replaceContents(values);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};

	@action completeDraft = async (values: ICollectionDetailsValues) => {
		try {
			await agent.Collection.completeDraft(values);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};

	@action list = async (
		values: ICollectionListRequest
	): Promise<ICollection[]> => {
		try {
			const options = ConvertCollectionAdminRequestIntoOptions(values);
			return await agent.Collection.list(options);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};

	@action adminList = async (
		values: ICollectionListRequest
	): Promise<IQueryableResponse<IAdminCollection>> => {
		try {
			const options = ConvertCollectionAdminRequestIntoOptions(values);
			return await agent.Collection.adminList(options);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};

	@action adminGet = async (id: string): Promise<IAdminCollection> => {
		try {
			let result = await agent.Collection.adminGet(id);
			result.readyDate = fromUTC(result.readyDate);
			return result;
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};

	@action adminUpdate = async (collection: IAdminCollection) => {
		try {
			await agent.Collection.adminUpdate(collection);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};

	@action deleteCollection = async (id: string) => {
		try {
			await agent.Collection.delete(id);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};

	@action createCollection = async (collection: ICollection) => {
		try {
			await this.setAddresses({
				collectionId: collection.id!,
				senderAddressId: collection.sender!.id!,
				receiverAddressId: collection.receiver!.id!
			});
			await this.replaceContents({
				collectionId: collection.id!,
				contents: collection.contents!
			});
			await this.completeDraft({
				collectionId: collection.id!,
				comment: collection.comment,
				insurance: collection.insurance!,
				readyDate: collection.readyDate!,
				readyTime: collection.readyTime!,
				closingTime: collection.closingTime!,
				serviceTypeId: collection.serviceType!.id
			});
		} catch (error) {
			// do nothing as the error will be toasted
		}
	};

	@action share = async (collectionId: string): Promise<IShareableLink> => {
		try {
			return await agent.Collection.share(collectionId);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};

	@action getShared = async (token: string): Promise<ICollection> => {
		try {
			return await agent.Collection.getShared(token);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};

	@action getSharedAcknowledgement = async (
		token: string
	): Promise<ICollection> => {
		try {
			return await agent.Collection.getSharedAcknowledgement(token);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};

	@action email = async (
		data: ICreateCollectionEmailNotification
	): Promise<void> => {
		try {
			await agent.Collection.email(data);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};

	@action acceptSupplier = async (
		data: IAssignCollectionSupplierCommand
	): Promise<void> => {
		try {
			await agent.Collection.acceptSupplier(data);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};

	@action getPod = async (id: string): Promise<IPod> => {
		try {
			return await agent.Collection.getPod(id);
		} catch (error) {
			console.debug(error);
			throw error;
		}
	};
}
