import { Avatar, Layout, Row, Popover } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import './Header.css'
import { RootStoreContext } from '../../app/stores/rootStore';
import { useContext } from 'react';
import Role from '../../app/models/role';
import { Link } from 'react-router-dom';
import UserPopOver from '../../app/components/popOvers/UserPopOver';
import GuessPopOver from '../../app/components/popOvers/GuessPopOver';

const Header = () => {
	const rootContext = useContext(RootStoreContext);
	const { user } = rootContext.userStore;
	const { Header } = Layout;

	return (
		<Header className="site-layout-background" style={{ padding: '0 16px' }}>
			<Row align="middle" style={{ height: '100%' }}>
				{process.env.REACT_APP_ENV !== 'production' &&
					<h1 style={{ padding: 0, margin: 0 }}>
						<span style={{ color: 'red' }}>{process.env.REACT_APP_ENV?.toUpperCase()}</span><span style={{ color: 'white' }}> | CURRENT USER: {user?.email ?? 'Guest'}  | USER TYPE: {Role[(user?.role) ?? 0]}</span>
					</h1>
				}
				{user &&
					<Popover title='User Actions' placement="leftTop" content={(<UserPopOver />)}>
						<Link style={{ marginLeft: 'auto' }} to='/account/profile'>
							<Avatar size='large' icon={<UserOutlined />} />
						</Link>
					</Popover>}
				{!user && 
					<Popover title='User Actions' placement="leftTop" content={(<GuessPopOver />)}>
						<Avatar style={{ marginLeft: 'auto' }} size='large' icon={<UserOutlined />} />
					</Popover>
				}
			</Row>
		</Header>
	)
}

export default Header
