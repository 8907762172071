import { Layout } from 'antd';
import { Link } from 'react-router-dom';

const Footer = () => {
	const { Footer } = Layout;

	return (
		<Footer style={{ textAlign: 'center' }}>Cum Laude Freight Management ©{new Date().getFullYear().toString()} - <Link to='/tc'>Terms & Conditions</Link></Footer>
	)
}

export default Footer
