import { Spin } from 'antd';
import React, { useContext, useEffect } from 'react'
import { replace } from '../../../app/helpers/pdf/ViewWaybillReport';
import { RootStoreContext } from '../../../app/stores/rootStore';

const CollectionSharedViewDownload = () => {
	const rootContext = useContext(RootStoreContext);
	const { getShared } = rootContext.collectionStore;

	const search = window.location.search;
	const params = new URLSearchParams(search);
	const token: string = params.get('token') || '';

	function delay(ms: number) {
		return new Promise( resolve => setTimeout(resolve, ms) );
	}

	useEffect(() => {
		const doAsync = async ()  => {
			// delaying here to protect against brute force attacks
			await delay(1000);
			getShared(token)
				.then(collection => {
					replace(collection);
				})
		}
		
		doAsync()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div style={{marginLeft: 'auto', marginRight: 'auto', width: 'fit-content', height: 'fit-content', paddingTop: '35%'}}>
			<Spin size='large' tip='Downloading waybill' />
		</div>
	)
}

export default CollectionSharedViewDownload
