import { Button, Card, Steps } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { history } from '../..';
import { IAddress } from '../../app/models/Address';
import { ICollection } from '../../app/models/Collection';
import { IServiceType } from '../../app/models/ServiceType';
import { RootStoreContext } from '../../app/stores/rootStore';
import CollectionAddressComponent from './collectionComponents/CollectionAddressComponent';
import CollectionContentsComponent from './collectionComponents/CollectionContentsComponent';
import CollectionDetailsComponent from './collectionComponents/CollectionDetailsComponent';
import ViewCollectionComponent from './collectionComponents/ViewCollectionComponent';

const CollectionForm = () => {
	const rootContext = useContext(RootStoreContext);

	const { user } = rootContext.userStore;
	const { getAddresses } = rootContext.addressStore;
	const { get, newCollection, listServiceTypes, createCollection } = rootContext.collectionStore;
	const { Step } = Steps;
	const params: any = useParams();

	const [activeTab, setActiveTab] = useState(0);
	const [loading, setLoading] = useState(true);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [canContinue, setCanContinue] = useState(false);
	const [collection, setCollection] = useState<Partial<ICollection>>({});
	const [serviceTypes, setServiceTypes] = useState<IServiceType[]>([]);
	const [addresses, setAddresses] = useState<IAddress[]>([]);

	const next = () => {
		setCanContinue(false);
		setActiveTab(activeTab + 1);
	}

	const prev = () => {
		setActiveTab(activeTab - 1);
	}

	const done = async () => {
		setButtonLoading(true)
		await createCollection(collection as ICollection);
		setButtonLoading(false);
		history.push('/collections')
	}

	const setDefaults = async (result: ICollection) => {
		setCollection(result);
		setAddresses((await getAddresses(result.requester.company.id, { limit: -1 })).data);
		setServiceTypes(await listServiceTypes());
		setLoading(false);
	}

	useEffect(() => {
		setLoading(true);
		const userId = params.userId ?? user?.id;

		// load possible collection
		if (params.collectionId) {
			get(params.collectionId)
				.then(setDefaults);
		} else {
			newCollection({ userId })
				.then(setDefaults);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	const steps = [
		{
			key: 1,
			title: activeTab > 0 ? 'Completed' : activeTab < 0 ? 'Waiting' : 'In Progress',
			description: 'Choose a sender and receiver.',
			content: (
				<CollectionAddressComponent
					collection={collection}
					setCollection={setCollection}
					addresses={addresses}
					setAddresses={setAddresses}
					validateCallback={setCanContinue}
				/>
			)
		},
		{
			key: 2,
			title: activeTab > 1 ? 'Completed' : activeTab < 1 ? 'Waiting' : 'In Progress',
			description: 'Add contents to the collection.',
			content: (
				<CollectionContentsComponent
					collection={collection}
					setCollection={setCollection}
					validateCallback={setCanContinue}
				/>
			)
		},
		{
			key: 3,
			title: activeTab > 2 ? 'Completed' : activeTab < 2 ? 'Waiting' : 'In Progress',
			description: 'Finalise details such as services.',
			content: (
				<CollectionDetailsComponent
					collection={collection}
					setCollection={setCollection}
					serviceTypes={serviceTypes}
					validateCallback={setCanContinue}
				/>
			)
		},
		{
			key: 4,
			title: activeTab > 3 ? 'Completed' : activeTab < 3 ? 'Waiting' : 'In Progress',
			description: 'Confirmation and waybill.',
			content: (
				<ViewCollectionComponent collection={collection as ICollection} />
			)
		}
	]

	return (
		<>
			<div>
				<Steps current={activeTab} responsive={true}>
					{steps.map(step =>
						<Step key={step.key} title={step.title} description={step.description} />
					)}
				</Steps>

				<Card style={{ marginTop: '3px' }} loading={loading}>
					<div className="steps-content">{steps[activeTab].content}</div>
				</Card>

				<div className="steps-action" style={{ float: 'right', marginTop: '3px' }}>
					{activeTab > 0 && (
						<Button style={{ margin: '0 8px' }} disabled={buttonLoading} onClick={() => prev()}>
							Previous
						</Button>
					)}
					{activeTab < steps.length - 1 && (
						<Button loading={buttonLoading} disabled={!canContinue} type="primary" onClick={() => next()}>
							Next
						</Button>
					)}
					{activeTab === steps.length - 1 && (
						<Button loading={buttonLoading} type="primary" onClick={() => done()}>
							Done
						</Button>
					)}
				</div>
			</div>
		</>
	)
}

export default CollectionForm
