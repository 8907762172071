import React from 'react'
import { Area } from '@ant-design/charts';
import { IDashboard } from '../../../models/Dashboard';

interface IProps {
	data: IDashboard['collectionProfits'];
}

const AreaChart: React.FC<IProps> = ({data: serverData}) => {
	const data: any[] = [];
	Object.keys(serverData).forEach(key => {
		data.push({timePeriod: key, profit: serverData[key]});
	})

	const config = {
		data,
		loading: data.length === 0,
		xField: 'timePeriod',
		yField: 'profit',
		xAxis: {
			range: [0, 1],
		},
		tooltip: {
			formatter: (data: any) => {
				return {
					name: "Profit",
					value: data.profit.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' }),
				};
			},
		}
	};

	return <Area {...config} />;
}

export default AreaChart
