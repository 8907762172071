import React, { useContext, useEffect, useState } from 'react'
import { RootStoreContext } from '../../../app/stores/rootStore'
import { Table, Input, Row, TablePaginationConfig, Card, Button, Col } from 'antd';
import FeatureHeading from '../../../app/components/headings/FeatureHeading';
import { IRequestData, OrderByType } from '../../../app/models/requestData';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import ConfirmationModal from '../../../app/components/modals/ConfirmationModal';
import { ICompany } from '../../../app/models/company';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import CustomFormModal from '../../../app/components/modals/CustomFormModal';
import CompanyForm from './CompanyForm';

const ListCompanies = () => {
	const rootStore = useContext(RootStoreContext);
	const { getCompanies, deleteCompany } = rootStore.companyStore;
	const { Search } = Input;

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<{}[]>([]);
	const [request, setRequest] = useState<IRequestData>({});
	const [total, setTotal] = useState<number>();
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [showRegisterModal, setShowRegisterModal] = useState<boolean>(false);
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [tableDataUpdated, setTableDataUpdated] = useState<boolean>(false);
	const [companyToModify, setCompanyToModify] = useState<Partial<ICompany>>()

	const fetchData = () => {
		setLoading(true);
		getCompanies(request)
			.then((response) => {
				setTotal(response.count)
				const companies = response.data;
				if (companies) {
					setData(companies.map((x, index) => {
						return {
							id: x.id,
							companyName: x.companyName,
							key: index,
						};
					}));
					setLoading(false);
				}
			})
			.finally(() => setLoading(false));
	}

	const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
		let updatedRequest = request;
		request.limit = pagination?.pageSize;
		request.offset = ((pagination.pageSize || 10) * (pagination.current || 1)) - (pagination.pageSize || 10);
		request.orderByColumn = sorter?.column?.dataIndex;
		request.orderByType = sorter?.order === 'ascend' ? OrderByType.asc : OrderByType.desc;

		setRequest(updatedRequest);
		fetchData();
	};

	const onSearch = (value: string) => {
		let updatedRequest = request;
		request.keyword = value;
		setRequest(updatedRequest);
		fetchData();
	}

	const onDelete = async (record: ICompany) => {
		setShowConfirmation(true);
		setCompanyToModify(record);
	}

	const onEdit = async (record: ICompany) => {
		setShowEditModal(true);
		setCompanyToModify(record);
	}

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!tableDataUpdated) {
			return;
		}

		if (!showRegisterModal || !showEditModal) {
			fetchData();
		}

		setTableDataUpdated(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showRegisterModal, showEditModal]);

	const columns = [
		{
			title: 'Edit',
			dataIndex: 'edit',
			key: 'edit',
			width: '5%',
			render: (text: any, record: any) =>
				<Button
					title={'Edit ' + record.displayName}
					style={{ border: 0 }}
					icon={<EditOutlined />} 
					onClick={() => onEdit(record)}
				/>,
		},
		{
			title: 'Delete',
			dataIndex: 'delete',
			key: 'delete',
			width: '7%',
			render: (text: any, record: any) => <Button title={'Delete ' + record.companyName} style={{ border: 0 }} icon={<DeleteOutlined style={{ color: 'red' }} />} onClick={() => onDelete(record)} />,
		},
		{
			title: 'Company Name',
			dataIndex: 'companyName',
			key: 'companyName',
			width: '88%',
			sorter: true
		},
	];

	return (
		<>
			<FeatureHeading value='Companies' />
			<Card>
				<Row style={{ paddingBottom: '3px' }}>
					<Col>
						<Search
							placeholder="input search text"
							onSearch={onSearch}
							style={{ width: 200 }}
						/>
					</Col>
					<Col style={{marginLeft: 'auto'}}>
						<Button type='link' onClick={fetchData} icon={<RedoOutlined />}></Button>
						<Button type='link' onClick={() => setShowRegisterModal(true)} icon={<PlusOutlined />}></Button>
					</Col>
				</Row>
				<Table
					pagination={{ pageSize: request?.limit, showSizeChanger: true, total: total }}
					scroll={{ x: true }}
					loading={loading}
					columns={columns}
					dataSource={data}
					onChange={handleTableChange}
				/>
			</Card>
			<ConfirmationModal
				callback={async () => {
					setLoading(true);
					deleteCompany(companyToModify?.id || '')
						.then(() => fetchData())
						.finally(() => setLoading(false));
				}}
				message={`Please confirm you want to delete ${companyToModify?.companyName}`}
				visible={showConfirmation}
				setVisible={setShowConfirmation}
			/>
			<CustomFormModal
				setVisible={setShowRegisterModal}
				title={'Register New Company'}
				visible={showRegisterModal}
			>
				<CompanyForm
					closeModal={() => {
						setTableDataUpdated(true);
						setShowRegisterModal(false);
					}}
				/>
			</CustomFormModal>
			<CustomFormModal
				setVisible={setShowEditModal}
				title={'Update Company'}
				visible={showEditModal}
			>
				<CompanyForm
					id={companyToModify?.id || ''}
					closeModal={() => {
						setTableDataUpdated(true);
						setShowEditModal(false);
					}}
				/>
			</CustomFormModal>
		</>
	)
}

export default ListCompanies
