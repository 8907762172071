import { action, makeObservable, observable, reaction, runInAction } from "mobx";
import { RootStore } from "./rootStore";
import Cookies from 'universal-cookie';

export default class CommonStore {
	rootStore: RootStore;
	cookies = new Cookies();

	constructor(rootStore: RootStore) {
		makeObservable(this);
		this.rootStore = rootStore;

		reaction(
			() => this.token,
			token => {
				if (token) {
					window.localStorage.setItem('jwt', token);
				} else {
					window.localStorage.removeItem('jwt');
				}
			}
		);
	}

	@observable token: string | null = window.localStorage.getItem('jwt');
	@observable appLoading: boolean = true;
	@observable lightMode: boolean = this.cookies.get('lightMode') === 'false'? false : true;

	@action setAppLoading = (value: boolean) => {
		this.appLoading = value;
	}

	@action setToken = (token: string | null) => {
		window.localStorage.setItem('jwt', token!);
		this.token = token;
	}

	@action setLightMode = (value: boolean) => {
		this.setAppLoading(true);
		runInAction(() => {
			this.lightMode = value;
			this.cookies.set('lightMode', value, {path: '/', expires: new Date(2030, 12, 1)});
			window.location.reload();
		})
	}
}