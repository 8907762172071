import { Button, Form, Input, Select, Tabs } from 'antd'
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react'
import CustomForm from '../../../app/components/forms/CustomForm';
import FeatureHeading from '../../../app/components/headings/FeatureHeading';
import { NotificationMethod } from '../../../app/models/notification';
import { RootStoreContext } from '../../../app/stores/rootStore';

const ProfilePage = () => {
	const { TabPane } = Tabs;
	const rootStore = useContext(RootStoreContext);
	const { updateCurrentProfile, updateCurrentPassword, user, getUser } = rootStore.userStore;

	const [loading, setLoading] = useState(false);
	const [loadingInitial, setLoadingInitial] = useState(false);

	const onFinishProfileUpdate = (values: any) => {
		setLoading(true);
		updateCurrentProfile(values)
			.then(() => {
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	const onFinishFailedProfileUpdate = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	const onFinishPasswordUpdate = async (values: any) => {
		setLoading(true);
		updateCurrentPassword(values)
			.then(() => {
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	const onFinishFailedPasswordUpdate = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	useEffect(() => {
		setLoadingInitial(true);
		getUser()
			.finally(() => setLoadingInitial(false));
	}, [setLoading, getUser])

	return (
		<>
			<Tabs defaultActiveKey="1">
				<TabPane tab="Details" key="1">
					<div id='CustomProfilePageTabPane1Container'>
						<FeatureHeading value='Update your profile' />
						<CustomForm loading={loadingInitial}>
							<Form
								labelCol={{ span: 6 }}
								wrapperCol={{ span: 18 }}
								layout='horizontal'
								initialValues={user!}
								onFinish={onFinishProfileUpdate}
								onFinishFailed={onFinishFailedProfileUpdate}
							>
								<Form.Item
									label='Email'
									name='email'
									rules={[{ required: true, message: 'Please enter an email!' }]}
								>
									<Input autoComplete='off' type='email' />
								</Form.Item>
								<Form.Item
									label='Name'
									name='name'
									rules={[{ required: true, message: 'Please enter a name!' }]}
								>
									<Input autoComplete='off' />
								</Form.Item>
								<Form.Item
									label='Surname'
									name='surname'
									rules={[{ required: true, message: 'Please enter a surname!' }]}
								>
									<Input autoComplete='off' />
								</Form.Item>
								<Form.Item
									label='Display Name'
									name='displayName'
									rules={[{ required: true, message: 'Please enter a display name!' }]}
								>
									<Input autoComplete='off' />
								</Form.Item>
								<Form.Item
									label='Phone'
									name='phoneNumber'
									rules={[{ required: true, message: 'Please enter a phone number!' }]}
								>
									<Input autoComplete='off' type='tel' />
								</Form.Item>
								<Form.Item
									label='Notification Method'
									name='notificationMethod'
									rules={[{ required: true, message: 'Please select a Notification Method!' }]}
								>
									<Select
										virtual={false}
										getPopupContainer={() => document.getElementById('CustomProfilePageTabPane1Container')!}
									>
										<Select.Option key={NotificationMethod.default} value={NotificationMethod.default}>{NotificationMethod[NotificationMethod.default].charAt(0).toUpperCase() + NotificationMethod[NotificationMethod.default].slice(1)}</Select.Option>
										<Select.Option key={NotificationMethod.email} value={NotificationMethod.email}>{NotificationMethod[NotificationMethod.email].charAt(0).toUpperCase() + NotificationMethod[NotificationMethod.email].slice(1)}</Select.Option>
										<Select.Option key={NotificationMethod.whatsApp} value={NotificationMethod.whatsApp}>{NotificationMethod[NotificationMethod.whatsApp].charAt(0).toUpperCase() + NotificationMethod[NotificationMethod.whatsApp].slice(1)}</Select.Option>
									</Select>
								</Form.Item>
								<Form.Item style={{ float: 'right' }}>
									<Button loading={loading} htmlType='submit' type='primary'>Update</Button>
								</Form.Item>
								<Form.Item
									hidden={true}
									name='id'
								/>
							</Form>
						</CustomForm>
					</div>
				</TabPane>
				<TabPane tab="Password" key="2">
					<FeatureHeading value='Update your password' />
					<CustomForm>
						<Form
							labelCol={{ span: 6 }}
							wrapperCol={{ span: 18 }}
							layout='horizontal'
							initialValues={{ switch: true, input: 'hello' }}
							onFinish={onFinishPasswordUpdate}
							onFinishFailed={onFinishFailedPasswordUpdate}
						>
							<Form.Item
								name="oldPassword"
								label="Old Password"
								rules={[
									{
										required: true,
										message: 'Please input your password!',
									},
								]}
							>
								<Input.Password autoComplete='off' />
							</Form.Item>

							<Form.Item
								name="password"
								label="New Password"
								rules={[
									{
										required: true,
										message: 'Please input your password!',
									},
								]}
								hasFeedback
							>
								<Input.Password autoComplete='off' />
							</Form.Item>
							<Form.Item
								name="confirm"
								label="Confirm Password"
								dependencies={['password']}
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Please confirm your password!',
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue('password') === value) {
												return Promise.resolve();
											}
											return Promise.reject(new Error('The two passwords that you entered do not match!'));
										},
									}),
								]}
							>
								<Input.Password autoComplete='off' />
							</Form.Item>
							<Form.Item style={{ float: 'right' }}>
								<Button loading={loading} type="primary" htmlType="submit">
									Update Password
								</Button>
							</Form.Item>
						</Form>
					</CustomForm>
				</TabPane>
			</Tabs>
		</>
	)
}

export default observer(ProfilePage)
