import { Modal } from 'antd'
import React from 'react'

interface IProps {
	visible: boolean;
	setVisible: (value: boolean) => any;
	title: string;
	width?: number | string;
}

const CustomFormModal: React.FC<IProps> = ({ visible, setVisible, children, title, width }) => {
	return (
		<Modal
			visible={visible}
			title={title}
			onCancel={() => setVisible(false)}
			footer={false}
			destroyOnClose={true}
			width={width}
		>
			{children}
			<br />
		</Modal>
	)
}

export default CustomFormModal
