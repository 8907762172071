import { CollectionStatus } from "./Collection";
import { IServiceType } from './ServiceType';

export interface ISupplier {
	id: string;
	name: string;
	emails: string[];
	parcelPerfectConfigurations: IParcelPerfectConfiguration[];
	parcelPerfectServiceResponse: IParcelPerfectServiceResponse;
	services: IServiceType[]
}

export interface IParcelPerfectConfiguration {
	type: ParcelPerfectConfigurationType;
	credentialsUsername: string;
	credentialsPassword: string;
	url: string;
	accountNumber: string;
}

export enum ParcelPerfectConfigurationType {
	Integration,
	Tracking
}

export enum SupplierAcknowledgementType {
	ParcelPerfect,
	Email
}

export interface IAssignCollectionSupplierCommand {
	status: CollectionStatus;
	supplierAcknowledgementType: SupplierAcknowledgementType;
	collectionId: string;
	supplierId: string;
	emails?: string[];
	serviceId?: string;
}

export enum ParcelPerfectServiceEntryStatus {
	Succeeded,
	Failed
}

export interface IParcelPerfectServiceEntry {
	action: string;
	status: ParcelPerfectServiceEntryStatus;
	response: string;
	errors: string[];
}

export interface IParcelPerfectServiceResponse {
	data: boolean;
	entries: IParcelPerfectServiceEntry[];
}
