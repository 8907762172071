import React, { useContext } from 'react';
import {
	PieChartOutlined,
	TeamOutlined,
	UserOutlined,
	HighlightOutlined,
	DollarOutlined
} from '@ant-design/icons';
import { Layout, Menu, Switch } from 'antd';
import { Link } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import Logo from './Logo';
import {
	generateNavBarItems,
	getActivePathItem,
	INavItemPath
} from './navHelper';

interface IProps {}

const AdminNavBar: React.FC<IProps> = (props) => {
	const rootStore = useContext(RootStoreContext);

	const { setLightMode, lightMode } = rootStore.commonStore;
	const { Sider } = Layout;
	const { SubMenu } = Menu;

	const changeTheme = (value: boolean) => {
		setLightMode(value);
	};

	const paths: INavItemPath[] = [
		{
			link: '/admin/dashboard/',
			value: 'Reports',
			icon: <PieChartOutlined />
		},
		{
			link: '/admin/companies/',
			value: 'Clients',
			icon: <UserOutlined />
		},
		{
			link: '/admin/users/',
			value: 'Client Users',
			icon: <UserOutlined />
		},
		{
			link: '/addresses/',
			value: 'Addresses',
			icon: <UserOutlined />
		},
		{
			value: 'Admin',
			icon: <TeamOutlined />,
			children: [
				{
					link: '/admin/collections/',
					value: 'Collections'
				}
			]
		},
		{
			link: '/admin/suppliers/',
			value: 'Suppliers',
			icon: <UserOutlined />
		},
		{
			value: 'Rates',
			icon: <DollarOutlined />,
			children: [
				{
					link: '/admin/rates/',
					value: 'Client Rates',
					icon: <DollarOutlined />
				},
				{
					link: '/admin/rates/update-fuel/',
					value: 'Update Fuel'
				},
				{
					link: '/admin/rates/update-rates/',
					value: 'Rate Increase'
				},
				{
					link: '/admin/quote/',
					value: 'Get Quote'
				}
			]
		}
	];

	const activeItem = getActivePathItem(paths);

	return (
		<Sider
			width="300px"
			style={{
				maxHeight: '100vh',
				overflow: window.innerWidth > 991 ? 'auto' : 'unset'
			}}
			breakpoint="lg"
			collapsedWidth="0"
		>
			<Logo />
			<Menu
				theme="dark"
				defaultSelectedKeys={[activeItem.active]}
				defaultOpenKeys={[activeItem.open ?? '']}
				mode="inline"
			>
				{generateNavBarItems(paths)}

				<Menu.Item key="199" icon={<HighlightOutlined />}>
					Light Mode{' '}
					<Switch checked={lightMode} onChange={changeTheme} />
				</Menu.Item>
				<SubMenu
					style={{ visibility: 'hidden' }}
					key="sub100"
					icon={<TeamOutlined />}
					title="Functional"
				>
					<Menu.Item key="200">
						<Link to={'/developer/adhock'}>Ad-hock Features</Link>
					</Menu.Item>
				</SubMenu>
			</Menu>
		</Sider>
	);
};

export default observer(AdminNavBar);
