import { Col, Descriptions, Form, Select } from 'antd'
import React from 'react'
import { ICollection } from '../../../app/models/Collection'
import { ISupplier, ParcelPerfectServiceEntryStatus } from '../../../app/models/Supplier'

interface IProps {
	collection: Partial<ICollection>;
	setCollection: (collection: Partial<ICollection>) => void;
	suppliers: ISupplier[];
}

const CollectionSupplierComponent: React.FC<IProps> = ({ suppliers, collection, setCollection }) => {
	const setSupplier = (value: ISupplier | undefined) => {
		if (value) {
			setCollection({ ...collection, supplier: { ...value, emails: [], parcelPerfectConfigurations: [] } });
		} else {
			setCollection({ ...collection, supplier: value })
		}
	}

	return (
		<>
			<div id='CustomCollectionSupplierComponentContainer'>
				<Col sm={24} md={16} xl={8}>
					<Form
						layout={'vertical'}
					>
						<Form.Item label={'Supplier:'}>
							<Select
								virtual={false}
								placeholder='select a supplier'
								showSearch
								filterOption={(input, option) =>
									option?.props.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								onChange={(value) => setSupplier(suppliers.find(x => x.id === value))}
								defaultValue={collection.supplier?.id}
								getPopupContainer={() => document.getElementById('CustomCollectionSupplierComponentContainer')!}
							>
								<Select.Option
									key={'None'}
									value={'None'}
									title={'No Supplier'}
								>
									No Supplier
								</Select.Option>
								{suppliers?.map(supplier => {
									return (
										<Select.Option
											key={supplier.id}
											value={supplier.id}
											title={supplier.name}
										>
											{supplier.name}
										</Select.Option>
									)
								})}
							</Select>
						</Form.Item>
					</Form>
					{collection.supplier?.emails && collection.supplier?.emails?.length > 0 &&
						<Descriptions title={'Emails sent:'} column={1}>
							{collection.supplier?.emails.map(x => {
								return (
									<Descriptions.Item label="Email">{x}</Descriptions.Item>
								)
							})}
						</Descriptions>
					}
					{collection.supplier?.parcelPerfectServiceResponse &&
						<div>
							<Descriptions title="Parcel Perfect:" column={1}></Descriptions>
							{collection.supplier.parcelPerfectServiceResponse.entries.map(x => {
								return (
									<Descriptions title={x.action} column={1}>
										<Descriptions.Item label="Status">{ParcelPerfectServiceEntryStatus[x.status]}</Descriptions.Item>
										<Descriptions.Item label="Response">{x.response}</Descriptions.Item>
										{x.errors.map(error => (<Descriptions.Item label="Error">{error}</Descriptions.Item>))}
									</Descriptions>
								)
							})}
						</div>
					}
				</Col>
			</div>
		</>
	)
}

export default CollectionSupplierComponent
