import React from 'react'
import { Line } from '@ant-design/charts';
import { IDashboard } from '../../../models/Dashboard';

interface IProps {
	data: IDashboard['collectionCounts'];
}

const LineChart: React.FC<IProps> = ({ data: serverData }) => {
	const data: any[] = [];
	Object.keys(serverData).forEach(key => {
		data.push({ year: key, value: serverData[key] });
	})
	const config = {
		data,
		loading: data.length === 0,
		xField: 'year',
		yField: 'value',
		label: {},
		point: {
			size: 5,
			shape: 'diamond',
			style: {
				fill: 'white',
				stroke: '#5B8FF9',
				lineWidth: 2,
			},
		},
		state: {
			active: {
				style: {
					shadowBlur: 4,
					stroke: '#000',
					fill: 'red',
				},
			},
		},
		interactions: [
			{
				type: 'marker-active',
			},
		],
		tooltip: {
			showMarkers: false,
			formatter: (data: any) => {
				return {
					name: "Total",
					value: data.value.toLocaleString(),
				};
			},
		}
	};
	return (<Line {...config} />)
}

export default LineChart


