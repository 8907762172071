import { Button, Form, Input, Radio, Skeleton } from 'antd'
import React, { Fragment } from 'react'
import { IParcelPerfectConfiguration, ParcelPerfectConfigurationType } from '../../../../app/models/Supplier';


interface IProps {
	closeModal: (result: IParcelPerfectConfiguration) => void;
	initialValue?: IParcelPerfectConfiguration;
	editMode: boolean;
}

const SupplierConfigurationForm: React.FC<IProps> = ({ closeModal, initialValue, editMode }) => {

	const onFinish = async (values: IParcelPerfectConfiguration) => {
		closeModal(values);
	}

	return (
		<Fragment>
			<Skeleton loading={!initialValue && editMode}>
				<Form
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 18 }}
					layout='horizontal'
					initialValues={initialValue}
					onFinish={onFinish}
				>
					<Form.Item
						label='Type'
						name='type'
						rules={[{ required: true, message: 'Please select the type of config!' }]}
					>
						<Radio.Group>
							<Radio.Button value={ParcelPerfectConfigurationType.Integration}>{ParcelPerfectConfigurationType[ParcelPerfectConfigurationType.Integration]}</Radio.Button>
							<Radio.Button value={ParcelPerfectConfigurationType.Tracking}>{ParcelPerfectConfigurationType[ParcelPerfectConfigurationType.Tracking]}</Radio.Button>
						</Radio.Group>
					</Form.Item>
					<Form.Item label='Account Number' name='accountNumber'
						rules={[{ required: true, message: 'Please input the email!' }]}
					>
						<Input autoComplete='off' />
					</Form.Item>
					<Form.Item label='Url' name='url'
						rules={[{ required: true, message: 'Please input the email!' }]}
					>
						<Input autoComplete='off' />
					</Form.Item>
					<Form.Item label='Username' name='credentialsUsername'
						rules={[{ required: true, message: 'Please input the email!' }]}
					>
						<Input autoComplete='off' />
					</Form.Item>
					<Form.Item label='Password' name='credentialsPassword'
						rules={[{ required: true, message: 'Please input the password!' }]}
					>
						<Input autoComplete='off' />
					</Form.Item>
					<Form.Item style={{ float: 'right' }}>
						<Button type="primary" htmlType="submit">
							{editMode ? 'Save Changes' : 'Save Content'}
						</Button>
					</Form.Item>
				</Form>
			</Skeleton>
		</Fragment>
	)
}

export default SupplierConfigurationForm
