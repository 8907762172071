import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	pdf,
	Image
} from '@react-pdf/renderer';
import logo from '../../../assets/logo.png';
import moment from 'moment';
import { IQuote } from '../../models/quote';

const styles = StyleSheet.create({
	body: {
		paddingTop: 35,
		paddingBottom: 65,
		paddingHorizontal: '5%'
	},
	headerText: {
		fontSize: 12,
		color: 'grey'
	},
	section: {
		marginTop: '10px'
	},
	footer: {
		position: 'absolute',
		bottom: 30,
		left: 30
	},
	footerHeading: {
		fontSize: 12,
		fontFamily: 'Helvetica-Bold',
		fontWeight: 'bold'
	},
	footerText: {
		fontSize: 12
	},
	logo: {
		width: '240px'
	},
	logos: {
		flexDirection: 'row',
		maxHeight: '90px'
	},
	barcode: {
		marginLeft: '50px',
		width: '180px'
	},
	row: {
		flexDirection: 'row'
	},
	column: {
		width: '50%'
	},
	columnThree: {
		width: '33%'
	},
	columnFive: {
		width: '20%'
	},
	textSmall: {
		fontSize: '12pt',
		maxHeight: '60px',
		lineHeight: '1.2'
	},
	text: {
		fontSize: '15pt',
		maxHeight: '60px',
		lineHeight: '1.2'
	},
	textRight: {
		fontSize: '15pt',
		maxHeight: '60px',
		lineHeight: '1.2',
		textAlign: 'right'
	},
	textHeavy: {
		fontSize: '15pt',
		maxHeight: '18px',
		lineHeight: '1.2',
		fontWeight: 900,
		fontFamily: 'Helvetica-Bold'
	},
	heading: {
		fontSize: '22pt',
		fontFamily: 'Helvetica-Bold',
		fontWeight: 'bold'
	},
	headingLarge: {
		fontSize: '28pt',
		fontFamily: 'Helvetica-Bold',
		fontWeight: 'bold'
	},
	line: {
		padding: 0,
		margin: 0
	}
});

const rowLine = (
	<Text style={[styles.line, styles.text]}>
		-------------------------------------------------------------------------------------------------------------
	</Text>
);

const MyDocument = (quote: IQuote) => (
	<Document>
		{Array.from(
			{ length: Math.floor(quote.contents.length / 10) + 1 },
			(_, i) => i + 1
		).map((it) => {
			return (
				<Page style={styles.body}>
					<View style={styles.logos}>
						<Image style={styles.logo} src={logo} />
					</View>
					<View style={styles.section}>
						<Text style={styles.headerText} fixed>
							P.O. Box: 2764 | Somerset West | Tel: 021 493 2819 |
							Cel: 083 438 4341
						</Text>
					</View>
					<View style={styles.section}>
						<View style={styles.row}>
							<Text style={styles.headingLarge}>
								Price Estimate
							</Text>
						</View>
						<View style={styles.row}>
							<View style={styles.column}>
								<Text style={styles.text}>
									{moment().format('D MMMM yyyy HH:mm')}
								</Text>
								<View style={styles.section}>
									<Text style={styles.heading}>From</Text>
									{quote.sender && (
										<Text style={styles.text}>
											{quote.sender}
										</Text>
									)}
									<Text style={styles.text}>
										{quote.from}
									</Text>
								</View>
							</View>
							<View style={styles.column}>
								<Text style={styles.text}> </Text>
								<View style={styles.section}>
									<Text style={styles.heading}>To</Text>
									{quote.receiver && (
										<Text style={styles.text}>
											{quote.receiver}
										</Text>
									)}
									<Text style={styles.text}>{quote.to}</Text>
								</View>
							</View>
						</View>
					</View>
					<View style={styles.section}>
						<View style={styles.row}>
							<Text style={styles.textHeavy}>Service:</Text>
							<Text style={[styles.text, { paddingLeft: '3px' }]}>
								{quote.serviceType.code} (
								{quote.serviceType.displayName})
							</Text>
						</View>
						{rowLine}
						<View style={styles.row}>
							<Text style={styles.textHeavy}>Reference:</Text>
							<Text style={[styles.text, { paddingLeft: '3px' }]}>
								{quote.reference}
							</Text>
						</View>
						{rowLine}
					</View>
					<View>
						<View style={styles.row}>
							<View style={styles.columnFive}>
								<Text style={styles.textHeavy}>Pieces</Text>
							</View>
							<View style={styles.columnFive}>
								<Text style={styles.textHeavy}>Length</Text>
							</View>
							<View style={styles.columnFive}>
								<Text style={styles.textHeavy}>Width</Text>
							</View>
							<View style={styles.columnFive}>
								<Text style={styles.textHeavy}>Height</Text>
							</View>
							<View style={styles.columnFive}>
								<Text style={styles.textHeavy}>
									Mass (kg's)
								</Text>
							</View>
						</View>
					</View>
					<View style={{ maxHeight: '190px' }}>
						{Array.from(
							{
								length: 10
							},
							(_, i) => i + 1
						).map((x, index) => {
							return (
								<View>
									<View
										style={[
											styles.row,
											{
												backgroundColor:
													index % 2 === 0
														? 'white'
														: 'lightgray'
											}
										]}
									>
										<View style={styles.columnFive}>
											<Text style={styles.text}>
												{
													quote.contents[
														it * 10 - 10 + index
													]?.pieces
												}
											</Text>
										</View>
										<View style={styles.columnFive}>
											<Text style={styles.text}>
												{
													quote.contents[
														it * 10 - 10 + index
													]?.length
												}
											</Text>
										</View>
										<View style={styles.columnFive}>
											<Text style={styles.text}>
												{
													quote.contents[
														it * 10 - 10 + index
													]?.width
												}
											</Text>
										</View>
										<View style={styles.columnFive}>
											<Text style={styles.text}>
												{
													quote.contents[
														it * 10 - 10 + index
													]?.height
												}
											</Text>
										</View>
										<View style={styles.columnFive}>
											<Text style={styles.text}>
												{(
													quote.contents[
														it * 10 - 10 + index
													]?.weight *
													quote.contents[
														it * 10 - 10 + index
													]?.pieces
												).toString() === 'NaN'
													? ' '
													: quote.contents[
															it * 10 - 10 + index
													  ]?.weight *
													  quote.contents[
															it * 10 - 10 + index
													  ]?.pieces}
											</Text>
										</View>
									</View>
								</View>
							);
						})}
					</View>
					<View style={styles.section}>
						<View style={styles.row}>
							<Text style={styles.textHeavy}>
								Chargeable Weight:
							</Text>
							<Text style={[styles.text, { paddingLeft: '3px' }]}>
								{quote.chargeableWeight} kg
							</Text>
						</View>
						{rowLine}
					</View>
					<View>
						<View style={styles.row}>
							<View style={styles.columnThree}></View>
							<View style={styles.columnThree}>
								<Text style={styles.textHeavy}>Totals:</Text>
								<Text style={styles.textRight}>Sub-total:</Text>
								<Text style={styles.textRight}>VAT:</Text>
								<Text style={styles.textRight}>Total:</Text>
							</View>
							<View style={styles.columnThree}>
								<Text style={styles.textHeavy}> </Text>
								<Text style={styles.text}>
									{' '}
									{quote.value.toLocaleString('en-ZA', {
										style: 'currency',
										currency: 'ZAR'
									})}
								</Text>
								<Text style={styles.text}>
									{' '}
									{(quote.value * 0.15).toLocaleString(
										'en-ZA',
										{
											style: 'currency',
											currency: 'ZAR'
										}
									)}
								</Text>
								<Text style={styles.text}>
									{' '}
									{(
										quote.value +
										quote.value * 0.15
									).toLocaleString('en-ZA', {
										style: 'currency',
										currency: 'ZAR'
									})}
								</Text>
							</View>
						</View>
					</View>
					<View style={styles.footer}>
						<Text style={styles.footerHeading}>Please note:</Text>
						<Text style={styles.footerText}>
							*Quotations are estimates based on the information
							supplied
						</Text>
						<Text style={styles.footerText}>
							*Should the sizes differ to what was given the cost
							on the waybill will differ
						</Text>
						<Text style={styles.footerText}>
							*Cum Laude Freight Management is subject to limited
							liability of the carrier.
						</Text>
						<Text style={styles.footerText}>
							*Quotations are valid for 30 days and does not
							include insurance..
						</Text>
					</View>
				</Page>
			);
		})}
	</Document>
);

export const download = async (quote: IQuote): Promise<void> => {
	var FileSaver = require('file-saver');
	const blob = await pdf(<MyDocument {...quote} />).toBlob();
	FileSaver.saveAs(blob, `${'quote.waybill'}.pdf`);
};

export const open = async (quote: IQuote): Promise<void> => {
	const blob = await pdf(<MyDocument {...quote} />).toBlob();
	const url = URL.createObjectURL(blob);
	window.open(url);
};

export const replace = async (quote: IQuote): Promise<void> => {
	const blob = await pdf(<MyDocument {...quote} />).toBlob();
	const url = URL.createObjectURL(blob);
	window.location.replace(url);
};

export const getBlob = async (quote: IQuote): Promise<Blob> => {
	const blob = await pdf(<MyDocument {...quote} />).toBlob();
	return blob;
};

export const getBase64 = async (quote: IQuote): Promise<string> => {
	const blob = await pdf(<MyDocument {...quote} />).toBlob();
	var stringFile = ((await blobToBase64(blob)) as string).substring(28);
	return stringFile;
};

const blobToBase64 = (blob: Blob) => {
	return new Promise((resolve, _) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result);
		reader.readAsDataURL(blob);
	});
};
