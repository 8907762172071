import { List } from 'antd'
import React from 'react'
import { IMediaItem } from '../../../app/models/MediaItem'

interface IProps {
	attachments?: IMediaItem[];
}

const CollectionAttachmentsListComponent: React.FC<IProps> = ({ attachments }) => {
	return (
		<List
			header={<div>Attachments</div>}
			bordered
			dataSource={attachments}
			renderItem={item => (
				<List.Item>
					<a target='_blank' rel='noreferrer' href={item.link}>{item.title}</a>
				</List.Item>
			)}
		/>
	)
}

export default CollectionAttachmentsListComponent
